/* Je vous salue, Marie pleine de grâce le Seigneur est avec vous. Vous êtes bénie entre toutes les femmes 
et Jésus le fruit de vos entrailles, est béni. Sainte Marie, Mère de Dieu, priez pour nous pauvres pécheurs
maintenant et à l’heure de notre mort*/

import { Box, Button, Center, Flex, Image, Link, useColorMode } from '@chakra-ui/react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import userAtom from '../atoms/userAtom'
import { AiFillHome } from 'react-icons/ai'
import { Link as RouterLink } from 'react-router-dom'
import { RxAvatar } from 'react-icons/rx'
import useLogout from '../hooks/useLogout'
import authScreenAtom from '../atoms/authAtom'
import { BsFillChatQuoteFill } from 'react-icons/bs'
import { MdOutlineSettings } from 'react-icons/md'
import { useLocation } from "react-router-dom";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useMediaQuery } from "@chakra-ui/react";

const Header = () => {
const { colorMode, toggleColorMode } = useColorMode()
const user = useRecoilValue(userAtom)
const logout = useLogout()
const setAuthScreen = useSetRecoilState(authScreenAtom)
const location = useLocation();
const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
const isHiveMenu = location.pathname.startsWith('/Hive');
const isTags = location.pathname.startsWith('/tags/');


return (
<Flex justifyContent={'space-between'} mt={2} mb="6" position="relative">
 

{!user && (
  <Box height="200px"> 
  <Box position="absolute" left="51%" transform="translateX(-50%)">
  <Center width="100%">
    <Image
      cursor={'pointer'}
      alt='no header'
      w={150}
      src={ colorMode === "dark" ? "/NewArklogo.jpg" : "/NewArklogo.jpg"}
   
      borderRadius="full" 
    />
  </Center>
</Box>
</Box>
)}

{user && !isHiveMenu && !isTags && (
  <Box height="200px"> 
  <Box position="absolute" left="51%" transform="translateX(-50%)">
  <Center width="100%">
    <Image
      cursor={'pointer'}
      alt='no header'
      w={150}
      src={ colorMode === "dark" ? "/NewArklogo.jpg" : "/NewArklogo.jpg"}
      
      borderRadius="full" 
    />
  </Center>
</Box>
</Box>
)}


{isHiveMenu && (
          <Box height="200px"> 
          <Box position="absolute" left="51%" transform="translateX(-50%)">
          <Center width="100%">
            <Image
              cursor={'pointer'}
              alt='no header'
              w={150}
              src={ colorMode === "dark" ? "/ArkYellow.jpg" : "/ArkYellow.jpg"}
             
              borderRadius="full" 
            />
          </Center>
        </Box>
        </Box>
      )}

      {isTags && (
                 <Box height="200px"> 
                 <Box position="absolute" left="51%" transform="translateX(-50%)">
                 <Center width="100%">
                   <Image
                     cursor={'pointer'}
                     alt='no header'
                     w={150}
                     src={ colorMode === "dark" ? "/ArkYellow.jpg" : "/ArkYellow.jpg"}
                    
                     borderRadius="full" 
                   />
                 </Center>
               </Box>
               </Box>
      )}

</Flex>

)
}

export default Header

/* 
import { Box, Button, Center, Flex, Image, Link, useColorMode } from '@chakra-ui/react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import userAtom from '../atoms/userAtom'
import { AiFillHome } from 'react-icons/ai'
import { Link as RouterLink } from 'react-router-dom'
import { RxAvatar } from 'react-icons/rx'
import useLogout from '../hooks/useLogout'
import authScreenAtom from '../atoms/authAtom'
import { BsFillChatQuoteFill } from 'react-icons/bs'
import { MdOutlineSettings } from 'react-icons/md'

const Header = () => {
const { colorMode, toggleColorMode } = useColorMode()
const user = useRecoilValue(userAtom)
const logout = useLogout()
const setAuthScreen = useSetRecoilState(authScreenAtom)

return (
<Flex justifyContent={'space-between'} mt={2} mb="6">

{!user && (
  <Box height="200px"> 
  <Box position="absolute" left="51%" transform="translateX(-50%)">
  <Center width="100%">
    <Image
      cursor={'pointer'}
      alt='no header'
      w={150}
      src={ colorMode === "dark" ? "/NewArklogo.jpg" : "/NewArklogo.jpg"}
      onClick={toggleColorMode}
      borderRadius="full" 
    />
  </Center>
</Box>
</Box>
)}

{user && (
  <Box height="200px"> 
  <Box position="absolute" left="51%" transform="translateX(-50%)">
  <Center width="100%">
    <Image
      cursor={'pointer'}
      alt='no header'
      w={150}
      src={ colorMode === "dark" ? "/NewArklogo.jpg" : "/NewArklogo.jpg"}
      onClick={toggleColorMode}
      borderRadius="full" 
    />
  </Center>
</Box>
</Box>
)}

</Flex>
)
}

export default Header


*/