/*שָלוֹם לָךְ, מִרְיָם,
מְלֵאַת הַחֶסֶד,
ה׳ עִמָּךְ,
בְּרוּכָה אַתְּ בַּנָשִׁים
וּבָרוּךְ פְּרִי בִטְנֵךְ יֵשׁוּעַ,
מִרְיָם הַקְּדוֹשָׁה,
אֵם הָאֱלֹהִים,
הִתְפַּלְּלִי בַּעֲדֵנוּ, הַחוֹטְאִים,
עַתָּה וּבִשְׁעַת מוֹתֵנוּ. */

import { useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import userAtom from '../atoms/userAtom'
import useShowToast from './useShowToast'

const useLogout = () => {
    const setUser = useSetRecoilState(userAtom)
    const showToast = useShowToast()
    const navigate = useNavigate()

    const logout = async() => {
        try {
            const res = await fetch("/api/users/logout",{
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            })
            const data = await res.json();
            console.log(data)
            if(data.error){
                showToast("Error", data.error, "error");
            } 
            localStorage.removeItem('user-ark');
            localStorage.removeItem('userInterests'); 
            setUser(null)
            navigate('/auth') 
        } catch (error) {
            showToast("Error", error, "error")
        }
    }
    return logout
}

export default useLogout
