/* Gegrüßet seist du, Maria, voll der Gnade der Herr ist mit dir. Du bist gebenedeit unter den Frauen,
und gebenedeit ist die Frucht deines Leibes, Jesus Heilige Maria, Mutter Gottes bitte für uns Sünder
jetzt und in der Stunde unseres Todes Amen
*/

import React from 'react';
import { ChakraProvider, extendTheme, Box, Flex, Image, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { mode } from "@chakra-ui/theme-tools";
import { useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import HiveMindSearch from '../HiveMindSearch';

const HiveMindComputer = () => {

    const styles = {
        global: (props) => ({
          body: {
            bg: 'black',
            color: mode('gray.800', 'whiteAlpha.900')(props),
          },
        }),
      };
      
      const theme = extendTheme({ styles });
      
      
    const center = useBreakpointValue({
        base: "10%", //iphone
        sm: "10%",
        md: "31%", //ipad Mini
        lg: "39%",
        xl: "39%"
      });
    
      const left = useBreakpointValue({
        base: "15%",
        sm: "15%",
        md: "25%",
        lg: "32%",
        xl: "32%"
      });
    
      const right = useBreakpointValue({
        base: "65%",
        sm: "65%",
        md: "60%",
        lg: "59%",
        xl: "59%"
      });

      const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
      const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
      const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");

console.log("isIphoneXR:", isIphoneXR);
console.log("isIphone14ProMax:", isIphone14ProMax);


const adjustedCenter = isIphoneXR ? "15%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : center;
const adjustedLeft = isIphoneXR ? "20%" : isIphone14ProMax ? "20%" : isIpadPro ? "30%" : left;
const adjustedRight = isIphoneXR ? "65%" : isIphone14ProMax ? "65%" : isIpadPro ? "65%" : right;

  return (
    <ChakraProvider theme={theme}>
             
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="100%" 
        left={adjustedCenter}
      >
        <Box mt={-100}><HiveMindSearch /></Box>
        <Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px">Computer Science</Box> 

        <Tooltip label="ArtificialIntelligence" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/ArtificialIntelligence">
              <Image src="artificialintelligence.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Software Engineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/SoftwareEngineering">
              <Image src="softwareengineering.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Coding" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Coding">
              <Image src="coding.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Hardware Engineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/ComputerEngineering">
              <Image src="compengineering.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Database Management" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/DatabaseManagement">
              <Image src="databasemanagement.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>

      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top={{ base: "120%", md: "125%" }} 
        left={adjustedLeft}
      >
        <Tooltip label="Computer Science" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/ComputerScience">
              <Image src="computerscience.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Cyber Security" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/CyberSecurity">
              <Image src="cybersecurity.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Data Analysis" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/DataAnalysis">
              <Image src="dataanalysis.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Datamining" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Datamining">
              <Image src="datamining.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Data Science" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/DataScience">
              <Image src="datascience.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>

      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="120%" 
        left={adjustedRight}
      >
        <Tooltip label="Digital Design" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/DigitalDesign">
              <Image src="digitaldesign.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Game Development" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/GameDevelopment">
              <Image src="gamedevelopment.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Machine Learning" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/MachineLearning">
              <Image src="machinelearning.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Network Security" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/NetworkSecurity">
              <Image src="networksecurity.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Web Development" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/WebDevelopment">
              <Image src="webdevelopment.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>
    </ChakraProvider>
  );
};

export default HiveMindComputer;
/* 
import { Box, Button, Flex, Grid, Image, Tooltip, useMediaQuery } from '@chakra-ui/react';
import { Link, Link as RouterLink } from 'react-router-dom';
import HiveMindSearch from '../HiveMindSearch';

const HiveMindComputer = () => {

    const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (max-width: 430px)");
    const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
    const [ipadPro] = useMediaQuery('(min-width: 1024px) and (min-height: 1366px)');
    const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");
    const [isIphone] = useMediaQuery([
        "(min-device-width: 375px) and (max-device-width: 430px) and (min-device-height: 812px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3)"
      ]);
  return ( 
   <>


<Flex 
    direction="column" 
    align="center" 
    justify="center" 
    position="absolute" 
    top="100%" 
    left={isMac ? '40%' : ipadPro ? '35%' : isIpadEsq ? "30%" : isIphone ? '15%' : isSmallScreen ? "7%" : "39%"}
>
<Box mt={-100} ><HiveMindSearch /></Box>
<Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px"> Computer Science</Box> 


<Tooltip label="ArtificialIntelligence" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/ArtificialIntelligence">
        <Image src="artificialintelligence.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Software Engineering" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/SoftwareEngineering">
        <Image src="softwareengineering.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Coding" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/Coding">
        <Image src="coding.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Hardware Engineering" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/ComputerEngineering">
        <Image src="compengineering.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>

{/*
<Tooltip label="Computer Engineering" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/ComputerEngineering">
        <Image src="computerengineering.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box> 
</Tooltip> 


{isSmallScreen && (
<Tooltip label="Database Management" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/DatabaseManagement">
        <Image src="databasemanagement.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
)}

</ Flex>




<Flex direction="column" align="center" justify="center" position="absolute" top={isIpadEsq ? "125%" : "120%"} 
    left={isMac ? '40%' : ipadPro ? '30%' : isIpadEsq ? "23%" : isSmallScreen ? "15%" : "41%"}
>

<Tooltip label="Computer Science" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/ComputerScience">
        <Image src="computerscience.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Cyber Security" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/CyberSecurity">
        <Image src="cybersecurity.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Data Analysis" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/DataAnalysis">
        <Image src="dataanalysis.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>


{isSmallScreen && (
    <>
<Tooltip label="Datamining" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/Datamining">
        <Image src="datamining.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Data Science" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/DataScience">
        <Image src="datascience.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
</>
)}

</ Flex>


{!isSmallScreen && (
<Flex direction="column" align="center" justify="center" position="absolute" top="140%" left="34%">
<Tooltip label="Datamining" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/Datamining">
        <Image src="datamining.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Data Science" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/DataScience">
        <Image src="datascience.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Web Development" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/WebDevelopment">
        <Image src="webdevelopment.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
  </Flex> 
)}




<Flex direction="column" align="center" justify="center" position="absolute" top="120%" left={isMac ? '55%' : ipadPro ? '58%' : isSmallScreen ? "60%" : "55%"}>
<Tooltip label="Digital Design" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/DigitalDesign">
        <Image src="digitaldesign.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Game Development" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/GameDevelopment">
        <Image src="gamedevelopment.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Machine Learning" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/MachineLearning">
        <Image src="machinelearning.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Network Security" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/NetworkSecurity">
        <Image src="networksecurity.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>

{isSmallScreen && (
<Tooltip label="Web Development" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/WebDevelopment">
        <Image src="webdevelopment.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
)}

</Flex>





{!isSmallScreen && (
<Flex direction="column" align="center" justify="center" position="absolute" top="140%" left="62%">
<Tooltip label="Database Management" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/DatabaseManagement">
        <Image src="databasemanagement.png" boxSize="100px"/>
        </Link>
    </Box>
</Tooltip>
</ Flex>
)}

    </>
  )
}

export default HiveMindComputer


/* import { Box, Button, Flex, Grid, Image, Tooltip } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const HiveMindComputer = () => {
  return ( 
   <>
   <Flex direction="column" align="center" justify="center" mt ={0} ml={-200}>
    <Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px"> Computer Science</Box> 


<Tooltip label="ArtificialIntelligence" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="artificialintelligence.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Software Engineering" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="softwareengineering.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Coding" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="coding.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Computer Engineering" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="compengineering.png" boxSize="100px"/>
    </Box>
</Tooltip>

<Tooltip label="Computer Engineering" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="computerengineering.png" boxSize="100px"/>
    </Box>
</Tooltip>



</ Flex>


<Flex direction="column" align="center" justify="center" mt ={-100} ml={-350}>

<Tooltip label="Computers" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="computers.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Computer Science" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="computerscience.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Cyber Security" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="cybersecurity.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Data Analysis" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="dataanalysis.png" boxSize="100px"/>
    </Box>
</Tooltip>


</ Flex>


<Flex direction="column" align="center" justify="center" mt ={-850} ml={-550}>
<Tooltip label="Database Management" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="databasemanagement.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Datamining" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="datamining.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Data Science" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="datascience.png" boxSize="100px"/>
    </Box>
</Tooltip>
</Flex>



<Flex direction="column" align="center" justify="center" mt ={-400} ml={-50}>
<Tooltip label="Digital Design" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="digitaldesign.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Game Development" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="gamedevelopment.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Machine Learning" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="machinelearning.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Network Security" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="networksecurity.png" boxSize="100px"/>
    </Box>
</Tooltip>
<Tooltip label="Web Development" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Image src="webdevelopment.png" boxSize="100px"/>
    </Box>
</Tooltip>
</Flex>
    </>
  )

export default HiveMindComputer */ 