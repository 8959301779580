 /* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/

import React from 'react';
import { ChakraProvider, extendTheme, Box, Flex, Image, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { mode } from "@chakra-ui/theme-tools";
import { useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import HiveMindSearch from '../HiveMindSearch';

const HiveMindPhysics = () => {
  const styles = {
    global: (props) => ({
      body: {
        bg: 'black',
        color: mode('gray.800', 'whiteAlpha.900')(props),
      },
    }),
  };

  const theme = extendTheme({ styles });

  const center = useBreakpointValue({
    base: "10%", //iphone
    sm: "10%",
    md: "31%", //ipad Mini
    lg: "39%",
    xl: "39%"
  });

  const left = useBreakpointValue({
    base: "15%",
    sm: "15%",
    md: "30%",
    lg: "40%",
    xl: "32%"
  });

  const right = useBreakpointValue({
    base: "60%",
    sm: "65%",
    md: "60%",
    lg: "55%",
    xl: "59%"
  });

  const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
  const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
  const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");

  const adjustedCenter = isIphoneXR ? "15%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : center;
  const adjustedLeft = isIphoneXR ? "20%" : isIphone14ProMax ? "20%" : isIpadPro ? "30%" : left;
  const adjustedRight = isIphoneXR ? "60%" : isIphone14ProMax ? "60%" : isIpadPro ? "65%" : right;

  return (
    <ChakraProvider theme={theme}>
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="100%" 
        left={adjustedCenter}
      >
        <Box mt={-100}><HiveMindSearch /></Box>
        <Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px">
          Physics 
        </Box>

        <Tooltip label="Astrophysics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Astrophysics">
              <Image src="astrophysics.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Quantum Mechanics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/QuantumMechanics">
              <Image src="quantummechanics.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Quantum Physics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/QuantumPhysics">
              <Image src="quantumphysics.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Astronomy" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Astronomy">
              <Image src="astronomy.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>

      {/* Left 1 */}
      <Flex direction="column" align="center" justify="center"         top={{ base: "120%", md: "125%" }} 
      position="absolute" 
      left={adjustedLeft}>
        <Tooltip label="Nuclear Physics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/NuclearPhysics">
              <Image src="nuclearphysics.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Plasma" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Plasma">
              <Image src="plasma.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Waves" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Waves">
              <Image src="waves.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>

      {/* Right 1 */}
      <Flex         direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
             top="120%"
      left={adjustedRight}>
        <Tooltip label="Optics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Optics">
              <Image src="optics.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Thermodynamics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Thermodynamics">
              <Image src="thermodynamics.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>
    </ChakraProvider>
  );
}

export default HiveMindPhysics;