/* Χαῖρε Μαρία κεχαριτωμένη ὁ Κύριος μετά σοῦ Ἐυλογημένη σὺ ἐν γυναιξὶ, καὶ εὐλογημένος ὁ καρπὸς τῆς κοιλίας 
σοῦ Ἰησούς Ἁγία Μαρία, μῆτερ θεοῦ προσεύχου ὑπέρ ἡμῶν τῶν ἁμαρτωλῶν, νῦν καὶ ἐν τῇ ὥρᾳ τοῦ θανάτου ἡμῶν. */

import { FaRegCommentAlt } from "react-icons/fa";
import { Box, Flex, Input, Text, Tooltip, useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import Comment from '../Comment'; 
import useShowToast from '../../hooks/useShowToast';
import { useRecoilState, useRecoilValue } from "recoil";
import userAtom from "../../atoms/userAtom";
import postsAtom from "../../atoms/postsAtom";
import sanitizeHtml from 'sanitize-html';

const Com = ({ post }) => {
  const [isReplying, setIsReplying] = useState(false);
  const [reply, setReply] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = useState('xl');
  const user = useRecoilValue(userAtom);
  const [posts, setPosts] = useRecoilState(postsAtom);
  const showToast = useShowToast();
  const [replies, setReplies] = useState([]);
  const [replyId, setReplyId] = useState(null);


// Inside Com component

useEffect(() => {
  const modalOpen = localStorage.getItem(`modalOpen-${post._id}`) === 'true';
  if (modalOpen) {
    onOpen();
  }
}, [onOpen, post._id]); // Include post._id in the dependency array

// Adjust handleOpen and handleClose to use the comment's ID
const handleOpen = () => {
  localStorage.setItem(`modalOpen-${post._id}`, 'true');
  onOpen();
};

const handleClose = () => {
  localStorage.setItem(`modalOpen-${post._id}`, 'false'); // Set to 'false' instead of removing to ensure correct state tracking
  onClose();
};



const handleReply = async () => {
  if (!user) return showToast("Error", "You need to be logged in to reply to a post", "error");
  if (isReplying) return;
  setIsReplying(true);
  try {
    const sanitizedReply = sanitizeHtml(reply);
    const res = await fetch(`/api/posts/reply/` + post._id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: sanitizedReply }),
    });
    if (!res.ok) {
      console.log('Response status:', res.status);
      throw new Error('Failed to send reply');
    }
    const data = await res.json();
    if (data.error) {
      console.log('Server error:', data.error);
      return showToast("Error", data.error, "error");
    }

    // Update replies state to include the new reply
    setReplies(prevReplies => [data, ...prevReplies]);
    // Update posts state to include the new reply in the correct post

    setPosts('');
    setReply(''); // Clear the reply input field after successful submission

    // Log the replyId and postId using the newly set replyId variable
    console.log(`Reply ID: ${data.replyId}, Post ID: ${post._id}`);

    // Refresh the page after successful reply
    window.location.reload();
  } catch (error) {
    console.log('Error:', error);
    showToast("Error", error.message, "error");
  } finally {
    setIsReplying(false);
  }
};



useEffect(() => {
  console.log(`Updated replyId: ${replyId}`);
}, [replyId]); 

  useEffect(() => {
    const fetchReplies = async () => {
      try {
        const res = await fetch(`/api/posts/` + post._id);
        if (!res.ok) {
          console.log('Response status:', res.status);
          throw new Error('Failed to fetch replies');
        }
        const data = await res.json();
        setReplies(Array.isArray(data.replies) ? data.replies : []); 
      } catch (error) {
        console.log('Error:', error);
        showToast("Error", error.message, "error")
      }
    };
  
    fetchReplies();
  }, [post._id]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleReply();
    }
  }



  return (
    <>
      <Tooltip
        hasArrow
        label={"Comment"}
        placement='right'
        ml={1}
        openDelay={500}
        display={{ base: 'block', md: 'none' }}
        fontFamily={"'Orbitron', sans-serif"}
      >
        <Flex
          alignItems={'center'}
          gap={4}
          p={2}
          w={{ base: 10, md: 'full' }}
          justifyContent={{ base: 'center', md: 'flex-start' }}
          onClick={handleOpen} 
        >
          <FaRegCommentAlt size={20} color="#FF6EC7" />
          <Box
            display={{ base: "none", md: "block" }}
            fontFamily={"'Orbitron', sans-serif"}
            color="#8d8d8d"
            css={{
              textShadow: "0 0 2px yellow.400, 0 0 3px yellow.400, 0 0 4px yellow.400",
              mixBlendMode: "difference",
            }}
          >
            Comment
          </Box>
        </Flex>
      </Tooltip>



      <Modal onClose={handleClose} size={size} isOpen={isOpen}>
  <ModalOverlay />
  <ModalContent  bg={'black'}>
    <ModalHeader fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)">
    <Button 
  onClick={handleReply} 
  style={{
    backgroundColor: 'black',
    color: '#FF6EC7',
    borderColor: '#FF6EC7',
    borderWidth: '2px'
  }}>Reply</Button>
      <Text textAlign="center">Comments</Text>
    </ModalHeader>
    <ModalCloseButton />
    <ModalBody>
  <Input 
    placeholder="Type your comment here..." 
    fontFamily={'Orbitron, sans-serif'}
    value={reply} 
    onChange={(e) => setReply(sanitizeHtml(e.target.value))} 
    onKeyDown={handleKeyDown} 
  />


{replies.map((reply, index) => (
  <Comment 
    key={reply._id} // Use reply._id as the key
    reply={reply} 
    user={reply.user}
    lastReply={index === replies.length - 1}
    currentUser={user}
    setComments={setReplies}
    post={post}
    replyId={reply._id} // Use reply._id here instead of reply.replyId
  />
))}
</ModalBody>
    <ModalFooter>

    </ModalFooter>
  </ModalContent>
</Modal>
    </>
  )
}

export default Com;
 