/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/

// This is place the vidoes and images are processed for conversation
//Sometimes it wont let me put multiple images, when I click them it closes and nothing renders, 
//see if this is an error on my machine or everywhere

import {
    Flex,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,   ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { IoSendSharp } from "react-icons/io5";
import useShowToast from "../hooks/useShowToast";
import { conversationsAtom, selectedConversationAtom } from "../atoms/messagesAtom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LiaCameraRetroSolid } from "react-icons/lia";
import { BsFillImageFill } from "react-icons/bs";
import usePreviewMedia from "../hooks/usePreviewImg";
import ReactPlayer from 'react-player';

const MessageInput = ({ setMessages }) => {
    const [messageText, setMessageText] = useState("");
    const showToast = useShowToast();
    const selectedConversation = useRecoilValue(selectedConversationAtom);
    const setConversations = useSetRecoilState(conversationsAtom);
    const imageRef = useRef(null);
    const { onClose } = useDisclosure();
	const { handleMediaChange, mediaUrl, setMediaUrl, mediaType, setMediaType } = usePreviewMedia(() => setLoading((prev) => !prev));
    const [isSending, setIsSending] = useState(false);
	const [loading, setLoading] = useState(false);

    
	const handleSendMessage = async (e) => {
		e.preventDefault();
		if (!messageText && !mediaUrl) return;
		if (isSending) return;
	
		setIsSending(true);
	
		try {
			console.log('mediaUrl:', mediaUrl); 
			console.log('messageText:', messageText); 
	
			const res = await fetch("/api/messages", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					message: messageText,
					recipientId: selectedConversation.userId,
					img: mediaUrl,
				}),
			});
			const data = await res.json();
			console.log('server response:', data);
			if (data.error) {
				showToast("Error", data.error, "error");
				return;
			}
			console.log(data);
			setMessages((messages) => {
				const newMessages = [...messages, data];
				console.log('New messages:', newMessages);
				return newMessages;
			});
	
			setConversations((prevConvs) => {
				const updatedConversations = prevConvs.map((conversation) => {
					if (conversation._id === selectedConversation._id) {
						return {
							...conversation,
							lastMessage: {
								text: messageText,
								sender: data.sender,
							},
						};
					}
					return conversation;
				});
				console.log('Updated conversations:', updatedConversations);
				return updatedConversations;
			});
			setMessageText("");
			setMediaUrl("");
			setMediaType(""); 
		} catch (error) {
			console.error('Error in handleSendMessage:', error);
			showToast("Error", error.message, "error");
		} finally {
			setIsSending(false);
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault(); 
			handleSendMessage(e);
		}
	};
	

    return (
		<Flex gap={2} alignItems={"center"}>
        <form onSubmit={handleSendMessage} style={{ flex: 95 }} fontFamily={'Obritron, sans-serif'}>
            <InputGroup>
                <Input
                    w={"full"}
                    placeholder='Type a message'
                    onChange={(e) => setMessageText(e.target.value)}
                    onKeyDown={handleKeyDown} // Use onKeyDown here
                    value={messageText}
                    fontFamily={"Orbitron, sans-serif"}
                />
                <InputRightElement onClick={handleSendMessage} cursor={"pointer"}>
                    <IoSendSharp />
                </InputRightElement>
            </InputGroup>
        </form>
            <Flex flex={5} cursor={"pointer"}>
			<LiaCameraRetroSolid  style={{marginLeft:"5px", cursor:"pointer"}} size={24} color="#FF6EC7"
               onClick={() => imageRef.current.click()} />
                <Input type={"file"} hidden ref={imageRef} onChange={handleMediaChange} />
            </Flex>
            <Modal
                isOpen={mediaUrl}
                onClose={() => {
                    onClose();
                    setMediaUrl("");
                    setMediaType(""); 
                }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
					<Flex mt={5} w={"full"}>
    {mediaType && mediaType.startsWith('video') ? (
        <ReactPlayer url={mediaUrl} controls={true} onError={e => console.error('Error loading video:', mediaUrl, e)} />
    ) : (
        <Image src={mediaUrl} onError={e => console.error('Error loading image:', mediaUrl, e)} />
    )}
</Flex>
                        <Flex justifyContent={"flex-end"} my={2}>
                            {!isSending ? (
                                <IoSendSharp size={24} cursor={"pointer"} onClick={handleSendMessage} />
                            ) : (
                                <Spinner size={"md"} />
                            )}
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default MessageInput;
