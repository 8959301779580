/* Ave Maria gratia plena, Dominus tecum, benedicta tu in mulieribus et benedictus fructus 
ventris tui Iesu, Santa Maria, mater Dei, ora pro nobis peccatoribus, nunc et in ora mortis nostrae */

import { useState } from 'react';
import useShowToast from './useShowToast'; 

const usePreviewMedia = (setLoading) => {
  const [mediaUrl, setMediaUrl] = useState(null);
  const [mediaType, setMediaType] = useState(null); 
  const showToast = useShowToast();
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [totalChunksState, setTotalChunksState] = useState(0);
  

  const startMultipartUpload = async (fileName) => {
    try {
      const response = await fetch('/api/posts/start-multipart-upload', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName }),
      });
  
      if (!response.ok) {
        console.error("Failed to start multipart upload:", await response.text());
        return null;
      }
  
      const data = await response.json();
      return { uploadId: data.uploadId, chunkFileNameBase: data.chunkFileNameBase };
    } catch (error) {
      console.error("Error starting multipart upload:", error);
      return null;
    }
  };
  
  const handleMediaChange = async (e) => {
    setLoading(true); 

    const file = e.target.files[0];
    if (!file) {
        setLoading(false); 
        return;
    }

    const MAX_SIZE = 10 * 1024 * 1024; //  10MB

    if (file.type.startsWith("image/") || (file.type.startsWith("video/") && file.size <= MAX_SIZE)) {
        // Handle small files
        const reader = new FileReader();
        reader.onloadend = () => {
            setMediaUrl(reader.result);
            setMediaType(file.type);
            setLoading(false); 
        };
        reader.readAsDataURL(file);
    } else if (file.type.startsWith("video/") && file.size > MAX_SIZE) {
        try {
            const { uploadId, chunkFileNameBase } = await startMultipartUpload(file.name);
            if (!uploadId) {
                showToast("Upload Error", "Failed to start multipart upload", "error");
                setLoading(false); 
                return;
            }
            // Chunking for large videos
            const CHUNK_SIZE = 10 * 1024 * 1024; // 10MB
            const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
            setTotalChunksState(totalChunks); // Set total chunks before the loop
            let parts = []; // To store parts information for completeUpload
            let blobParts = []; // To store Blob parts of each chunk
            for (let i = 0; i < totalChunks; i++) {
                setCurrentChunkIndex(i + 1); // Update current chunk index
                const start = i * CHUNK_SIZE;
                const end = Math.min(start + CHUNK_SIZE, file.size);
                const chunkFileName = `${chunkFileNameBase}`;
                try {
                    const data = await uploadChunk(file, start, end, i + 1, totalChunks, uploadId, chunkFileName, i + 1);
                    if (!data || !data.ETag) {
                        console.error(`Failed to upload chunk ${i + 1}: No ETag in response`);
                        continue; 
                    }
                    parts.push({ ETag: data.ETag, PartNumber: i + 1 });
                    const chunkBlob = new Blob([data.chunkData], { type: file.type });
                    blobParts.push(chunkBlob);
                } catch (error) {
                    console.error(`Failed to upload chunk ${i + 1}:`, error);
                }
            }
            try {
                const blobUrl = await completeUpload(file.name, uploadId, parts);
                if (!blobUrl) {
                    console.error("No URL returned from completeUpload");
                    showToast("Upload Error", "No URL returned from the upload process", "error");
                    setLoading(false); 
                    return;
                }
                console.log("Blob URL:", blobUrl); 
                setMediaUrl(blobUrl); 
                setMediaType(file.type);
            } catch (error) {
                console.error("Error completing upload and generating blob URL:", error);
                showToast("Upload Error", "An error occurred during the finalization of the upload process", "error");
            }
        } catch (error) {
            console.error("Error initializing multipart upload:", error);
            showToast("Upload Error", "An error occurred during the initialization of the upload process", "error");
        } finally {
            setLoading(false); 
        }
    } else {
        showToast("Invalid file type", "Please select an image or video file", "error");
        setMediaUrl(null);
        setMediaType(null);
        setLoading(false); 
    }
  };

  const uploadChunk = async (file, start, end, chunkIndex, totalChunks, uploadId, fileName, partNumber) => {
    if (!file || start >= end) {
        console.error("Invalid file or chunk boundaries");
        return;
    }

    const chunk = file.slice(start, end);
    const formData = new FormData();
    formData.append("fileChunk", chunk);
    formData.append("chunkIndex", chunkIndex);
    formData.append("totalChunks", totalChunks);
    formData.append("uploadId", uploadId);
    formData.append("fileName", fileName); 
    formData.append("partNumber", partNumber); 

    console.log(`fileChunk size: ${chunk.size}, chunkIndex: ${chunkIndex}, totalChunks: ${totalChunks}, uploadId: ${uploadId}, fileName: ${fileName}, partNumber: ${partNumber}`);

    try {
        const response = await fetch('/api/posts/upload-chunk', {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
        
            const errorText = await response.text();
            console.error("Failed to upload chunk:", errorText);
            return;
        }

    
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error uploading chunk:", error);
    }
  };

  const completeUpload = async (fileName, uploadId, parts) => {
    const response = await fetch('/api/posts/complete-upload', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName, uploadId, parts }),
    });
    if (!response.ok) {
      console.error("Failed to complete upload:", await response.text());
      return null;
    }
    const data = await response.json();
    const { url, fileId } = data; 
    if (!url || !fileId) {
      console.error("URL or FileID missing in the response");
      return null;
    }
    return { url, fileId }; 
  };

  return { handleMediaChange, mediaUrl, setMediaUrl, mediaType, setMediaType, currentChunkIndex, totalChunksState };
};

export default usePreviewMedia;