/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/


import { Box, Button, Flex, Grid, Image, Tooltip, useMediaQuery } from '@chakra-ui/react';
import { Link, Link as RouterLink } from 'react-router-dom';
import HiveMindSearch from '../HiveMindSearch';

//This is styles in the old way because it never gave me problems

const HiveMindBiology = () => {
    const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (max-width: 430px)");
    const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
    const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");

  return (
    <>


<Flex direction="column" align="center" justify="center" ml={isMac ? '-240px': isIpadEsq ? "-225px" : isSmallScreen ? "-25%" : "-200px"}>
<Box mt={-100} > 
  <HiveMindSearch />
</Box>
    <Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px">
        Biology 
    </Box>


{/* Center */}


<Flex direction="column" align="center" justify="center" ml={isMac ? '5': isSmallScreen ? "0%" : "0"}>
    <Tooltip label="Agricultural Science" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/AgriculturalScience">
            <Image src="agriculturalscience.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
    <Tooltip label="Anatomy" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/Anatomy">
                <Image src="anatomy.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
    <Tooltip label="Biochem" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/Biochem">
                <Image src="Biochem.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
    <Tooltip label="Bioengineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/Bioengineering">
                <Image src="bioengineering.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
    <Tooltip label="Bioinformatics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/Bioinformatics">
                <Image src="bioinformatics.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
    <Tooltip label="Wildlife Conservation" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/WildlifeConservation">
                <Image src="wildlifeconservation.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
    <Tooltip label="Physiology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
                <Link to="/tags/Physiology">
                    <Image src="physiology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
                </Link>
            </Box>
        </Tooltip>
        
        <Tooltip label="Biomechanics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
                <Link to="/tags/Biomechanics">
                    <Image src="biomechanics.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
                </Link>
            </Box>
        </Tooltip>
</Flex>

   
    
    </ Flex>
    
    {/* Left 1 */}
    <Flex 
  direction="column" 
  align="center" 
  justify="center" 
  mt={isIpadEsq ? "-1129" : isSmallScreen ? "-750" : "-750"} 
  ml={isMac ? '-400px' : isIpadEsq ? "-500px" : isSmallScreen ? "-80%" : "-370px"}>
<Tooltip label="Biomedengineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/Biomedengineering">
        <Image src="biomedengineering.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </ Link>
    </Box>
</Tooltip>
<Tooltip label="Biophysics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/Biophysics">
        <Image src="biophysics.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </ Link>
    </Box>
</Tooltip>
<Tooltip label="Biotechnology" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/Biotechnology">
        <Image src="biotechnology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </ Link>
    </Box>
</Tooltip>
<Tooltip label="Botany" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/Botany">
        <Image src="botany.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </ Link>
    </Box>
</Tooltip>
<Tooltip label="Marine Biology" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/MarineBiology">
        <Image src="marinebiology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </ Link>
    </Box>
</Tooltip>
<Tooltip label="Cell Biology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
                <Link to="/tags/CellBiology">
                    <Image src="cellbiology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
                </Link>
            </Box>
        </Tooltip>


</Flex>
{/* Left 2 */}

{!isSmallScreen && (
    <Flex direction="column" align="center" justify="center" mt={-450} ml={-525}>
        <Tooltip label="Physiology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
                <Link to="/tags/Physiology">
                    <Image src="physiology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
                </Link>
            </Box>
        </Tooltip>
        
        <Tooltip label="Biomechanics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
                <Link to="/tags/Biomechanics">
                    <Image src="biomechanics.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
                </Link>
            </Box>
        </Tooltip>

        <Tooltip label="Cell Biology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
                <Link to="/tags/CellBiology">
                    <Image src="cellbiology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
                </Link>
            </Box>
        </Tooltip>
    </Flex>
)}

{/* Right 1*/}

<Flex 
  direction="column" 
  align="center" 
  justify="center" 
  mt={isIpadEsq ? "-900" : isSmallScreen ? "-600" : "-450"} 
  ml={isMac ? '-5%' : isIpadEsq ? "10%" : isSmallScreen ? "35%" : "-5%"}>
    <Tooltip label="Computational Biology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/ComputationalBiology">
                <Image src="computationalbiology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
    <Tooltip label="Evolutionary Biology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/EvolutionaryBiology">
                <Image src="evolutionarybiology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
    <Tooltip label="Human Biology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/HumanBiology">
                <Image src="humanbiology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
    <Tooltip label="Neuroscience" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/Neuroscience">
                <Image src="neuroscience.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
    <Tooltip label="Zoology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
        <Box>
            <Link to="/tags/Zoology">
                <Image src="zoology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
            </Link>
        </Box>
    </Tooltip>
        <Tooltip label="Genetics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
                <Link to="/tags/Genetics">
                    <Image src="genetics.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
                </Link>
            </Box>
        </Tooltip>
        <Tooltip label="Microbiology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
                <Link to="/tags/Microbiology">
                    <Image src="microbiology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
                </Link>
            </Box>
        </Tooltip>
</Flex>


{/* Right 2*/}

{!isSmallScreen && (
    <Flex direction="column" align="center" justify="center" mt={-450} ml={150}>
        <Tooltip label="Genetics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
                <Link to="/tags/Genetics">
                    <Image src="genetics.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
                </Link>
            </Box>
        </Tooltip>
        <Tooltip label="Microbiology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
                <Link to="/tags/Microbiology">
                    <Image src="microbiology.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
                </Link>
            </Box>
        </Tooltip>
    </Flex>
)}




</>


    
  )
}

export default HiveMindBiology