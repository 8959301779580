/* Ave Maria gratia plena, Dominus tecum, benedicta tu in mulieribus et benedictus fructus 
ventris tui Iesu, Santa Maria, mater Dei, ora pro nobis peccatoribus, nunc et in ora mortis nostrae */

import React, { useEffect, useState } from 'react'
import UserHeader from '../components/UserHeader'
import UserPost from '../components/UserPost'
import { useParams } from 'react-router-dom'
import { Container, Flex, Spinner, Text, Box } from "@chakra-ui/react";
import useShowToast from '../hooks/useShowToast'
import Post from '../components/Post';
import useGetUserProfile from '../hooks/useGetUserProfile';
import { useRecoilState } from 'recoil';
import postsAtom from '../atoms/postsAtom';
import { set } from 'date-fns';
import { useMediaQuery } from "@chakra-ui/react";
import BioSidebar from '../components/BioSidebar';
import './UserPage.css';


const UserPage = () => {
  const { user, loading } = useGetUserProfile()
  const {username}= useParams()
  const showToast = useShowToast()
  const [posts, setPosts] = useRecoilState(postsAtom)
  const [fetchingPosts, setFetchingPosts] = useState(true)
  const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (max-width: 430px)");
  const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
  const [isIphone] = useMediaQuery([
    "(min-device-width: 375px) and (max-device-width: 430px) and (min-device-height: 812px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3)"
  ]);
  

  useEffect(() => {
    const visits = localStorage.getItem('visits') ? parseInt(localStorage.getItem('visits'), 10) : 0;
  
    if (visits < 10) {
      showToast(
        'Certify your professional credentials',
        'Go to updateProfile.',
        'info',
        5000,
        true,
        'top',
        'solid',
        'yellow'
      );
  
      localStorage.setItem('visits', visits + 1);
    }
  }, [showToast]);
  
  useEffect(() => {
    setPosts([]);
  
    const getPosts = async () => {
      if (!user) return;
      setFetchingPosts(true);
      try {
        const res = await fetch(`/api/posts/user/${username}`);
        const data = await res.json();
        console.log(data);
        setPosts(data);
      } catch (error) {
        showToast("Error", error.message, "error");
        setPosts([]);
      } finally {
        setFetchingPosts(false);
      }
    };
  
    getPosts();
  }, [username, showToast, setPosts, user]);

  if(!user && loading){
    return (
      <Flex>
      <Spinner size="xl" />
      </Flex>
    )}

  if(!user && !loading) return <Flex height="1vh" ml={225}>
  <Text color={'yellow.400'} fontFamily={'Orbitron, sans-serif'} fontSize="xl">
  User not found
  </Text>
</Flex>

return (

  <div style={{
    marginLeft: isIpadEsq ? '0px' : (isSmallScreen ? '-70px' : '-190px'), 
    overflowX: 'visible', 
    overflowY: 'visible' 
  }}>
      <div style={{ transform: isSmallScreen ? 'scale(1)' : 'scale(0.9)' }}>
        <UserHeader user={user} />
      </div>
    {!fetchingPosts && posts.length === 0 && (
      <Flex height="1vh" ml={isSmallScreen ? '5px' : (isIpadEsq ? '0px' : '225px')} mt={10}>
        <Text color={'yellow.400'} fontFamily={'Orbitron, sans-serif'} fontSize="xl">
          No Posts Made
        </Text>
      </Flex>
    )}
    {fetchingPosts && (
      <Flex justify={'center'} my={12}>
        <Spinner size={"xl"} />
      </Flex>
    )}
    <div style={{ marginLeft: isIpadEsq ? '-150px' : '0', marginRight: isIpadEsq ? '50px' : '0' }}>
      {posts.map((post) => (
        <div key={post._id} style={{ transform: isSmallScreen ? 'scale(1)' : 'scale(0.9)' }}>
          <Post post={post} postedBy={post.postedBy} />
        </div>
      ))}
  
      <Box flex={0} position="fixed" right={0} top={0} height="100vh" overflowY="auto">
        <BioSidebar isIphone={false} isSmallScreen={isSmallScreen} user={user}/>
      </Box>
    </div>
  </div>

)
}

export default UserPage
