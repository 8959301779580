 /* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/
import React, { useEffect, useState } from 'react'
import { Box, Flex, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react'
import SuggestedUser from './SuggestedUser';
import useShowToast from '../../hooks/useShowToast';
import { useMediaQuery } from "@chakra-ui/react";

const SuggestedUsers = ({user}) => {
    const [loading, setLoading] = useState(true);
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
    const showToast = useShowToast();

    useEffect(() => {
        const getSuggestedUsers = async() => {
            setLoading(true)
            try {
                const res = await fetch('/api/users/suggested');
                const data = await res.json();
                if(data.error){
                    showToast("Error", data.error, "error");
                    return;
                }
                setAllUsers(data); 
                const initialUsersCount = isIpadEsq ? 5 : 3;
                setSuggestedUsers(data.slice(0, initialUsersCount));
            } catch (error) {
                showToast("Error", error.message, "error")
            } finally {
                setLoading(false)
            }
        }
        getSuggestedUsers();
    },[showToast, isIpadEsq]) 
    
    const handleButtonClick = (id) => {
        setTimeout(() => {
            setSuggestedUsers(prevUsers => {
                const newUsers = prevUsers.filter(user => user._id !== id); 
                while (newUsers.length < (isIpadEsq ? 5 : 3)) {
                    const nextUser = allUsers.find(user => !newUsers.some(prevUser => prevUser._id === user._id)); 
                    if (!nextUser) break; 
                    newUsers.push(nextUser); 
                }
                return newUsers;
            });
        }, 6000);
    }
    
    return (
        <Flex direction={'column'} gap={4}>
            {!loading && suggestedUsers.map(user => 
                <SuggestedUser key={user._id} user={user} onButtonClick={() => handleButtonClick(user._id)} />
            )}
            {loading && [0,1,2,3,4].map((_,idx) => ( 
               <Flex key={idx} gap={2} position="absolute" left={0} p={'1'} borderRadius={'md'}>
                    <Box>
                        <SkeletonCircle size={10} />
                    </Box>
                    <Flex w={'full'} flexDirection={'column'} gap={3}>
                        <Skeleton h={'8px'} w={'80px'} />
                        <Skeleton h={'8px'} w={'90%'} />
                    </Flex>
                    <Flex>
                        <Skeleton h={'20px'} w={'90px'} />
                    </Flex>
                </Flex>
            ))}
        </Flex>
    )
}

export default SuggestedUsers

/* import React, { useEffect, useState } from 'react'
import { Box, Flex, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react'
import SuggestedUser from './SuggestedUser';
import useShowToast from '../../hooks/useShowToast';

const SuggestedUsers = ({user}) => {
    const [loading, setLoading] = useState(true);
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    const showToast = useShowToast();

    useEffect(() => {
        const getSuggestedUsers = async() => {
            setLoading(true)
            try {
                const res = await fetch('/api/users/suggested');
                const data = await res.json();
                if(data.error){
                    showToast("Error", data.error, "error");
                    return;
                }
                setSuggestedUsers(data.slice(0, 3)); // initially get the first 3 users
            } catch (error) {
                showToast("Error", error.message, "error")
            } finally {
                setLoading(false)
            }
        }
        getSuggestedUsers();
    },[showToast])

    const handleButtonClick = (id) => {
        setTimeout(() => {
            setSuggestedUsers(prevUsers => {
                const newUsers = prevUsers.filter(user => user._id !== id); // remove the followed user
                const nextUser = suggestedUsers.find(user => !prevUsers.includes(user)); // find a user not in the current list
                if (nextUser) {
                    newUsers.push(nextUser); // add the new user to the list
                }
                return newUsers;
            });
        }, 10000);
    }

    return (
        <Flex direction={'column'} gap={4}>
            {!loading && suggestedUsers.map(user => 
                <SuggestedUser key={user._id} user={user} onButtonClick={() => handleButtonClick(user._id)} />
            )}
            {loading && [0,1,2,3,4].map((_,idx) => ( 
                <Flex key={idx} gap={2} alignItems={'center'} p={'1'} borderRadius={'md'}>
                    <Box>
                        <SkeletonCircle size={10} />
                    </Box>
                    <Flex w={'full'} flexDirection={'column'} gap={3}>
                        <Skeleton h={'8px'} w={'80px'} />
                        <Skeleton h={'8px'} w={'90%'} />
                    </Flex>
                    <Flex>
                        <Skeleton h={'20px'} w={'90px'} />
                    </Flex>
                </Flex>
            ))}
        </Flex>
    )
}

export default SuggestedUserss */