/* Gegrüßet seist du, Maria, voll der Gnade der Herr ist mit dir. Du bist gebenedeit unter den Frauen,
und gebenedeit ist die Frucht deines Leibes, Jesus Heilige Maria, Mutter Gottes bitte für uns Sünder
jetzt und in der Stunde unseres Todes Amen
*/

import { Box, Button,Image,  Flex, Input, Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalCloseButton, ModalBody, ModalFooter, Text, CloseButton, Spinner } from '@chakra-ui/react'
import React from 'react'
import ReactPlayer from 'react-player'
import { useEffect, useState, useRef } from 'react'
import { LiaCameraRetroSolid } from "react-icons/lia";
import FundedCard from '../components/FundedCard'
import { Textarea } from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import usePreviewMedia from '../hooks/usePreviewImg'
import  useShowToast  from '../hooks/useShowToast'

const FundingPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false)
  const { mediaUrl, setMediaUrl } = usePreviewMedia(setLoading);
  const fileInputRef = useRef(null);
  const [blob, setBlob] = useState(null);
const [mediaFile, setMediaFile] = useState(null);
const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
const [totalChunksState, setTotalChunksState] = useState(0);
const [mediaType, setMediaType] = useState(null); 

  const showToast = useShowToast()

  const fetchUserInfo = async () => {
    try {
      const userId = localStorage.getItem('userId'); 
      const response = await fetch('/api/users/userinfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ userId }) 
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Fetched user info:', data);
      return data;
    } catch (error) {
      console.error('Failed to fetch user info:', error);
      return null;
    }
  };

  const startMultipartUpload = async (fileName) => {
    try {
      const response = await fetch('/api/posts/start-multipart-upload', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileName }),
      });
  
      if (!response.ok) {
        console.error("Failed to start multipart upload:", await response.text());
        return null;
      }
  
      const data = await response.json();
      return { uploadId: data.uploadId, chunkFileNameBase: data.chunkFileNameBase };
    } catch (error) {
      console.error("Error starting multipart upload:", error);
      return null;
    }
  };

 
  const handleMediaChange = async (e) => {
    setLoading(true); 

    const file = e.target.files[0];
    setMediaFile(file); 

    if (!file) {
        setLoading(false); 
        return;
    }

    const MAX_SIZE = 10 * 1024 * 1024; //  10MB

    if (file.type.startsWith("image/") || (file.type.startsWith("video/") && file.size <= MAX_SIZE)) {
        // Handle small files
        const reader = new FileReader();
        reader.onloadend = () => {
            setMediaUrl(reader.result);
            setMediaType(file.type);
            setLoading(false); 
        };
        reader.readAsDataURL(file);
    } else if (file.type.startsWith("video/") && file.size > MAX_SIZE) {
        try {
            const { uploadId, chunkFileNameBase } = await startMultipartUpload(file.name);
            if (!uploadId) {
                showToast("Upload Error", "Failed to start multipart upload", "error");
                setLoading(false); 
                return;
            }
            // Chunking for large videos
            const CHUNK_SIZE = 10 * 1024 * 1024; // 10MB
            const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
            setTotalChunksState(totalChunks); // Set total chunks before the loop
            let parts = []; // To store parts information for completeUpload
            let blobParts = []; // To store Blob parts of each chunk
            for (let i = 0; i < totalChunks; i++) {
                setCurrentChunkIndex(i + 1); // Update current chunk index
                const start = i * CHUNK_SIZE;
                const end = Math.min(start + CHUNK_SIZE, file.size);
                const chunkFileName = `${chunkFileNameBase}`;
                try {
                    const data = await uploadChunk(file, start, end, i + 1, totalChunks, uploadId, chunkFileName, i + 1);
                    if (!data || !data.ETag) {
                        console.error(`Failed to upload chunk ${i + 1}: No ETag in response`);
                        continue; 
                    }
                    parts.push({ ETag: data.ETag, PartNumber: i + 1 });
                    const chunkBlob = new Blob([data.chunkData], { type: file.type });
                    blobParts.push(chunkBlob);
                } catch (error) {
                    console.error(`Failed to upload chunk ${i + 1}:`, error);
                }
            }
            try {
                const blobUrl = await completeUpload(file.name, uploadId, parts);
                if (!blobUrl) {
                    console.error("No URL returned from completeUpload");
                    showToast("Upload Error", "No URL returned from the upload process", "error");
                    setLoading(false); 
                    return;
                }
                console.log("Blob URL:", blobUrl); 
                setMediaUrl(blobUrl); 
                setMediaType(file.type);
            } catch (error) {
                console.error("Error completing upload and generating blob URL:", error);
                showToast("Upload Error", "An error occurred during the finalization of the upload process", "error");
            }
        } catch (error) {
            console.error("Error initializing multipart upload:", error);
            showToast("Upload Error", "An error occurred during the initialization of the upload process", "error");
        } finally {
            setLoading(false); 
        }
    } else {
        showToast("Invalid file type", "Please select an image or video file", "error");
        setMediaUrl(null);
        setMediaType(null);
        setLoading(false); 
    }
  };

  const submitForFunding = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('title', title);
    formData.append('summary', summary);
    if (mediaFile) {
      formData.append('media', mediaFile);
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  

    try {
      const response = await fetch('/api/posts/submitforfunding', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        console.log('SUCCESS!');
        setIsOpen(false);
        showToast('Success', 'Submission successful!', 'success');
      } else {
        console.log('FAILED...', response.statusText);
      }
    } catch (err) {
      console.log('FAILED...', err);
    }
    console.log('Form Data:', {
      name,
      email,
      subject,
      title,
      summary,
      mediaFile
    });
  };



  const uploadChunk = async (file, start, end, chunkIndex, totalChunks, uploadId, fileName, partNumber) => {
    if (!file || start >= end) {
        console.error("Invalid file or chunk boundaries");
        return;
    }

    const chunk = file.slice(start, end);
    const formData = new FormData();
    formData.append("fileChunk", chunk);
    formData.append("chunkIndex", chunkIndex);
    formData.append("totalChunks", totalChunks);
    formData.append("uploadId", uploadId);
    formData.append("fileName", fileName); 
    formData.append("partNumber", partNumber); 

    console.log(`fileChunk size: ${chunk.size}, chunkIndex: ${chunkIndex}, totalChunks: ${totalChunks}, uploadId: ${uploadId}, fileName: ${fileName}, partNumber: ${partNumber}`);

    try {
        const response = await fetch('/api/posts/upload-chunk', {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
        
            const errorText = await response.text();
            console.error("Failed to upload chunk:", errorText);
            return;
        }

    
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error uploading chunk:", error);
    }
  };

  const completeUpload = async (fileName, uploadId, parts) => {
    const response = await fetch('/api/posts/complete-upload', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName, uploadId, parts }),
    });
    if (!response.ok) {
      console.error("Failed to complete upload:", await response.text());
      return null;
    }
    const data = await response.json();
    const { url, fileId } = data; 
    if (!url || !fileId) {
      console.error("URL or FileID missing in the response");
      return null;
    }
    return { url, fileId }; 
  };
  
  const handleOpen = async () => {
    const userInfo = await fetchUserInfo();
    if (userInfo) {
      setName(userInfo.name);
      setEmail(userInfo.email);
    }
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);





  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMediaUrl(file);
    }
  };

  const convertBase64ToBlobUrl = (base64String) => {
    const parts = base64String.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
  
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
  
    const blob = new Blob([uInt8Array], { type: contentType });
    return URL.createObjectURL(blob);
  };


  return (
    <>
    <Flex
  
    >
<Text fontWeight='bold' fontFamily={'Orbitron, sans-serif'} 
  ml={100}  whiteSpace="nowrap"
 color="black" mt="-15px"
 fontSize="4xl" 
 style={{ WebkitTextStroke: '1px rgb(135,206,250)' }}
 >Got Funding?</Text>
      <Box ml={-450} mt={10}> 
        <ReactPlayer url="https://www.youtube.com/watch?v=dQw4w9WgXcQ" /> 
      </Box>
<Text ml ={-640} mt={420} fontFamily={'Orbitron, sans-serif'} 
 >
    At Ark, we believe in empowering innovators. We do this not only by our media presence 
    and makretplace, but also by investing in ideas we believe can change the world. If you beleive you
    can change the world, submit your idea to us today.
 </Text>
</Flex>

<Button
        ml={150}
        mt={25}
        colorScheme="blue"
        fontFamily={'Orbitron, sans-serif'}
        variant="solid"
        onClick={handleOpen}
      >
        Apply
      </Button>

      <Modal onClose={handleClose} size="md" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent bg={'black'}>
          <ModalHeader fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)">
            <Text textAlign="center">Submission</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)">Name</Text>
            <Input
              placeholder="Type your name here..."
              fontFamily={'Orbitron, sans-serif'}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Text mt={4} fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)">Email</Text>
            <Input
              placeholder="Type your email here..."
              fontFamily={'Orbitron, sans-serif'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

<Text mt={4} fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)">Subject</Text>
<Text 
    fontFamily={'Orbitron, sans-serif'}
    color="white"
    backgroundColor="black"
    padding="10px"
    borderRadius="5px"
>
    Funding Submission by {name}
</Text>

<Text mt={4} fontFamily={'Orbitron, sans-serif'} 
 color="rgb(135,206,250)"> Title of Idea </Text>
<Input 
    placeholder="Type title here" 
    fontFamily={'Orbitron, sans-serif'}
    value={title}
    onChange={(e) => setTitle(e.target.value)}
  />

<Text mt={4} fontFamily={'Orbitron, sans-serif'} 
 color="rgb(135,206,250)"> Summary of Idea </Text>
<Textarea 
    placeholder="Summarize what your product is about" 
    fontFamily={'Orbitron, sans-serif'}
    height='200px'
    value={summary}
    onChange={(e) => setSummary(e.target.value)}
  />

<Text mt={4} fontFamily={'Orbitron, sans-serif'} 
 color="rgb(135,206,250)"> Submit Media </Text>

<div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleMediaChange}
                accept="image/*,video/*"
              />
              <Button onClick={() => fileInputRef.current.click()}>
                <LiaCameraRetroSolid />
              </Button>
            </div>

            {loading ? (
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '10vh', 
          transform: 'translateY(20px)' 
        }}>
          <Text fontFamily={'Orbitron, sans-serif'} 
          color="rgb(135,206,250)" style={{ marginBottom: '20px' }}> 
            Uploading Your Video {currentChunkIndex} of {totalChunksState}
          </Text>
          <Spinner /> 
        </div>
      ) : (
        <>
        {typeof mediaUrl === 'string' ? (
          mediaUrl && (
            <Flex mt={5} w={'full'} position={'relative'}>
              {(mediaUrl.startsWith('data:video/') || mediaUrl.startsWith('blob:')) ? (
                <ReactPlayer url={mediaUrl.startsWith('data:video/') ? convertBase64ToBlobUrl(mediaUrl) : mediaUrl} controls={true} width='320px' height='240px' />
              ) : (
                <Image src={mediaUrl} />
              )}
              <CloseButton 
                onClick={() => {
                  setMediaUrl('');
                  //setMediaType('');
                }} 
                bg={'gray.800'} 
                position={'absolute'} 
                top={2} 
                right={2}
              />
            </Flex>
          )
        ) : (
          mediaUrl && mediaUrl.url && (
            <Flex mt={5} w={'full'} position={'relative'}>
              <ReactPlayer url={mediaUrl.url} controls={true} width='320px' height='240px' />
              <CloseButton 
                onClick={() => {
                  setMediaUrl('');
                  setMediaType('');
                }} 
                bg={'gray.800'} 
                position={'absolute'} 
                top={2} 
                right={2}
              />
            </Flex>
          )
        )}
      </>
    )}
            

</ModalBody>
    <ModalFooter>
    <Button 
   onClick={submitForFunding}
   style={{
     backgroundColor: 'black',
     color: '#FF6EC7',
     borderColor: '#FF6EC7',
     borderWidth: '2px',
     fontFamily: 'Orbitron, sans-serif'
   }}>
  Send
</Button>
    </ModalFooter>
  </ModalContent>
</Modal>

<Flex

    >
<Text fontWeight='bold' fontFamily={'Orbitron, sans-serif'} 
color="black" mt={30}  whiteSpace="nowrap" ml={100}
fontSize="4xl" 
style={{ WebkitTextStroke: '1px rgb(135,206,250)' }}

> Ark Investments </Text>
<Text>

</Text>
<Flex ml={-500} mt={40} width="300px">
<FundedCard img ='/retvrn.jpg' name='RETVRN' description='RETVRN was founded by Nicholas Stumphauzer with the intent of providing users of technology with greater degrees of control over their devices in an age where tech is demaning increasingly more from us'/>
</ Flex>
<Flex ml={0} mt={40} width="300px" >
<FundedCard img='/modlee.jpg' link="https://www.modlee.ai/" name='Modlee' description='Modlee is a pioneer
in the AI development and is optimizing the Machine Learning process' />
</Flex>
</Flex>
</>
  )
}

export default FundingPage