/*שָלוֹם לָךְ, מִרְיָם,
מְלֵאַת הַחֶסֶד,
ה׳ עִמָּךְ,
בְּרוּכָה אַתְּ בַּנָשִׁים
וּבָרוּךְ פְּרִי בִטְנֵךְ יֵשׁוּעַ,
מִרְיָם הַקְּדוֹשָׁה,
אֵם הָאֱלֹהִים,
הִתְפַּלְּלִי בַּעֲדֵנוּ, הַחוֹטְאִים,
עַתָּה וּבִשְׁעַת מוֹתֵנוּ. */

import React from 'react';
import { ChakraProvider, extendTheme, Box, Flex, Image, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { mode } from "@chakra-ui/theme-tools";
import { useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import HiveMindSearch from '../HiveMindSearch';

const HiveMindMisc = () => {
    const styles = {
        global: (props) => ({
          body: {
            bg: 'black',
            color: mode('gray.800', 'whiteAlpha.900')(props),
          },
        }),
      };
    
      const theme = extendTheme({ styles });
    
      const center = useBreakpointValue({
        base: "10%", //iphone
        sm: "10%",
        md: "31%", //ipad Mini
        lg: "39%",
        xl: "39%"
      });
    
      const left = useBreakpointValue({
        base: "15%",
        sm: "15%",
        md: "30%",
        lg: "40%",
        xl: "32%"
      });
    
      const right = useBreakpointValue({
        base: "60%",
        sm: "65%",
        md: "60%",
        lg: "55%",
        xl: "59%"
      });
    
      const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
      const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
      const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");
    
      const adjustedCenter = isIphoneXR ? "15%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : center;
      const adjustedLeft = isIphoneXR ? "20%" : isIphone14ProMax ? "20%" : isIpadPro ? "30%" : left;
      const adjustedRight = isIphoneXR ? "60%" : isIphone14ProMax ? "60%" : isIpadPro ? "65%" : right;
    

  return (

  <ChakraProvider theme={theme}>
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="30%" 
        left={adjustedCenter}
      >
    <Box mt={10}><HiveMindSearch /></Box>
    <Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px">
Miscellaneous 
</Box>

<Tooltip label="3DPrinting" bg="black" color="orange" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to= '/tags/3Dprinting'>
        <Image src="3dprinting.png" boxSize={isIpadPro ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>

<Tooltip label="Anthropology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to='/tags/anthropology'>
        <Image src="anthropology.png" boxSize={isIpadPro ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Archeology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
    <Box> 
        <Link to='/tags/archeology' >
        <Image src="archeology.png" boxSize={isIpadPro ? "150px" : "100px"}/>
        </ Link>
    </Box>
</Tooltip>
<Tooltip label="Blacksmithing" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/blacksmithing' >
        <Image src="blacksmithing.png" boxSize={isIpadPro ? "150px" : "100px"}/>
        </ Link>
    </Box>
</Tooltip>
<Tooltip label="Paleontology" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/paleontology' >
        <Image src="paleontology.png" boxSize={isIpadPro ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Forensic Science" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/forensicscience' >
        <Image src="forensicscience.png" boxSize={isIpadPro ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>

</Flex>



{/* Right */}
<Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="120%" 
        left={adjustedRight}
      >
<Tooltip label="Plumbing" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/plumbing'>
        <Image src="plumbing.png" boxSize={isIpadPro ? "125px" : "85px"}/>
    </Link>
    </Box>
</Tooltip>
<Tooltip label="Caligraphy" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/caligraphy' >
        <Image src="caligraphy.png" boxSize={isIpadPro ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Gunsmithing" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/gunsmithing' >
        <Image src="gunsmithing.png" boxSize={isIpadPro ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>

<Tooltip label="Organic Farming" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to='/tags/organicfarming'>
        <Image src="organicfarming.png" boxSize={isIpadPro ? "150px" : "100px"}/>
        </Link>
    </Box>
</Tooltip>
<Tooltip label="Trades" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/trades'>
        <Image src="trades.png" boxSize={isIpadPro ? "125px" : "85px"}/>
    </Link>
    </Box>
</Tooltip>
</Flex>




{/* Left */}
<Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="120%" 
        left={adjustedLeft}
      >
<Tooltip label="Entrepeneur" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/entrepeneur'>
        <Image src="entrepeneur.png" boxSize={isIpadPro ? "125px" : "85px"}/>
    </Link>
    </Box>
</Tooltip>
<Tooltip label="BehavioralEconomics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/behavioraleconomics'>
        <Image src="behavioraleconomics.png" boxSize={isIpadPro ? "150px" : "100px"}/>
    </Link>
    </Box>
</Tooltip>
<Tooltip label="Space Exploration" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/spaceexploration'>
        <Image src="spaceexploration.png" boxSize={isIpadPro ? "150px" : "100px"}/>
    </Link>
    </Box>
</Tooltip>
<Tooltip label="Materials Science" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/materialscience'>
        <Image src="materialscience.png" boxSize={isIpadPro ? "150px" : "100px"}/>
    </Link>
    </Box>
</Tooltip>
<Tooltip label="Architecture" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/architecture'>
        <Image src="architecture.png" boxSize={isIpadPro ? "150px" : "100px"}/>
    </Link>
    </Box>
</Tooltip>
<Tooltip label="Electrician" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
    <Link to='/tags/electrician'>
        <Image src="electrician.png" boxSize={isIpadPro ? "125px" : "85px"}/>
    </Link>
    </Box>
</Tooltip>
</Flex>
</ChakraProvider>
  )
}

export default HiveMindMisc