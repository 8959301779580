/* Χαῖρε Μαρία κεχαριτωμένη ὁ Κύριος μετά σοῦ Ἐυλογημένη σὺ ἐν γυναιξὶ, καὶ εὐλογημένος ὁ καρπὸς τῆς κοιλίας 
σοῦ Ἰησούς Ἁγία Μαρία, μῆτερ θεοῦ προσεύχου ὑπέρ ἡμῶν τῶν ἁμαρτωλῶν, νῦν καὶ ἐν τῇ ὥρᾳ τοῦ θανάτου ἡμῶν. */

import { useMediaQuery, Box, Text, Flex, Collapse, IconButton, Tooltip } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import SidebarItems from "./SidebarItems";
import { useRecoilValue } from 'recoil';


const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
    const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
    const iphoneMediaQuery = `
  @media only screen 
    and (-webkit-device-pixel-ratio: 3) 
    and (min-device-width: 375px) 
    and (max-device-width: 430px) 
    /* Styles for iPhone 12, 13, 14, 15 and their Pro/Plus/Max versions */
  }
`;

    const closeSidebar = () => setIsOpen(false);

    if (location.pathname === '/Rec') {
        return null;
    }
    
  return (
    <aside>

    <Tooltip backgroundColor="black" label="Tool box" color="gray.500" fontSize="md">
        <IconButton
            aria-label="Toggle sidebar"
            icon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setIsOpen(!isOpen)}
            position={"fixed"}
            top={"50%"}
            left={0}
            backgroundColor="black"
            color="rgb(135, 206, 250)"
            _hover={{ borderColor: "yellow.400", borderWidth: "2px" }}
            zIndex={999}
        />
        </Tooltip>
            <Collapse in={isOpen}>
            <Box 
    height={"65vh"} 
    border={"1px solid"} 
    borderRadius={'lg'} 
    borderColor={"gray.400"} 
    w={isIpadEsq ? "400px" : { base: "95%", sm: 230 }}
    position={"fixed"}
    top={isSmallScreen ? "10%" : 0}
    ml={{ base: "10px", sm: "50px" }}
    px={{ base: 10, md: 10 }} 
    mt={{ base: "100px", md: "200px" }}
    backgroundColor={iphoneMediaQuery || isSmallScreen ? "black" : ""}
    zIndex={1}
>
                    <Text fontSize={20} fontWeight={"bold"} fontFamily={"'Orbitron', sans-serif"} color=""
                        css={{WebkitTextStroke: "1px rgb(135, 206, 250)", }}>
                    </Text>
                    <Flex direction={'column'} gap={4}>
                    <SidebarItems closeSidebar={closeSidebar} size={isIpadEsq ? 'large' : 'normal'} height={isIpadEsq ? '500px' : 'auto'}/>
                    </Flex>
                </Box>
            </Collapse>
            
        </aside>
  );
} 

export default Sidebar;