/*שָלוֹם לָךְ, מִרְיָם,
מְלֵאַת הַחֶסֶד,
ה׳ עִמָּךְ,
בְּרוּכָה אַתְּ בַּנָשִׁים
וּבָרוּךְ פְּרִי בִטְנֵךְ יֵשׁוּעַ,
מִרְיָם הַקְּדוֹשָׁה,
אֵם הָאֱלֹהִים,
הִתְפַּלְּלִי בַּעֲדֵנוּ, הַחוֹטְאִים,
עַתָּה וּבִשְׁעַת מוֹתֵנוּ. */

import { Avatar, Box, Flex, Image, Skeleton, Text } from "@chakra-ui/react";
import { selectedConversationAtom } from "../atoms/messagesAtom";
import { useRecoilValue } from "recoil";
import userAtom from "../atoms/userAtom";
import { BsCheck2All } from "react-icons/bs";
import { useState } from "react";
import ReactPlayer from 'react-player';

const Message = ({ ownMessage, message, post }) => {
    const selectedConversation = useRecoilValue(selectedConversationAtom);
    const user = useRecoilValue(userAtom);
    const [imgLoaded, setImgLoaded] = useState(false);

    const isVideo = (url) => {
        const videoExtensions = ['mp4', 'mov', 'webm', 'avi', 'flv'];
        const extension = url.split('.').pop().toLowerCase();
        return videoExtensions.includes(extension);
    };

    return (
        <>
            {ownMessage ? (
                <Flex gap={2} alignSelf={"flex-end"}>
                    {message?.text && (
                        <Flex bg={"black"} maxW={"350px"} p={1} borderRadius={"md"}>
                            <Text color={"rgb(135, 206, 250)"} fontFamily={'Orbitron, sans-serif'}>{message?.text}</Text>
                            <Box
                                alignSelf={"flex-end"}
                                ml={1}
                                color={message?.seen ? "blue.400" : ""}
                                fontWeight={"bold"}
                            >
                                <BsCheck2All size={16} />
                            </Box>
                        </Flex>
                    )}
                    {message?.img && (
                        <Box borderRadius={6} overflow={'hidden'} border={'1px solid white'} borderColor={'gray.light'}>
                            {isVideo(message?.img) ? (
                           <ReactPlayer
                           url={message?.img.startsWith('http://') || message?.img.startsWith('https://') ? message?.img : `https://${message?.img}`}
                           width='100%'
                           height='100%'
                           controls
                           onReady={() => {
                               console.log('ReactPlayer loaded URL successfully:', message?.img);
                           }}
                           onError={(e) => {
                               console.error('ReactPlayer error with URL:', message?.img, e);
                           }}
                       />
                            ) : (
                                <Image src={message?.img} alt='Message image' w={'full'}/>
                            )}
                        </Box>
                    )}
                    <Avatar src={user.profilePic} name={user.username} w='7' h={7} />
                </Flex>
            ) : (
                <Flex gap={2}>
                    <Avatar src={selectedConversation.userProfilePic} name={user.username} w='7' h={7} />

                    {message?.text && (
                        <Text maxW={"350px"} bg={"black"} p={1} borderRadius={"md"} 
                        color={"orange"} fontFamily={'Orbitron, sans-serif'}>
                            {message?.text}
                        </Text>
                    )}
                    {message?.img && (
                        <Box borderRadius={6} overflow={'hidden'} border={'1px solid white'} borderColor={'gray.light'}>
                            {isVideo(message?.img) ? (
                                <ReactPlayer
                                    url={message?.img}
                                    width='100%'
                                    height='100%'
                                    controls
                                    onError={e => console.error('ReactPlayer error', e)}
                                />
                            ) : (
                                <Image src={message?.img} alt='Message image' w={'full'}/>
                            )}
                        </Box>
                    )}
                </Flex>
            )}
        </>
    );
};

export default Message;
