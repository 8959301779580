/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/

import React from 'react'
import { Box, Button,Flex, Text, FormControl,FormLabel,Heading, Input, Stack, useColorModeValue, HStack, Avatar,
  Center, useBreakpointValue, ChakraProvider, Modal, ModalHeader, ModalBody, ModalCloseButton, ModalContent,
  ModalFooter, ModalOverlay, useDisclosure,
  Spacer, Textarea, extendTheme,
  useMediaQuery,} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import userAtom from '../atoms/userAtom'
import usePreviewMedia from '../hooks/usePreviewImg'
import useShowToast from '../hooks/useShowToast'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { mode } from "@chakra-ui/theme-tools";

export default function UpdateProfilePage() {
  const [user, setUser] = useRecoilState(userAtom)
      const [inputs, setInputs] = useState({
      name: user.name,
      username:user.username,
      email:user.email,
      bio:user.bio,
      password:'',
      organization: user.organization,

 
  });
  const fileRef = useRef(null)
  const showToast = useShowToast()
  const [loading, setLoading] = React.useState(false);
  const { mediaUrl, handleMediaChange } = usePreviewMedia(setLoading);
  const [isFormOpen, setIsFormOpen] = useState(true);
  const navigate = useNavigate();  
  const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (max-width: 430px)");
  const [iPhone14] = useMediaQuery('(min-width:430px) and (min-height: 926px)')
  const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
  const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [reason, setReason] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const res = await fetch(`/api/users/update/${user._id}`, {
            method: "PUT",
            headers: {
                "Content-Type" : "application/json",
            },
            body: JSON.stringify({...inputs, profilePic: mediaUrl}),
        })
        const data = await res.json()
        if(data.error){
            showToast("Error", data.error, "error")
            return;
        }
        showToast("Success", "Profile Updated", "success")
        setUser(data)
        localStorage.setItem("user-ark", JSON.stringify(data))
        setIsFormOpen(false); 
        console.log(`Redirecting to /${data.username}`); 
        navigate(`/${data.username}`); 
    } catch (error) {
        showToast("Error", error.message || "An unknown error occurred", "error")
    }
}

const handleReport = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch('/api/users/credential', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  
        reason, 
        userId: user._id,
        userName: user.name
      }), 
    });
    if (response.ok) {
      showToast('Report submitted successfully', 'success');
      onClose(); 
    } else {
      showToast('Failed to submit report', 'error');
    }
  } catch (error) {
    console.error('Error:', error);
    showToast('An error occurred while submitting the report', 'error');
  }
};


const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
      e.preventDefault(); 
      document.querySelector('button[type="submit"]').click(); 
  }
};
const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'black',
        color: 'whiteAlpha.900',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        margin: 0,
        padding: 0,
      },
    },
  },
});

const center = useBreakpointValue({
  base: "-20%", // iPhone
  sm: "10%",
  md: "-25%", // iPad Mini
  lg: "-20%",
  xl: "39%"
});

const moveheader = useBreakpointValue({
  base: "-10%", // iPhone
  sm: "10%",
  md: "-25%", // iPad Mini
  lg: "-20%",
  xl: "39%"
});

const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");

const adjustedCenter = isIphoneXR ? "0%" : isIphone14ProMax ? "-5%" : isIpadPro ? "-20%" : center;
const adjustedHeader = isIphoneXR ? "0%" : isIphone14ProMax ? "-5%" : isIpadPro ? "-20%" : moveheader;

return (
  <>

  {isFormOpen && (
    <ChakraProvider theme={theme}>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', left: adjustedCenter }}>
      <Heading
  lineHeight={1.1}
            position="relative"
  fontSize={{ base: '2xl', sm: '3xl' }}
  fontFamily={'Orbitron, sans-serif'}
  color="rgb(135,206,250)"
left={adjustedHeader}
>
          Edit Profile
        </Heading>
      
          <Stack direction="column" alignItems="center" justifyContent="center" width="100%">
            <FormControl id="userName" mt={4}>
              <Stack direction="column" spacing={6} alignItems="center" justifyContent="center">
                <Center mt="4" w="full" justifyContent="center">
                  <Avatar   direction="column" 
        align="center" mt='75'
        justify="center" 
        position="absolute" left={adjustedCenter} size="xl" src={mediaUrl || user.profilePic} boxShadow="md" />
                </Center>
                <Center w="full" justifyContent="center">
                  <Button  direction="column" 
        align="center" 
        justify="center" 
        position="absolute"  left={adjustedCenter} mt="175" size="sm" w="auto" 
        onClick={() => fileRef.current.click()} fontFamily="Orbitron, sans-serif" zIndex="10" 
        minWidth="100px" 
      >
                    Change Avatar
                  </Button>
                  <Button direction="column" 
        align="center"  onClick={onOpen}
        justify="center" 
        position="absolute"  ml='100' mt="175" size="sm" w="auto" 
         fontFamily="Orbitron, sans-serif" zIndex="10" 
        minWidth="100px" color="yellow.500">Show Credentials</Button>
                  <Input type="file" hidden ref={fileRef} onChange={handleMediaChange} />
                </Center>
                <FormControl>
                  <FormLabel  direction="column" 
        align="center" 
        justify="center" mt='100'
        position="absolute"  left={adjustedCenter} fontFamily="Orbitron, sans-serif" color="rgb(135,206,250)" width="auto">Full Name</FormLabel>
                  <Box w="15vw">
                  <Input
                      mt='130'
                      placeholder={user.name || "Gabriel Gutierrez"}
                      value={inputs.fullname}
                      onChange={(e) => setInputs({ ...inputs, fullname: e.target.value })}
                      _placeholder={{ color: 'gray.500' }}
                      type="text"
                      onKeyDown={handleKeyDown}
                      fontFamily="Orbitron"
                      width="100%"
                      left={adjustedCenter}
                      direction="column"
                      align="center"
                      justify="center"
                      position="absolute"
                      zIndex="10" 
        minWidth="100px"
                    />
                  </Box>
                </FormControl>
              </Stack>
            </FormControl>
          </Stack>
      
        <FormControl>
          <FormLabel left={adjustedCenter}  direction="column" 
        align="center" 
        justify="center" 
        position="absolute" fontFamily="Orbitron, sans-serif" color="rgb(135,206,250)" width="auto" mt="190">User name</FormLabel>
          <Box w="20vw" mx="auto">
          <Input
                mt='220'
                direction="column"
                align="center"
                justify="center"
                position="absolute"
                value={inputs.username}
                left={adjustedCenter}
                onChange={(e) => setInputs({ ...inputs, username: e.target.value })}
                onKeyDown={handleKeyDown}
                _placeholder={{ color: 'gray.500' }}
                type="text"
                fontFamily="Orbitron, sans-serif"
                width="100%"
                zIndex="10" 
        minWidth="100px"
              />
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel  direction="column" 
        align="center" mt='280'
        justify="center" 
        position="absolute"  left={adjustedCenter} fontFamily="Orbitron, sans-serif" 
        color="rgb(135,206,250)" width="auto" >Organization</FormLabel>
          <Box w="15vw" mx="auto">
          <Input
                mt='310'
                direction="column"
                align="center"
                justify="center"
                position="absolute"
                left={adjustedCenter}
                placeholder="Guitarras Gutierrez"
                value={inputs.organization}
                onChange={(e) => setInputs({ ...inputs, organization: e.target.value })}
                _placeholder={{ color: 'gray.500' }}
                type="text"
                onKeyDown={handleKeyDown}
                fontFamily="Orbitron"
                width="100%"
                zIndex="10" 
        minWidth="100px"
              />
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel  direction="column" 
        align="center" 
        justify="center" 
        position="absolute"  left={adjustedCenter} fontFamily="Orbitron, sans-serif" color="rgb(135,206,250)" mt="360" width="auto">Bio</FormLabel>
          <Box w="25vw" mx="auto">
            <Textarea
             direction="column" 
             align="center" 
             justify="center" 
             position="absolute" 
            left={adjustedCenter}
              placeholder="Tell us about yourself..."
              value={inputs.bio}
              onChange={(e) => setInputs({ ...inputs, bio: e.target.value })}
              _placeholder={{ color: 'gray.500' }}
              fontFamily="Orbitron"
              width="100%"
              mt='380'
              zIndex="10" 
        minWidth="100px"
        maxLength={500}
            />
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel  direction="column" 
        align="center" 
        justify="center" 
        position="absolute" mt='470' left={adjustedCenter} fontFamily="Orbitron, sans-serif" color="rgb(135,206,250)"  width="auto">
            Change Password
          </FormLabel>
          <Box w="20vw" mx="auto" mr={2}>
          <Input
                direction="column"
                align="center"
                justify="center"
                position="absolute"
                placeholder="password"
                left={adjustedCenter}
                value={inputs.password}
                onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                onKeyDown={handleKeyDown}
                _placeholder={{ color: 'gray.500' }}
                type="password"
                fontFamily="Orbitron"
                width="100%"
                mt='500'
                zIndex="10" 
        minWidth="100px"
              />
          </Box>
        </FormControl>
        <FormControl>

  <Button
    border="1px"
    borderColor="green.400"
    bg="black"
    color="green.400"
    _hover={{ bg: 'blue.800' }}
    type="submit"
    fontFamily="Orbitron, sans-serif"
    left={adjustedCenter}
    mt='550'
  >
    Submit
  </Button>
  </FormControl>
  <FormControl>
  <Button
    border="1px"
    borderColor="red.500"
    bg="black"
    color="red.500"
    _hover={{ bg: 'red.800' }}
    fontFamily="Orbitron, sans-serif"
    onClick={() => navigate(`/${user.username}`)}
    left={adjustedCenter}
  >
    Cancel
  </Button>
</FormControl>

      </form>
    </ChakraProvider>
  )}




  <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalHeader fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)" 
                textAlign="center" bg="black">Stats</ModalHeader>
        <ModalContent bg='black'>
        <ModalHeader fontFamily="Orbitron" textAlign="center" color="yellow.500"> Apply for Recognition</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text fontFamily="Orbitron">Are you a professional who wants to have their profile and posts marked 
              as certified? Apply for a meeting and have your credentials to get validated. 
              Please pick a day Monday through Friday, we will work around your schedule and get back to you.
            </Text>

<Text fontFamily="Orbitron" mt={4} textAlign="center" color="yellow.500">Reason for applying and date</Text>
            <Textarea value={reason} fontFamily="Orbitron" onChange={(e) => setReason(e.target.value)} ></Textarea>
            <FormControl>
             
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button color="yellow.500" size={'sm'} mr={3} fontFamily="Orbitron" onClick={handleReport}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
)
}