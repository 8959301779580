/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/

import { Box, Flex, GridItem, Spinner, Text, VStack } from '@chakra-ui/react'
import { FaGlobeAmericas } from 'react-icons/fa'
import { useRecoilState } from 'recoil'
import postsAtom from '../atoms/postsAtom'
import { useEffect, useState, useRef, useCallback } from 'react';
import useShowToast from '../hooks/useShowToast'
import SidebarSuggested from '../components/Suggested/SidebarSuggested';
import Post from '../components/Post';
import { Grid } from '@chakra-ui/react';
import Masonry from 'react-masonry-css'
import { useMediaQuery } from "@chakra-ui/react";


const GlobalPage = () => {

  const [posts, setPosts] = useRecoilState(postsAtom);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const showToast = useShowToast();
  const observer = useRef();
  const lastPostElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const leftColumnPosts = posts.filter((_, index) => index % 2 === 0);
const rightColumnPosts = posts.filter((_, index) => index % 2 !== 0);

const firstColumnPosts = posts.filter((_, index) => index % 3 === 0);
const secondColumnPosts = posts.filter((_, index) => index % 3 === 1);
const thirdColumnPosts = posts.filter((_, index) => index % 3 === 2);

const [isLessThan375] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
const [isIphone] = useMediaQuery([
  "(min-device-width: 375px) and (max-device-width: 430px) and (min-device-height: 812px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3)"
]);
const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");
const iphoneMediaQuery = `
  @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 430px) 
    and (min-device-height: 812px) 
    and (max-device-height: 852px) {
    /* Styles for iPhone 12, 13, 14, 15 and their Pro/Plus/Max versions */
  }

  @media only screen and (min-width: 1025px) {
    /* Styles for larger screens like laptops */
  }
`;

const getFeedPosts = async (page) => {
  setLoading(true);
  try {
    const res = await fetch(`/api/posts/global?limit=10&page=${page}`); 
    const data = await res.json();
    if (data.length < 10) setHasMore(false); 
    setPosts(prevPosts => [...prevPosts, ...data]);
  } catch (error) {
    showToast("Error", error.message, "error");
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  getFeedPosts(page);
}, [page]);


return (
  <Flex marginLeft='-70px'>
          {loading ? (
        <Spinner size="xl" ml='225px'/>
      ) : (
       <>
    {isLessThan375 || isIphone ? (
      <VStack spacing={4} width="100%">
        {posts.map((post, index) => (
          <Box
            ref={posts.length === index + 1 ? lastPostElementRef : null}
            key={index}
            bg={index % 2 === 0 ? 'gray.800' : 'black'}
            borderRadius="lg"
          >
            <Post post={post} postedBy={post.postedBy} key={post._id} />
          </Box>
        ))}
      </VStack>
    ) : isIpadEsq ? (
      <>
        <VStack spacing={4} width="20vw" ml='-5'>
          {firstColumnPosts.map((post, index) => (
            <Box
              key={index}
              bg={index % 2 === 0 ? 'gray.800' : 'black'}
              borderRadius="lg"
            >
              <Post post={post} postedBy={post.postedBy} key={post._id} style={{ fontSize: '0.6rem' }} />
            </Box>
          ))}
        </VStack>

        <VStack spacing={4} width="20vw" ml="170">
          {secondColumnPosts.map((post, index) => (
            <Box
              key={index}
              bg={index % 2 === 0 ? 'gray.800' : 'black'}
              borderRadius="lg"
            >
              <Post post={post} postedBy={post.postedBy} key={post._id} style={{ fontSize: '0.6rem' }} />
            </Box>
          ))}
        </VStack>
      </>
    ) : isMac ? (
      <>
        <VStack spacing={4} width="20%" marginRight={30} marginLeft={-1}>
          {firstColumnPosts.map((post, index) => (
            <Box
              key={index}
              bg={index % 2 === 0 ? 'gray.800' : 'black'}
              borderRadius="lg"
            >
              <Post post={post} postedBy={post.postedBy} key={post._id} style={{ fontSize: '0.8rem' }} />
            </Box>
          ))}
        </VStack>

        <VStack spacing={4} width="20%" marginRight={62} marginLeft={40}>
          {secondColumnPosts.map((post, index) => (
            <Box
              key={index}
              bg={index % 2 === 0 ? 'gray.800' : 'black'}
              borderRadius="lg"
            >
              <Post post={post} postedBy={post.postedBy} key={post._id} style={{ fontSize: '0.8rem' }} />
            </Box>
          ))}
        </VStack>

        <VStack spacing={4} width="20%" marginLeft={120}>
          {thirdColumnPosts.map((post, index) => (
            <Box
              ref={thirdColumnPosts.length === index + 1 ? lastPostElementRef : null}
              key={index}
              bg={index % 2 === 0 ? 'gray.800' : 'black'}
              borderRadius="lg"
            >
              <Post post={post} postedBy={post.postedBy} key={post._id} style={{ fontSize: '0.8rem' }} />
            </Box>
          ))}
        </VStack>
      </>
    ) : (
      <>
        <VStack spacing={4} width="20%" marginRight={30} marginLeft={-1}>
          {firstColumnPosts.map((post, index) => (
            <Box
              key={index}
              bg={index % 2 === 0 ? 'gray.800' : 'black'}
              borderRadius="lg"
            >
              <Post post={post} postedBy={post.postedBy} key={post._id} style={{ fontSize: '0.8rem' }} />
            </Box>
          ))}
        </VStack>

        <VStack spacing={4} width="20%" marginRight={62} marginLeft={40}>
          {secondColumnPosts.map((post, index) => (
            <Box
              key={index}
              bg={index % 2 === 0 ? 'gray.800' : 'black'}
              borderRadius="lg"
            >
              <Post post={post} postedBy={post.postedBy} key={post._id} style={{ fontSize: '0.8rem' }} />
            </Box>
          ))}
        </VStack>

        <VStack spacing={4} width="20%" marginLeft={120}>
          {thirdColumnPosts.map((post, index) => (
            <Box
              ref={thirdColumnPosts.length === index + 1 ? lastPostElementRef : null}
              key={index}
              bg={index % 2 === 0 ? 'gray.800' : 'black'}
              borderRadius="lg"
            >
              <Post post={post} postedBy={post.postedBy} key={post._id} style={{ fontSize: '0.8rem' }} />
            </Box>
          ))}
        </VStack>
        
      </>
    )}
    </>
    )}

  </Flex>
);
}
export default GlobalPage;