/* Gegrüßet seist du, Maria, voll der Gnade der Herr ist mit dir. Du bist gebenedeit unter den Frauen,
und gebenedeit ist die Frucht deines Leibes, Jesus Heilige Maria, Mutter Gottes bitte für uns Sünder
jetzt und in der Stunde unseres Todes Amen
*/

import React from 'react'
import { Box, Button, Flex, Input, Text, Skeleton, SkeletonCircle, useColorModeValue } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import Conversation from '../components/Conversation'
import { PiChatsThin } from "react-icons/pi";
import MessageContainer from '../components/MessageContainer'
import { useEffect, useState, useRef } from 'react'
import  useShowToast  from '../hooks/useShowToast'
import { useRecoilState, useRecoilValue } from 'recoil'
import { conversationsAtom, selectedConversationAtom } from '../atoms/messagesAtom'
import userAtom from '../atoms/userAtom'
import { useSocket } from '../context/SocketContext'
import { css } from '@emotion/react';
import { useMediaQuery } from '@chakra-ui/react';

const ChatPage = () => {
    const showToast = useShowToast()
    const [loadingConversations, setLoadingConversations] = useState(true);
    const [conversations, setConversations] = useRecoilState(conversationsAtom);
    const [selectedConversation, setSelectedConversation] = useRecoilState(selectedConversationAtom);
    const [searchText, setSearchText] = useState('');
    const [searchingUser, setSearchingUser] = useState(false);
    const currentUser = useRecoilValue(userAtom);
    const {socket, onlineUsers} = useSocket()
    const [ipadPro] = useMediaQuery('(min-width: 1024px) and (min-height: 1366px)');


const hideScrollBar = css`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

    useEffect(() => {
        socket?.on('messagesSeen',({conversionId}) => {
            setConversations(prev => {
                const updatedConversations = prev.map(conversation => {
                    if(conversation._id === conversionId){
                        return {...conversation, lastMessage: {...conversation.lastMessage, seen: true}}
                    }
                    return conversation
                })
                return updatedConversations
            })
        })
    },[socket, setConversations])

            useEffect(() => {
                const getConversations = async () => {
                    try {
                        if(selectedConversation.mock) return;
                        const res = await fetch('/api/messages/conversations');
                        const data = await res.json();
                        if (data.error) {
                            showToast("Error", data.error, "error");
                            return;
                        }
                        console.log(data);
                        setConversations(data);
                    } catch (error) {
                        showToast("Error", error.message, "error");
                    } finally {
                        setLoadingConversations(false);
                    }
                };
        
                getConversations();
            }, [showToast, setConversations]);

    const handleConversationSearch = async (e) => {
        e.preventDefault();
        setSearchingUser(true);
        try {
            const res = await fetch(`/api/users/profile/${searchText}`);
            const searchedUser = await res.json();
            if (searchedUser.error) {
                showToast("Error", searchedUser.error, "error");
                return;
            }
            const messagingYourself = searchedUser._id === currentUser._id;
            if(messagingYourself){
                showToast("Error", "You cannot message yourself ", "error");
                return;
            }
            const conversationAlreadyExists = conversations.find(conversation => conversation.participants[0]._id === searchedUser._id);
            if(conversationAlreadyExists){
                setSelectedConversation({
                    _id: conversations.find(conversation => conversation.participants[0]._id === searchedUser._id)._id,
                    userId: searchedUser._id,
                    username: searchedUser.username,
                    userProfilePic: searchedUser.profilePic,
                })
                return
            }
            const mockConversation = {
                mock:true,
                lastMessage: {
                    text:'',
                    sender:'',
                },
                _id:Date.now(),
                participants:[{
                    _id: searchedUser._id,
                    username: searchedUser.username,
                    profilePic: searchedUser.profilePic,
                },],
            }
            setConversations((prevConvs) => [...prevConvs, mockConversation]);
        } catch (error) {
            showToast("Error", error.message, "error");
        } finally { 
            setSearchingUser(false);
        }
    }

  return (
    <Box position={'absolute'} left={'50%'} width={{lg:'750px', md:'80%', base:'100%'}} p={4}
     transform={'translateX(-50%)'}> 
    
    <Flex flex={30} gap={2} flexDirection={'column'} maxW={{sm:'250px', md:'full'}} mx={'auto'}>
            
        <Flex  justifyContent={'center'}
        alignItems={'center'}> 
            <form onSubmit={handleConversationSearch}>
                <Flex alignItems={'center'} gap={2}>
                <Input placeholder='Search for a user' onChange={(e) => setSearchText(e.target.value)}
                    fontFamily={'Orbitron, sans-serif'} />
                    <Button size={'sm'} onClick={handleConversationSearch} isLoading={searchingUser}>
                        <SearchIcon />
                    </Button>
                </Flex>
            </form>
        </Flex>
    
{/* The Users*/}
        <Box width="95%" overflowX="auto" css={hideScrollBar} ml={7}>
    <Flex flexDirection="row" flexWrap="nowrap">
        {loadingConversations && 
            [0,1,2,3].map((_,i) => (
                <Flex key={i} gap={4} alignItems={'center'} p={'1'} borderRadius={'md'}>
                    <Box>
                        <SkeletonCircle size={'10'}/>
                    </Box>
                    <Flex w={'full'} flexDirection={'column'} gap={3}>
                        <Skeleton h={'10px'} w={'80px'} />
                        <Skeleton h={'8px'} w={'90%'} />
                    </Flex>
                </Flex>
            ))}

        {!loadingConversations && 
            (conversations.map((conversation) => (
                <Conversation key={conversation._id} 
                isOnline={onlineUsers.includes(conversation.participants[0]._id)} conversation={conversation}/> )))}
    </Flex>
</Box>

{/* The Convo*/}
<Box style={{ position: 'absolute', top: ipadPro ? '110%' : '100%', left: '10%', 
width: '90%', height: ipadPro ? '100%' : '40%' }} 
                justifyContent={'center'}
                alignItems={'center'}>
                {!selectedConversation._id && (
                    <div>
                        <PiChatsThin size={100} color="#FF6EC7" />
                        <Text fontFamily={'Orbitron, sans-serif'} fontSize={20}></Text>
                    </div>
                )}

                {selectedConversation._id && <MessageContainer />}
                
</Box>


    </Flex> 

    </Box>

  )
}

export default ChatPage

/* import React from 'react'
import { Box, Button, Flex, Input, Text, Skeleton, SkeletonCircle, useColorModeValue } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import Conversation from '../components/Conversation'
import { GiConversation } from 'react-icons/gi'
import MessageContainer from '../components/MessageContainer'
import { useEffect, useState, useRef } from 'react'
import  useShowToast  from '../hooks/useShowToast'
import { useRecoilState, useRecoilValue } from 'recoil'
import { conversationsAtom, selectedConversationAtom } from '../atoms/messagesAtom'
import userAtom from '../atoms/userAtom'
import { useSocket } from '../context/SocketContext'
import { css } from '@emotion/react';

const ChatPage = () => {
    const showToast = useShowToast()
    const [loadingConversations, setLoadingConversations] = useState(true);
    const [conversations, setConversations] = useRecoilState(conversationsAtom);
    const [selectedConversation, setSelectedConversation] = useRecoilState(selectedConversationAtom);
    const [searchText, setSearchText] = useState('');
    const [searchingUser, setSearchingUser] = useState(false);
    const currentUser = useRecoilValue(userAtom);
    const {socket, onlineUsers} = useSocket()
  

const hideScrollBar = css`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

    useEffect(() => {
        socket?.on('messagesSeen',({conversionId}) => {
            setConversations(prev => {
                const updatedConversations = prev.map(conversation => {
                    if(conversation._id === conversionId){
                        return {...conversation, lastMessage: {...conversation.lastMessage, seen: true}}
                    }
                    return conversation
                })
                return updatedConversations
            })
        })
    },[socket, setConversations])

            useEffect(() => {
                const getConversations = async () => {
                    try {
                        if(selectedConversation.mock) return;
                        const res = await fetch('/api/messages/conversations');
                        const data = await res.json();
                        if (data.error) {
                            showToast("Error", data.error, "error");
                            return;
                        }
                        console.log(data);
                        setConversations(data);
                    } catch (error) {
                        showToast("Error", error.message, "error");
                    } finally {
                        setLoadingConversations(false);
                    }
                };
        
                getConversations();
            }, [showToast, setConversations]);

    const handleConversationSearch = async (e) => {
        e.preventDefault();
        setSearchingUser(true);
        try {
            const res = await fetch(`/api/users/profile/${searchText}`);
            const searchedUser = await res.json();
            if (searchedUser.error) {
                showToast("Error", searchedUser.error, "error");
                return;
            }
            const messagingYourself = searchedUser._id === currentUser._id;
            if(messagingYourself){
                showToast("Error", "You cannot message yourself", "error");
                return;
            }
            const conversationAlreadyExists = conversations.find(conversation => conversation.participants[0]._id === searchedUser._id);
            if(conversationAlreadyExists){
                setSelectedConversation({
                    _id: conversations.find(conversation => conversation.participants[0]._id === searchedUser._id)._id,
                    userId: searchedUser._id,
                    username: searchedUser.username,
                    userProfilePic: searchedUser.profilePic,
                })
                return
            }
            const mockConversation = {
                mock:true,
                lastMessage: {
                    text:'',
                    sender:'',
                },
                _id:Date.now(),
                participants:[{
                    _id: searchedUser._id,
                    username: searchedUser.username,
                    profilePic: searchedUser.profilePic,
                },],
            }
            setConversations((prevConvs) => [...prevConvs, mockConversation]);
        } catch (error) {
            showToast("Error", error.message, "error");
        } finally { 
            setSearchingUser(false);
        }
    }

  return (
    <Box position={'absolute'} left={'50%'} width={{lg:'750px', md:'80%', base:'100%'}} p={4}
     transform={'translateX(-50%)'}> 
    
    <Flex flex={30} gap={2} flexDirection={'column'} maxW={{sm:'250px', md:'full'}} mx={'auto'}>
            
        <Flex  justifyContent={'center'}
        alignItems={'center'}> 
            <form onSubmit={handleConversationSearch}>
                <Flex alignItems={'center'} gap={2}>
                    <Input placeholder='Search for a user' onChange={(e) => setSearchText(e.target.value)} />
                    <Button size={'sm'} onClick={handleConversationSearch} isLoading={searchingUser}>
                        <SearchIcon />
                    </Button>
                </Flex>
            </form>
        </Flex>
    

        <Box width="95%" overflowX="auto" css={hideScrollBar} ml={7}>
    <Flex flexDirection="row" flexWrap="nowrap">
        {loadingConversations && 
            [0,1,2,3].map((_,i) => (
                <Flex key={i} gap={4} alignItems={'center'} p={'1'} borderRadius={'md'}>
                    <Box>
                        <SkeletonCircle size={'10'}/>
                    </Box>
                    <Flex w={'full'} flexDirection={'column'} gap={3}>
                        <Skeleton h={'10px'} w={'80px'} />
                        <Skeleton h={'8px'} w={'90%'} />
                    </Flex>
                </Flex>
            ))}

        {!loadingConversations && 
            (conversations.map((conversation) => (
                <Conversation key={conversation._id} 
                isOnline={onlineUsers.includes(conversation.participants[0]._id)} conversation={conversation}/> )))}
    </Flex>
</Box>


        {!selectedConversation._id && (
    <Flex 
        style={{ position: 'absolute', top: '150%', left: '50%', transform: 'translate(-50%, -50%)' }}
        justifyContent={'center'}
        alignItems={'center'}
    >
        <GiConversation size={100} />
        <Text fontSize={20}>Select a conversation to start messaging</Text>
    </Flex>
)}

<Flex 
        style={{ position: 'absolute', top: '275%', left: '50%', transform: 'translate(-50%, -50%)' }}
        justifyContent={'center'}
        alignItems={'center'}
        width={'75%'}
    >
        {selectedConversation._id && <MessageContainer style={{ marginTop: '20px', marginLeft: '20px' }} />}
        </Flex>



    </Flex> 

    </Box>

  )
}

export default ChatPage

 */