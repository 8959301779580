/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/


import { AddIcon } from '@chakra-ui/icons'
import { Box, Button, CloseButton, Flex, FormControl, Image, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,
     ModalOverlay, Text, Textarea, useColorModeValue, useDisclosure, 
     useMediaQuery} from '@chakra-ui/react'
import React, { useRef, useState, useEffect } from 'react'
import usePreviewMedia from '../hooks/usePreviewImg'
import { BsFillImageFill, BsReverseLayoutSidebarInsetReverse } from 'react-icons/bs'
import { useRecoilState, useRecoilValue } from 'recoil'
import userAtom from '../atoms/userAtom'
import useShowToast from '../hooks/useShowToast'
import postsAtom from '../atoms/postsAtom'
import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'
import { LiaCameraRetroSolid } from "react-icons/lia";
import Autosuggest from 'react-autosuggest';


const MAX_CHAR = 500;

const CreateQueenBee = ({tag}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [postText, setPostText] = useState('') 
    const {handleMediaChange, mediaUrl, setMediaUrl } = usePreviewMedia()
    const imageRef= useRef(null)
    const [remainingChar, setRemainingChar] = useState(MAX_CHAR)
    const user = useRecoilValue(userAtom)
    const showToast = useShowToast()
    const [loading, setLoading] = useState(false)
    const [posts, setPosts] = useRecoilState(postsAtom)
    const {username} = useParams()
    const [mediaType, setMediaType] = useState('')
    const [tags, setTags] = useState([])
    const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
    const [suggestions, setSuggestions] = useState([{ name: tag }]);
    const [originalSuggestions, setOriginalSuggestions] = useState([{ name: tag }]);
    const [value, setValue] = useState(tag);

    const handleTextChange = (e) => {
      const inputText = e.target.value;

      if(inputText.length > MAX_CHAR){
        const truncatedText = inputText.slice(0, MAX_CHAR);
        setPostText(truncatedText);
        setRemainingChar(0);
      } else {
        setPostText(inputText);
        setRemainingChar(MAX_CHAR - inputText.length);
      }
    }

    useEffect(() => {
      if (mediaUrl) {
        fetch(mediaUrl, { method: 'HEAD' })
          .then(response => setMediaType(response.headers.get('Content-Type')))
      }
    }, [mediaUrl])

    const handleCreatePost = async() => {
        setLoading(true)
        try {
          console.log(tag)
          const res = await fetch('/api/posts/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({postedBy:user._id, text: postText, img: mediaUrl, tags: [tag]}),
          })        
        const data = await res.json();
        if(data.error){
          showToast("Error", data.error, "error")
          return
        }
        setMediaUrl(data.img); 
        if(username === user.username){
          setPosts([data, ...posts])
        }
        onClose()
        setPostText('')
        setMediaUrl('')
      } catch (error) {
              } finally{
        setLoading(false)
        window.location.reload();
      }
    }


    const getSuggestions = value => {
      const tags = value.split(',');
      const lastTag = tags[tags.length - 1].trim();
      const inputValue = lastTag.toLowerCase();
      const inputLength = inputValue.length;
      return inputLength === 0 ? [] : originalSuggestions.filter(suggestion =>
        suggestion.name.toLowerCase().includes(inputValue)
      );
    };


    const getSuggestionValue = suggestion => {
      const tagsArray = value.split(',');
      tagsArray[tagsArray.length - 1] = suggestion.name;
      setTags(tagsArray);
      return `${tagsArray.join(', ')}, `;
    };
const renderSuggestion = suggestion => (
  <div>
    {suggestion.name}
  </div>
);

const inputProps = {
    placeholder: 'Tags',
    value,
    onChange: (event, { newValue }) => {
      if (!newValue.includes(tag)) {
        return;
      }
      setValue(newValue);
    },
    style: {
      fontFamily: 'Orbitron, sans-serif',
      backgroundColor: 'black',
      color: 'white', 
      border: '1px solid white',
      borderRadius: '5px', 
      width: '400px', 
      height: '40px'
    }
  };


const onSuggestionsFetchRequested = ({ value }) => {
  console.log('onSuggestionsFetchRequested called with value:', value);
  setSuggestions(getSuggestions(value));
};
const onSuggestionsClearRequested = () => {
  setSuggestions([]);
};

const renderInputComponent = inputProps => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ fontSize: '24px', marginRight: '10px' }}>#</span>
    <input {...inputProps} />
  </div>
);

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    handleCreatePost();
  }
}

 return <>
  <Button position={'fixed'} bottom={10} right={isSmallScreen ? '50' : '5'}
  bg='gray.700'
  onClick={onOpen} size={{ base: 'sm', sm:'md', md: 'lg' }} 
  border="2px" borderColor="yellow.400" ><AddIcon color="yellow.400" /></Button>

<Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />

        <ModalContent backgroundColor="black" border="1px" borderColor="yellow.400">
          <ModalHeader fontFamily={'Orbitron, sans-serif'} textAlign="center" color="yellow.400">Create Post</ModalHeader>
          <ModalBody pb={6}>
             <FormControl>
    <Textarea
      placeholder='Post content goes here'
      fontFamily={'Orbitron, sans-serif'} 
      color="yellow.400"
      backgroundColor='black'
      onChange={handleTextChange}
      onKeyDown={handleKeyDown} // Use onKeyDown here
      value={postText}
    />
<Box marginTop="4">

<Autosuggest
  suggestions={suggestions}
  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
  onSuggestionsClearRequested={onSuggestionsClearRequested}
  getSuggestionValue={getSuggestionValue}
  renderSuggestion={renderSuggestion}
  inputProps={inputProps}
  renderInputComponent={renderInputComponent}
/>
</ Box>

            <Text fontSize="xs"
            fontWeight="bold"
            textAlign={'right'}
            m={"1"}
            color={"gray.800"}>
                {remainingChar}/{MAX_CHAR}
            </Text>
         
            <Input type='file' hidden ref={imageRef} onChange={handleMediaChange} />
<LiaCameraRetroSolid  style={{marginLeft:"5px", cursor:"pointer"}} size={24} color="#FF6EC7"
onClick={() => imageRef.current.click()} />
</FormControl> 

{mediaUrl && (
<Flex mt={5} w={'full'} position={'relative'}>
  {mediaType.startsWith('video/') ? (
    <>
    {console.log('mediaUrl in CreatePost:', mediaUrl)} 
    <ReactPlayer url={mediaUrl} controls={true} width='320px' height='240px' />
  </>
  ) : (
    <Image src={mediaUrl}/>
  )}
  <CloseButton 
    onClick={() => {
      setMediaUrl('')
      setMediaType('')
    }} 
    bg={'gray.800'} 
    position={'absolute'} 
    top={2} 
    right={2}
  />
</Flex>
)}
</ModalBody>

<ModalFooter>

            <Button  mr={3} onClick={handleCreatePost} 
            isLoading={loading} fontFamily={'Orbitron, sans-serif'} color="yellow.400">
              Post
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
</>
}

export default CreateQueenBee
