import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import Post from '../components/Post';
// Define useQuery function
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SearchPage() {
  // Use useQuery to get the query parameter
  let query = useQuery().get('query');
  const [users, setUsers] = useState([]);
  const [posts, setPosts] = useState([]);
  const [fetchingPosts, setFetchingPosts] = useState(false); // Assuming you want to track the fetching state

  useEffect(() => {
    console.log("Received query:", query); // Logs the query each time the effect runs
  
    const fetchResults = async () => {
      const encodedQuery = encodeURIComponent(query);
      setFetchingPosts(true); // Tracks loading status
  
      try {
        // Attempt to fetch users first
        const userResponse = await fetch(`/api/users/profile/${encodedQuery}`);
        
        if (userResponse.ok) {
          const userData = await userResponse.json();
          if (userData.users && userData.users.length > 0) {
            setUsers(userData.users); // Updates state with user results
            console.log("Fetched users successfully.");
          } else {
            // If no users found, then fetch posts
            console.log("No users found, attempting to fetch posts...");
            const postResponse = await fetch(`/api/posts/findpost/${encodedQuery}`);
            if (postResponse.ok) {
              const postData = await postResponse.json();
              console.log("postData.posts:", postData.posts); // Add this line to debug
              setPosts(postData.posts || []); // Updates state with post results
              console.log("Fetched posts successfully.");
            }
          }
        } else {
          // If user fetch fails, then try to fetch posts
          console.log("User fetch failed, attempting to fetch posts...");
          const postResponse = await fetch(`/api/posts/findpost/${encodedQuery}`);
          if (postResponse.ok) {
            const postData = await postResponse.json();
            console.log("API Response for posts:", postData);
            console.log("Setting posts with:", postData.posts);
            setPosts(postData || []);
            console.log("Fetched posts successfully.");
          }
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setFetchingPosts(false); // Updates loading status
      }
    };
  
    if (query) {
      fetchResults();
    }
  }, [query]);

  console.log("Rendering posts:", posts);
  return (
    <>
      {fetchingPosts && (
        <Flex justify={'center'} my={12}>
          <Spinner size={"xl"} />
        </Flex>
      )}
      {!fetchingPosts && posts.length > 0 && posts.map((post) => (
        <Post key={post._id} post={post} postedBy={post.postedBy} />
      ))}
      {!fetchingPosts && posts.length === 0 && (
        <Flex height="1vh" ml={225} mt={10}>
          <Text color={'yellow.400'} fontFamily={'Orbitron, sans-serif'} fontSize="xl">
            No Posts Made
          </Text>
        </Flex>
      )}
    </>
  );
}

export default SearchPage;