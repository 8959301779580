/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/

import { Avatar, Image, useMediaQuery } from "@chakra-ui/react";
import { Box, Center, Flex, Link, Text, VStack } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Portal } from "@chakra-ui/portal";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, 
    ModalFooter, Spacer, useDisclosure, useToast } from "@chakra-ui/react";
import { BsInstagram } from "react-icons/bs";
import { CgMoreO } from "react-icons/cg";
import { useRecoilValue } from "recoil";
import userAtom from "../atoms/userAtom";
import { Link as RouterLink } from "react-router-dom";
import useFollowUnfollow from "../hooks/useFollowUnfollow";
import useShowToast from "../hooks/useShowToast";
import { Progress } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoPeopleOutline } from "react-icons/io5";
import { HiAcademicCap } from "react-icons/hi2";
import { MdAddBox } from "react-icons/md";
import BioSidebar from "./BioSidebar";

const UserHeader = ({ user }) => {
    const toast = useToast();
    const currentUser = useRecoilValue(userAtom); 
    const showToast = useShowToast();
    const { handleFollowUnfollow, following, updating } = useFollowUnfollow(user);
    const [progress, setProgress] = useState(user.followers);
    const [showFollows, setShowFollows] = useState(false);
    const [follows, setFollows] = useState([]);
    const [followingUsers, setFollowingUsers] = useState([]);
    const { isOpen: isFollowersOpen, onOpen: onFollowersOpen, onClose: onFollowersClose } = useDisclosure();
const { isOpen: isFollowingOpen, onOpen: onFollowingOpen, onClose: onFollowingClose } = useDisclosure();
const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
const { isOpen, onOpen, onClose } = useDisclosure();
const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
const [ipadPro] = useMediaQuery('(min-width: 1024px) and (min-height: 1366px)');
const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");
const [isIphone] = useMediaQuery([
  "(min-device-width: 375px) and (max-device-width: 430px) and (min-device-height: 812px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3)"
]);
const { isOpen: isBioOpen, onOpen: onBioOpen, onClose: onBioClose } = useDisclosure();

    const fetchUserFollowing = async (id) => {
        try {
            const response = await fetch(`/api/users/${id}/following`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(`Failed to fetch following list for user with ID ${id}: ${error}`);
            return null;
        }
    };
    
    const fetchUserFollowers = async (id) => {
        try {
            const response = await fetch(`/api/users/${id}/followers`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(`Failed to fetch followers list for user with ID ${id}: ${error}`);
            return null;
        }
    };

    useEffect(() => {
        const fetchFollowedUsers = async () => {
            const followedUsers = await fetchUserFollowers(user._id);
            console.log('Fetched followers:', followedUsers); 
            setFollows([followedUsers].filter(Boolean)); 
        };
        fetchFollowedUsers();
    }, [user._id]);
    
    useEffect(() => {
        const fetchFollowingUsers = async () => {
            const following = await fetchUserFollowing(user._id);
            console.log('Fetched following users:', following); 
            setFollowingUsers([following].filter(Boolean)); 
        };
    
        fetchFollowingUsers();
    }, [user._id]);

    const copyURL = () => {
        const currentURL = window.location.href;
        navigator.clipboard.writeText(currentURL).then(() => {
            toast({
                title: "Success.",
                status: "success",
                description: "Profile link copied.",
                duration: 3000,
                isClosable: true,
            });
        });
    };

    console.log('User in UserHeader:', user);


	return (
    <VStack
      gap={4}
      alignItems="flex-start"
      border="1px"
      borderColor="yellow.400"
      borderRadius="lg"
      width={{ base: 'full', md: '130%', lg: ipadPro ? '130%' : 'full' }} // Wider for iPad Pro
      mr={{ md: '500' }}
      ml={ipadPro ? '-180' : isIpadEsq ? '-170px' : '0'}
    >
            <Flex direction="row" alignItems="center" width="100%">
        <Flex direction="column" alignItems="center">
          <Box
            border="1.5px solid"
            borderColor="yellow.400"
            borderRadius="full"
            mt={isMac ? 10 : isIpadEsq ? 19 : isSmallScreen ? -49 : 3}
            ml={isIpadEsq ? '5' : '3'}
            mb={isIpadEsq ? '20' : '10'}
          >
                        {user.profilePic ? (
              <Avatar
                name={user.name}
                src={user.profilePic}
                size={{
                  base: "xl",
                  md: "2xl",
                }}
              />
            ) : (
              <Avatar
                name={user.name}
                src='https://bit.ly/broken-link'
                size={{
                  base: "xl",
                  md: "2xl",
                }}
              />
            )}
          </Box>
          {currentUser?._id === user._id && (
            <Link as={RouterLink} to='/update' ml={2} mt={isSmallScreen ? 20 : 4}>
              <Button
                size={"sm"}
                color={"green.400"}
                fontFamily={'Orbitron, sans-serif'}
                borderColor={"green.500"}
                borderWidth={2}
                mt={isMac ? '-150px' : isIpadEsq ? '-150px' : isSmallScreen ? '-200px' : '0'}
              >
                Update Profile
              </Button>
            </Link>
          )}
          {currentUser?._id !== user._id && (
            <div style={{ position: 'relative' }}>
              <div style={{
                position: 'absolute',
                left: isMac ? '-30px' : ipadPro ? '290px' : isIpadEsq ? '290px' : isSmallScreen ? '-28px' : '-30px',
                top: isMac ? '-210px' : ipadPro ? '-95px' : isIpadEsq ? '-95px' : isSmallScreen ? '25px' : '-30px'
              }}>
                <Button
                  size={"sm"}
                  onClick={handleFollowUnfollow}
                  isLoading={updating}
                  fontFamily={'Orbitron, sans-serif'}
                  style={{ transform: isIpadEsq ? "translateX(-10px)" : "none" }}
                >
                  {following ? "Unfollow" : "Follow"}
                </Button>
              </div>
            </div>
          )}
        </Flex>
        <Flex
          direction="column"
          alignItems="flex-start"
          ml={5}
          mb={10}
          transform={isSmallScreen ? "translateY(-65px)" : "none"}
          flexGrow={1}
          minWidth="0"
        >
                    <Flex direction="row" alignItems="center" width="100%">
                      
            <Text
              fontSize={"2xl"}
              fontWeight={"bold"}
              color={"rgb(135, 206, 250)"}
              fontFamily={'Orbitron, sans-serif'}
              mt={isIpadEsq ? '50px' : '0'}
              flexGrow={1}
              whiteSpace="normal"
              overflow="hidden"
              textOverflow="clip"
            >
              {user.username}
            </Text>
 {/* The people button on isSmallScreen */}
    <Flex gap={2} alignItems={"center"} ml={2} direction="row">
                        <Flex 
    direction="column" 
    justifyContent={isSmallScreen ? "flex-start" : "center"}
    alignItems={isSmallScreen ? "flex-start" : "center"}
>
{!isIpadEsq && (
  <Button 
    color={"#FF6EC7"} 
    fontFamily={'Orbitron, sans-serif'} 
    onClick={isSmallScreen ? undefined : onFollowersOpen} 
    mb={5}
    style={isSmallScreen ? {
      marginLeft: '0px', 
      marginTop: '400px',
      border: 'none', 
      background: 'transparent', 
      color: 'transparent', 
    } : {}}
  >    
{(!isSmallScreen || isMac) && !isIphone && (
  <Text 
    color={"#FF6EC7"} 
    fontFamily={'Orbitron, sans-serif'}
    mt={isMac ? '-700' : '0'}
    mr={isMac ? '500' : '0'}
    onClick={onFollowersOpen}
  >
    {user.followers.length} followers
  </Text>
    )}
  </Button>
)}

{/*  People Button for phones*/}
{(isSmallScreen || isIphone) && !isIpadEsq && !ipadPro && !isMac ? (
  <Button 
    onClick={onOpen} 
    Color={isMac ? "pink" : "transparent"}
    style={{
      position: 'absolute', 
      top: isIphone ? '320px' : isMac ? '225px' : isSmallScreen ? '280px' : '260px',
      right: '20px', 
      fontSize: isMac ? '24px' : '16px',
      overflow: 'visible'
    }}
  >
    <IoPeopleOutline color="#FF6EC7" style={{ pointerEvents: 'none' }} />
  </Button>
) : null}
{(isMac || isIpadEsq || (!isSmallScreen && !isIphone)) && (
    <Button
  color={"#FF6EC7"}
  fontFamily={'Orbitron, sans-serif'}
  onClick={onFollowingOpen}
  style={isMac ? { marginTop: '-375px' } : isIpadEsq ? { marginTop: '140px' } : {}}
>
        <Text color={"#FF6EC7"} fontFamily={'Orbitron, sans-serif'}>{user.following.length}  following
        </Text>    
    </Button>
)}
</Flex>



{isIpadEsq && (
    <Button
  color={"#FF6EC7"}
  fontFamily={'Orbitron, sans-serif'}
  onClick={onFollowersOpen}
  mb={5}
  width={"125px"}
  style={{ marginTop: '50px', marginLeft: '-135px' }}
>
  <Text color={"#FF6EC7"} fontFamily={'Orbitron, sans-serif'}>{user.followers.length}  followers</Text>
</Button>
)}



{/* Modal for small Screen */}
<Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)" 
                textAlign="center" bg="black">Stats</ModalHeader>
                <ModalCloseButton />
                <ModalBody bg="black">

                <Flex direction="column">
    <Button color={"#FF6EC7"} fontFamily={'Orbitron, sans-serif'} onClick={onFollowersOpen} mb={5} width={"125px"}>
        <Text color={"#FF6EC7"} fontFamily={'Orbitron, sans-serif'}>{user.followers.length}  followers
        </Text>
    </Button>

    <Box w='1' h='1' bg={"gray.light"} borderRadius={"full"}></Box>
<Link color={"gray.light"}></Link>
<Flex direction="column" mt={-55} ml={140}>
    <Box border="1px" borderColor="yellow.200" borderRadius="md" width="200px" bg="black">
        <Box bg="#FF6EC7" borderRadius="md" height="20px" width={`${(user.followers.length / 1000) * 100}%`} />
    </Box>
</Flex>

    <Button color={"#FF6EC7"} fontFamily={'Orbitron, sans-serif'} onClick={onFollowingOpen} 
    mt={10} width={"125px"}>
        <Text color={"#FF6EC7"} fontFamily={'Orbitron, sans-serif'}>{user.following.length}  following
        </Text>
    </Button>

    </ Flex>
                </ModalBody>
                <ModalFooter bg="black">
                </ModalFooter>
            </ModalContent>
        </Modal>
  

<Modal isOpen={isFollowersOpen} onClose={onFollowersClose}>
    <ModalOverlay />
    <ModalContent>
        <ModalHeader fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)" bg="black">Followers</ModalHeader>
        <ModalCloseButton />
        <ModalBody  bg="black">
        {follows.flat().map((follower, index) => (
    follower && (
        <Flex key={follower._id || index} alignItems="center" mb={2}>
<Link as={RouterLink} to={`/${follower.username}`} onClick={onFollowersClose}>
<Avatar src={follower.profilePic || undefined} name={follower.username} />
</Link>
            <Text ml={2} fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)">{follower.username}</Text>
        </Flex>
    )
))}
</ModalBody>
    </ModalContent>
</Modal>

<Modal isOpen={isFollowingOpen} onClose={onFollowingClose}>
    <ModalOverlay />
    <ModalContent>
        <ModalHeader fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)" bg="black">
            Following</ModalHeader>
        <ModalCloseButton />
        <ModalBody bg="black">
        {followingUsers.flat().map((user, index) => (
    user && (
        <Flex key={user._id || index} alignItems="center" mb={2}>
<Link as={RouterLink} to={`/${user.username}`} onClick={onFollowingClose}>
    <Avatar src={user.profilePic || undefined} name={user.username}/>
</Link>
            <Text ml={2} fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)" >{user.username}</Text>
        </Flex>
    )
))}
        </ModalBody>
    </ModalContent>
</Modal>




  
  {/* Follower Bar  */}
 
  {(isMac || isIpadEsq || (!isSmallScreen && !isIphone)) && (
    <>
      <Box w='1'   h='1' bg={"gray.light"} borderRadius={"full"}></Box>
        <Link color={"gray.light"}></Link>
        <Flex 
  direction="column" 
  mt={-50} 
  style={isMac ? { marginTop: "27px", marginLeft: "-520px" } : isIpadEsq ? { marginTop: "30px", marginLeft: "-30px" } : {}}
>
            <Box border="1px" borderColor="yellow.200" borderRadius="md" width={isIpadEsq ? "175px" : "150px"} bg="black">
                <Box bg="#FF6EC7" borderRadius="md" height="20px" width={`${(user.followers.length / 1000) * 100}%`} />
            </Box>
        </Flex>
    </>
)}


</Flex>           
 
                    </Flex>


      </Flex>
            </Flex>

{/* Bio Button */}

            <Modal isOpen={isBioOpen} onClose={onBioClose}
color="red">
        <ModalOverlay />
        <ModalContent bg="black">
        <ModalHeader>
        <Box fontFamily={'Orbitron, sans-serif'} color={"rgb(135, 206, 250)"} 
  ml={40}>Credentials</Box>
</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color={"rgb(135, 206, 250)"} 
    fontFamily={'Orbitron, sans-serif'}>Full Name</Text>
          <Text fontSize={"sm"} mr={10} 
    fontFamily={'Orbitron, sans-serif'} mt={isIpadEsq ? -10  : 0}>{user.name}</Text>
  <Text color={"rgb(135, 206, 250)"} 
    fontFamily={'Orbitron, sans-serif'}>Bio</Text>
        <Text mr={190} color={"gray.200"} fontFamily={'Orbitron, sans-serif'} mt={isIpadEsq ? 0 : (isSmallScreen ? 0 : 0)}>{user.bio}</Text>

        <Text color={"rgb(135, 206, 250)"} 
    fontFamily={'Orbitron, sans-serif'}>Skills</Text>
     <Text color={"rgb(135, 206, 250)"} 
    fontFamily={'Orbitron, sans-serif'}>{user.skills}</Text><MdAddBox />

<Text color={"rgb(135, 206, 250)"} 
    fontFamily={'Orbitron, sans-serif'}>Previous Projects</Text>
  <Text color={"rgb(135, 206, 250)"} 
    fontFamily={'Orbitron, sans-serif'}>{user.projects}</Text>  <MdAddBox />

<Text color={"rgb(135, 206, 250)"} 
    fontFamily={'Orbitron, sans-serif'}>Education</Text>
   <Text color={"rgb(135, 206, 250)"} 
    fontFamily={'Orbitron, sans-serif'}>{user.education}</Text> <MdAddBox />

<Text color={"rgb(135, 206, 250)"} 
    fontFamily={'Orbitron, sans-serif'}></Text>

<Text color={"rgb(135, 206, 250)"} 
    fontFamily={'Orbitron, sans-serif'}></Text>

          </ModalBody>

          <ModalFooter>
            <Button       fontFamily={'Orbitron, sans-serif'} colorScheme='blue' mr={3} onClick={onBioClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
            
         
            <Flex w={"full"} justifyContent={"space-between"} mb={isSmallScreen ? -75 : 2} ml={125}>
    <Flex>
        <Box className='icon-container'>
            <Menu>
            </Menu>
        </Box>
    </Flex>
</Flex>



        </VStack>

    );
};

export default UserHeader;
