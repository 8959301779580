/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/


import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { Input } from '@chakra-ui/input';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [notFound, setNotFound] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const tagsList = [
        "3DPrinting", "AerospaceEngineering", "AgriculturalScience", "Algebra", "Anatomy", "AnimalBehavior", 
        "Anthropology", "Archaeology", "Architecture", "ArtificialIntelligence", "Astronomy", "Astrophysics", 
      "ATVs",  "BehavioralEconomics","Bicycles", "Biochemistry", "Bioengineering", "Bioinformatics", "Biology", "Biomechanics", 
        "BiomedicalEngineering", "Biophysics", "Biotechnology", "Botany", "Calculus", 
        "Cardiology", "Cars",
        "CellBiology", 
        "ChemicalEngineering", "Chemistry", "CivilEngineering", "ClimateScience", "Clinical Psychology", "Coding", 
            "Cognitive Psychology ","Computers",
        "ComputationalBiology", 
        "ComputerEngineering", "ComputerScience", "Cooking", "Cryptography", "Cybersecurity", "DataAnalysis", "DataMining",
        "DataScience", "DatabaseManagement", "DigitalDesign", "Dentistry", "Dermatology", "Developmental psychology", "EarthScience", "Ecology",
        "Economics",
        "Educational Psychology", "Electrician", "ElectricalEngineering", 
        "Electronics", "Elements", "Endocrinology", "Energy", "Engineering", "Entrepeneur", "EnvironmentalScience", "EvolutionaryBiology", "FluidDynamics",
        "Forensic Psychology", 
        "ForensicScience", "GameDevelopment", "Gastroenterology", "GeneticEngineering", "Genetics", "Geology",
        "Geometry", "Geophysics", "Health Psychology", "Helicopters ", "Hematology",
        "HumanBiology", "Immunology", "IndustrialEngineering", "Industrial-organizational Psychology", "InformationTechnology", "InorganicChemistry", 
        "MachineLearning", "MarineBiology", "MaterialsScience", "Math", "MechanicalEngineering", 
        "Medicine", "Meteorology", "Microbiology", "MolecularBiology", "Motorcycles ", "Nanotechnology", "NetworkSecurity", 
       "Neuropsychology", "Neuroscience", "Neurosurgery ", "NuclearEngineering", "NuclearPhysics", "Oceanography", 
       "Oncology ",
       "Optics", "OrganicChemistry", 
        "OrganicFarming", "Orthopedics", "Paleontology", "ParticlePhysics", "Pathology",
        "Pediatrics",
        "PetroleumEngineering", 
        "Pharmacogenomics", "Pharmacology", "Philosophy", "PhysicalChemistry", "physics", "Physiology", 
        "Piezoelectricity", "Planes", "Plasma", "Plumbing","Psychiatry", "Psychology", "Pulmonology", "QuantumComputing", 
        "QuantumMechanics", "QuantumPhysics", "Radiology", "RenewableEnergy", 'Rheumatology', "Robotics",
        "Social Psychology ", "SoftwareEngineering", 
        "SpaceExploration", "Sports psychology ", "Statistics", "StructuralEngineering", "SystemsBiology", "Thermodynamics", 
        "Test", "Trades", "Virology", "Waves", "WebDevelopment", "WildlifeConservation", "Zoology"
    ];
    const [filteredTags, setFilteredTags] = useState([]);

const parentStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
};

const suggestionsStyle = {
    position: 'absolute',
    top: '100%',
    left: '0',
    zIndex: 2000,
    backgroundColor: 'black',
    border: '1px solid yellow',
    borderRadius: '4px',
    width: '270px',
    fontFamily: "Orbitron, sans-serif"
};
    

    useEffect(() => {
        if (searchTerm.trim() === '') {
            setFilteredTags([]);
        } else {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();
            const exactMatch = tagsList.some(tag => tag.toLowerCase() === lowerCaseSearchTerm);
            if (exactMatch) {
                setFilteredTags([]);
            } else {
                const filtered = tagsList.filter(tag =>
                    tag.toLowerCase().includes(lowerCaseSearchTerm)
                ).slice(0, 10); 
                setFilteredTags(filtered);
            }
        }
    }, [searchTerm, tagsList]);

    const handleSuggestionClick = (tag) => {
        setSearchTerm(tag); 
        setFilteredTags([]); 
    };
    
    const handleSearch = async (event) => {
        event.preventDefault();
        setNotFound(false);
        setLoading(true);

        const query = searchTerm.trim();
        if (!query) {
          console.error('Query is empty');
          setLoading(false);
          return;
        }

        const encodedQuery = encodeURIComponent(query);
        const tagExists = tagsList.some(tag => encodeURIComponent(tag) === encodedQuery);

        if (tagExists) {
          console.log(`Navigating to: /tags/${encodedQuery}`);
          navigate(`/tags/${encodedQuery}`);
        } else {
          console.log('Tag not found');
          setNotFound(true);
        }

        setLoading(false);
    };

    return (
        <form onSubmit={handleSearch} style={{ display: 'flex', alignItems: 'center' }}>
        <div style={parentStyle}> 
            <Input 
                className="no-focus-border"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search Subjects"
                fontFamily="Orbitron, sans-serif"
                style={{
                    color: 'gray.200',
                    borderColor: 'gray.200',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                }}
            />
            <Button 
                type='submit' 
                size='sm' 
                isLoading={loading}
                _hover={{ borderColor: 'yellow.400', borderWidth: '1px' }}
            >
                <FaRegArrowAltCircleRight />
            </Button>
            {filteredTags.length > 0 && (
                <div style={suggestionsStyle}>
                    {filteredTags.map((tag, index) => (
                        <div key={index} style={{ padding: '5px' }} onClick={() => handleSuggestionClick(tag)}>
                            {tag}
                        </div>
                    ))}
                </div>
            )}
        </div>
    </form>
    );
};


export default SearchBar;