/* Ave Maria gratia plena, Dominus tecum, benedicta tu in mulieribus et benedictus fructus 
ventris tui Iesu, Santa Maria, mater Dei, ora pro nobis peccatoribus, nunc et in ora mortis nostrae */

import { AddIcon } from '@chakra-ui/icons'
import { Box, Button, CloseButton, Flex, FormControl, Image, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,
     ModalOverlay, Spinner, Text, Textarea, useColorModeValue, useDisclosure, 
     useMediaQuery} from '@chakra-ui/react'
import React, { useRef, useState, useEffect } from 'react'
import usePreviewMedia from '../hooks/usePreviewImg'
import { BsFillImageFill, BsReverseLayoutSidebarInsetReverse } from 'react-icons/bs'
import { useRecoilState, useRecoilValue } from 'recoil'
import userAtom from '../atoms/userAtom'
import useShowToast from '../hooks/useShowToast'
import postsAtom from '../atoms/postsAtom'
import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player'
import { LiaCameraRetroSolid } from "react-icons/lia";
import Autosuggest from 'react-autosuggest';

const MAX_CHAR = 500;

const CreatePost = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [postText, setPostText] = useState('') 
    const [remainingChar, setRemainingChar] = useState(MAX_CHAR)
    const user = useRecoilValue(userAtom)
    const showToast = useShowToast()
    const [posts, setPosts] = useRecoilState(postsAtom)
    const {username} = useParams()
    const [mediaType, setMediaType] = useState('')
    const [tags, setTags] = useState([])
    const [value, setValue] = useState('');
    const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
    const [suggestions, setSuggestions] = useState([
      "3DPrinting", "AerospaceEngineering", "AgriculturalScience", "Algebra", "Anatomy", "AnimalBehavior", 
      "Anthropology", "Archaeology", "Architecture", "ArtificialIntelligence", "Astronomy", "Astrophysics", 
    "ATVs",  "BehavioralEconomics", "Bicycles","Biochemistry", "Bioengineering", "Bioinformatics", "Biology", "Biomechanics", 
      "BiomedicalEngineering", "Biophysics", "Biotechnology", "Botany", "Calculus",   "Cardiology", "Cars", "CellBiology", 
      "ChemicalEngineering", "Chemistry", "CivilEngineering", "ClimateScience",  "Clinical Psychology","Coding", 
      "Cognitive Psychology ",
      "Computers",
      "ComputationalBiology", 
      "ComputerEngineering", "ComputerScience", "Cooking","Cryptography", "Cybersecurity", "DataAnalysis", "DataMining",
      "DataScience", "DatabaseManagement", "DigitalDesign", "Dentistry", "Dermatology","Developmental psychology","EarthScience", "Ecology","Economics",
     "Educational Psychology", "Electrician", "ElectricalEngineering", 
      "Electronics", "Elements","Endocrinology", "Energy", "Engineering", "Entrepeneur", "EnvironmentalScience", "EvolutionaryBiology", "FluidDynamics", 
      "Forensic Psychology",  "ForensicScience", "GameDevelopment", "Gastroenterology", "GeneticEngineering", "Genetics", "Geology",
      "Geometry", "Geophysics", "Health Psychology", "Helicopters ", "Hematology",
      "HumanBiology", "Immunology", "IndustrialEngineering", "Industrial-organizational Psychology", "InformationTechnology", "InorganicChemistry", 
      "MachineLearning", "MarineBiology", "MaterialsScience", "Math", "MechanicalEngineering", 
      "Medicine", "Meteorology", "Microbiology", "MolecularBiology",  "Motorcycles ", "Nanotechnology", "NetworkSecurity", 
      "Neuroscience",   "Neuropsychology",  "Neurosurgery ", "NuclearEngineering", "NuclearPhysics", "Oceanography",    "Oncology ","Optics", "OrganicChemistry", 
      "OrganicFarming", "Orthopedics", "Paleontology", "ParticlePhysics", "Pathology",   "Pediatrics", "PetroleumEngineering", 
      "Pharmacogenomics", "Pharmacology","Philosophy", "PhysicalChemistry", "physics", "Physiology", 
      "Piezoelectricity", "Plasma", "Planes", "Plumbing", "Psychiatry", "Psychology", "Pulmonology", "QuantumComputing", 
      "QuantumMechanics", "QuantumPhysics",  "Radiology","RenewableEnergy",'Rheumatology', "Robotics",  "Social Psychology ", "SoftwareEngineering", 
      "SpaceExploration", "Sports psychology ", "Statistics", "StructuralEngineering", "SystemsBiology", "Thermodynamics", 
      "Test", "Trades", "Virology", "Waves", "WebDevelopment", "WildlifeConservation", "Zoology",
      "Scholarship24"
    ].map(tag => ({ name: tag })));
    const [originalSuggestions, setOriginalSuggestions] = useState([
      "3DPrinting", "AerospaceEngineering", "AgriculturalScience", "Algebra", "Anatomy", "AnimalBehavior", 
      "Anthropology", "Archaeology", "Architecture", "ArtificialIntelligence", "Astronomy", "Astrophysics", 
    "ATVs",  "BehavioralEconomics", "Bicycles","Biochemistry", "Bioengineering", "Bioinformatics", "Biology", "Biomechanics", 
      "BiomedicalEngineering", "Biophysics", "Biotechnology", "Botany", "Calculus",   "Cardiology", "Cars", "CellBiology", 
      "ChemicalEngineering", "Chemistry", "CivilEngineering", "ClimateScience",  "Clinical Psychology","Coding", 
      "Cognitive Psychology ",
      "Computers",
      "ComputationalBiology", 
      "ComputerEngineering", "ComputerScience", "Cooking","Cryptography", "Cybersecurity", "DataAnalysis", "DataMining",
      "DataScience", "DatabaseManagement", "DigitalDesign", "Dentistry", "Dermatology","Developmental psychology","EarthScience", "Ecology","Economics",
     "Educational Psychology", "Electrician", "ElectricalEngineering", 
      "Electronics", "Elements","Endocrinology", "Energy", "Engineering", "Entrepeneur", "EnvironmentalScience", "EvolutionaryBiology", "FluidDynamics", 
      "Forensic Psychology",  "ForensicScience", "GameDevelopment", "Gastroenterology", "GeneticEngineering", "Genetics", "Geology",
      "Geometry", "Geophysics", "Health Psychology", "Helicopters ", "Hematology",
      "HumanBiology", "Immunology", "IndustrialEngineering", "Industrial-organizational Psychology", "InformationTechnology", "InorganicChemistry", 
      "MachineLearning", "MarineBiology", "MaterialsScience", "Math", "MechanicalEngineering", 
      "Medicine", "Meteorology", "Microbiology", "MolecularBiology",  "Motorcycles ", "Nanotechnology", "NetworkSecurity", 
      "Neuroscience",   "Neuropsychology",  "Neurosurgery ", "NuclearEngineering", "NuclearPhysics", "Oceanography",    "Oncology ","Optics", "OrganicChemistry", 
      "OrganicFarming", "Orthopedics", "Paleontology", "ParticlePhysics", "Pathology",   "Pediatrics", "PetroleumEngineering", 
      "Pharmacogenomics", "Pharmacology","Philosophy", "PhysicalChemistry", "physics", "Physiology", 
      "Piezoelectricity", "Plasma", "Planes", "Plumbing", "Psychiatry", "Psychology", "Pulmonology", "QuantumComputing", 
      "QuantumMechanics", "QuantumPhysics",  "Radiology","RenewableEnergy",'Rheumatology', "Robotics",  "Social Psychology ", "SoftwareEngineering", 
      "SpaceExploration", "Sports psychology ", "Statistics", "StructuralEngineering", "SystemsBiology", "Thermodynamics", 
      "Test", "Trades", "Virology", "Waves", "WebDevelopment", "WildlifeConservation", "Zoology",
      "Scholarship24"
    ].map(tag => ({ name: tag })));
    const [loading, setLoading] = React.useState(false);
    const imageRef = useRef(null);
    const { handleMediaChange, mediaUrl, setMediaUrl, currentChunkIndex, totalChunksState } = usePreviewMedia(setLoading);


    const handleTextChange = (e) => {
      const inputText = e.target.value;

      if(inputText.length > MAX_CHAR){
        const truncatedText = inputText.slice(0, MAX_CHAR);
        setPostText(truncatedText);
        setRemainingChar(0);
      } else {
        setPostText(inputText);
        setRemainingChar(MAX_CHAR - inputText.length);
      }
    }

    useEffect(() => {
      if (mediaUrl) {
        if (typeof mediaUrl === 'string' && mediaUrl.startsWith('blob:')) {
          fetch(mediaUrl)
            .then(response => response.blob())
            .then(blob => {
              setMediaType(blob.type);
            });
        } else {
          fetch(mediaUrl, { method: 'HEAD' })
            .then(response => setMediaType(response.headers.get('Content-Type')));
        }
      }
    }, [mediaUrl]);

    
    const handleCreatePost = async () => {
      setLoading(true);
      console.log("Current user ID:", user._id);
    
      if (!user._id) {
        showToast("Error", "User not identified", "error");
        setLoading(false);
        return; 
      }
    
      const formData = new FormData();
      formData.append('postedBy', user._id);
      formData.append('text', postText);
      
      // Replace the JSON.stringify(tags) with a loop that appends each tag individually
      if (tags.length > 0) {
        tags.forEach((tag, index) => {
          formData.append(`tags[${index}]`, tag);
        });
      }
      if (mediaUrl && typeof mediaUrl === 'object' && mediaUrl.url) {
        formData.append('imgUrl', mediaUrl.url);
      } else if (mediaUrl instanceof File && mediaUrl.size > MAX_CHUNK_SIZE) {
        // Handle large file case here if needed
      } else if (mediaUrl instanceof File || (typeof mediaUrl === 'string' && mediaUrl.trim() !== '')) {
        formData.append(mediaUrl instanceof File ? 'img' : 'imgUrl', mediaUrl);
      }
    
      await uploadPost(formData);
    
      onClose();
      setPostText('');
      setMediaUrl('');
      setLoading(false);
      window.location.reload();
    };

    const uploadChunk = async (formData) => {
      try {
        const res = await fetch('/api/posts/createChunk', {
          method: 'POST',
          body: formData,
        });
        const data = await res.json();
        if (data.error) {
          showToast("Error", data.error, "error");
          throw new Error(data.error); 
        }
        console.log("Chunk upload successful", data);
      } catch (error) {
        console.error("Chunk upload failed", error);
        showToast("Error", "An unexpected error occurred during chunk upload", "error");
        throw error; 
      }
    };
    
    const uploadPost = async (formData) => {
      try {
        const res = await fetch('/api/posts/create', {
          method: 'POST',
          body: formData,
        });
        const data = await res.json();
        console.log("API Response:", data);
        if (data.error) {
          showToast("Error", data.error, "error");
          return;
        }
      } catch (error) {
        console.error(error);
        showToast("Error", "An unexpected error occurred", "error");
      }
    };

    const getSuggestions = value => {
      const tags = value.split(',');
    
      const lastTag = tags[tags.length - 1].trim();
    
      const inputValue = lastTag.toLowerCase();
    
      const inputLength = inputValue.length;
    
      return inputLength === 0 ? [] : originalSuggestions.filter(suggestion =>
        suggestion.name.toLowerCase().includes(inputValue)
      );
    };


    const getSuggestionValue = suggestion => {
      const tagsArray = value.split(',');
    
      tagsArray[tagsArray.length - 1] = suggestion.name;
    
      setTags(tagsArray);
      return `${tagsArray.join(', ')}, `;
    };
const renderSuggestion = suggestion => (
  <div>
    {suggestion.name}
  </div>
);


const inputProps = {
  placeholder: 'Tags',
  value,
  onChange: (event, { newValue }) => {
    setValue(newValue);
  },
  style: {
    fontFamily: 'Orbitron, sans-serif',
    backgroundColor: 'black',
    color: 'white', 
    border: '1px solid white',
    borderRadius: '5px', 
    width: '400px', 
    height: '40px'
  }
};

const onSuggestionsFetchRequested = ({ value }) => {
  console.log('onSuggestionsFetchRequested called with value:', value);
  setSuggestions(getSuggestions(value));
};

const onSuggestionsClearRequested = () => {
  setSuggestions([]);
};

const renderInputComponent = inputProps => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ fontSize: '24px', marginRight: '10px' }}>#</span>
    <input {...inputProps} />
  </div>
);

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    handleCreatePost();
  }
}

const convertBase64ToBlobUrl = (base64String) => {
  const parts = base64String.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  const blob = new Blob([uInt8Array], { type: contentType });
  return URL.createObjectURL(blob);
};

 return <>
  <Button position={'fixed'} bottom={10} right={isSmallScreen ? '50' : '5'}
  bg='gray.700'
  onClick={onOpen} size={{ base: 'sm', sm:'md', md: 'lg' }} 
  border="2px" borderColor="rgb(135, 206, 250)" ><AddIcon color="rgb(135, 206, 250)" /></Button>

<Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />

        <ModalContent backgroundColor="black" border="1px" borderColor="#white">
          <ModalHeader textAlign="center" fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)">Create Post</ModalHeader>
          <ModalBody pb={6}>
             <FormControl>
    <Textarea
      placeholder='Post content goes here'
      fontFamily={'Orbitron, sans-serif'} 
      color="rgb(135,206,250)"
      backgroundColor='black'
      onChange={handleTextChange}
      onKeyDown={handleKeyDown} 
      value={postText}
    />
<Box marginTop="4">

<Autosuggest
  suggestions={suggestions}
  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
  onSuggestionsClearRequested={onSuggestionsClearRequested}
  getSuggestionValue={getSuggestionValue}
  renderSuggestion={renderSuggestion}
  inputProps={inputProps}
  renderInputComponent={renderInputComponent}
/>
</ Box>

            <Text fontSize="xs"
            fontWeight="bold"
            textAlign={'right'}
            m={"1"}
            color={"gray.800"}>
                {remainingChar}/{MAX_CHAR}
            </Text>
         
            <div>
            {loading ? (
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '10vh', 
    transform: 'translateY(20px)' 
  }}>
    <Text fontFamily={'Orbitron, sans-serif'} 
    color="rgb(135,206,250)" style={{ marginBottom: '20px' }}> Uploading Your Video {currentChunkIndex} of {totalChunksState}</Text>
    <Spinner /> 
  </div>
      ) : (
        <Input type='file' hidden ref={imageRef} onChange={handleMediaChange} />
      )}
    </div>
<LiaCameraRetroSolid  style={{marginLeft:"5px", cursor:"pointer"}} size={24} color="#FF6EC7"
onClick={() => imageRef.current.click()} />
</FormControl> 

{typeof mediaUrl === 'string' ? (
  mediaUrl && (
    <Flex mt={5} w={'full'} position={'relative'}>
      {(mediaUrl.startsWith('data:video/') || mediaUrl.startsWith('blob:')) ? (
        <ReactPlayer url={mediaUrl.startsWith('data:video/') ? convertBase64ToBlobUrl(mediaUrl) : mediaUrl} controls={true} width='320px' height='240px' />
      ) : (
        <Image src={mediaUrl}/>
      )}
      <CloseButton 
        onClick={() => {
          setMediaUrl('')
          setMediaType('')
        }} 
        bg={'gray.800'} 
        position={'absolute'} 
        top={2} 
        right={2}
      />
    </Flex>
  )
) : (
  mediaUrl && mediaUrl.url && (
    <Flex mt={5} w={'full'} position={'relative'}>
      <ReactPlayer url={mediaUrl.url} controls={true} width='320px' height='240px' />
      <CloseButton 
        onClick={() => {
          setMediaUrl('')
          setMediaType('')
        }} 
        bg={'gray.800'} 
        position={'absolute'} 
        top={2} 
        right={2}
      />
    </Flex>
  )
)}
</ModalBody>

<ModalFooter>

            <Button  mr={3} onClick={handleCreatePost} 
            isLoading={loading} fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)">
              Post
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
</>
}

export default CreatePost
