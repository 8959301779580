/* Χαῖρε Μαρία κεχαριτωμένη ὁ Κύριος μετά σοῦ Ἐυλογημένη σὺ ἐν γυναιξὶ, καὶ εὐλογημένος ὁ καρπὸς τῆς κοιλίας 
σοῦ Ἰησούς Ἁγία Μαρία, μῆτερ θεοῦ προσεύχου ὑπέρ ἡμῶν τῶν ἁμαρτωλῶν, νῦν καὶ ἐν τῇ ὥρᾳ τοῦ θανάτου ἡμῶν. */

import React from 'react'
import SignupCard from '../components/SignupCard'
import LoginCard from '../components/LoginCard'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import authScreenAtom from '../atoms/authAtom'
import { Flex } from '@chakra-ui/react';
import { useMediaQuery } from "@chakra-ui/react";

const AuthPage = () => {
const authScreenState = useRecoilValue(authScreenAtom);
const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-width 1024px)')

return (
  <Flex ml ={265} mt={-50}>
    {authScreenState === 'login' ? <LoginCard /> : <SignupCard />}
  </Flex>
)
}

export default AuthPage


/* import React from 'react'
import SignupCard from '../components/SignupCard'
import LoginCard from '../components/LoginCard'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import authScreenAtom from '../atoms/authAtom'

const AuthPage = () => {
    const authScreenState = useRecoilValue(authScreenAtom);
    
  return (
    <>
        {authScreenState === 'login' ? <LoginCard /> : <SignupCard />}
    </>
  )
}

export default AuthPage */