/* Je vous salue, Marie pleine de grâce le Seigneur est avec vous. Vous êtes bénie entre toutes les femmes 
et Jésus le fruit de vos entrailles, est béni. Sainte Marie, Mère de Dieu, priez pour nous pauvres pécheurs
maintenant et à l’heure de notre mort*/

import React from 'react';
import { ChakraProvider, extendTheme, Box, Flex, Image, Tooltip, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import HiveMindSearch from '../HiveMindSearch';
import { mode } from "@chakra-ui/theme-tools";

const HiveMindMath = () => {
  const styles = {
    global: (props) => ({
      body: {
        bg: 'black',
        color: mode('gray.800', 'whiteAlpha.900')(props),
      },
    }),
  };

  const theme = extendTheme({ styles });

  const center = useBreakpointValue({
    base: "10%", //iphone
    sm: "10%",
    md: "31%", //ipad Mini
    lg: "39%",
    xl: "39%"
  });

  const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
  const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
  const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");

  const adjustedCenter = isIphoneXR ? "15%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : center;

  return (
    <ChakraProvider theme={theme}>
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="100%" 
        left={adjustedCenter}
      >
        <Box mt={-100}><HiveMindSearch /></Box>
        <Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px">Math</Box> 

        <Tooltip label="Calculus" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Calculus">
              <Image src="calculus.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Geometry" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Geometry">
              <Image src="geometry.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Statistics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Statistics">
              <Image src="statistics.png"boxSize={{ base: "100px", md: "150px" }}/>
            </Link>
          </Box>
        </Tooltip>
      </Flex>
    </ChakraProvider>
  );
}

export default HiveMindMath;