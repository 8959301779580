 /* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/

import { Box, Container } from "@chakra-ui/react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
 import UserPage from "./pages/UserPage"; 
import PostPage from "./pages/PostPage";
import Header from './components/Header'
import HomePage from "./pages/HomePage";
import AuthPage from "./pages/AuthPage";
import FundingPage from "./pages/FundingPage";
import ScholarScoreboard from "./pages/ScholarScoreboard";
import { useRecoilValue } from "recoil";
import userAtom from "./atoms/userAtom";
import LogoutButton from "./components/LogoutButton";
import UpdateProfilePage from "./pages/UpdateProfilePage";
import CreatePost from "./components/CreatePost";
import ChatPage from "./pages/ChatPage";
import { SettingsPage } from "./pages/SettingsPage";
import GlobalPage from "./pages/GlobalPage";
import PageLayout from "./components/Layouts/PageLayout";
import HiveMindHome from "./pages/HiveMindHome";
import HiveMindPage from "./pages/HiveMindPage";
import HiveMindComputer from './components/HiveMind/HiveMindComputer';
import HiveMindBiology from './components/HiveMind/HiveMindBiology';
import HiveMindChemistry from './components/HiveMind/HiveMindChemistry';
import HiveMindEarth from "./components/HiveMind/HiveMindEarth";
import HiveMindEngineering from "./components/HiveMind/HiveMindEngineering";
import HiveMindMath from "./components/HiveMind/HiveMindMath";
import HiveMindMedicine from "./components/HiveMind/HiveMindMedicine";
import HiveMindMisc from "./components/HiveMind/HiveMindMisc";
import HiveMindPhysics from "./components/HiveMind/HiveMindPhysics";
import TagsPage from "./pages/TagsPage";
import { useUserAction } from './hooks/userActionContext.jsx';
import { useEffect } from "react";
import SearchPage from "./pages/SearchPage.jsx";
import HiveMindPsychology from "./components/HiveMind/HiveMindPsychology";
import HiveMindVehicles from "./components/HiveMind/HiveMindVehicles";

function App() {
  const user = useRecoilValue(userAtom)
  const { setLastAction } = useUserAction(); 
  const {pathname} = useLocation()
  const { lastAction } = useUserAction();
  const navigate = useNavigate();

  useEffect(() => {
    if (lastAction === 'signup') {
      navigate('/Rec');
    } else if (lastAction === 'login') {
      navigate('/');
      setLastAction(null);
    }
  }, [lastAction, navigate]);

  return (
    <Box position={'relative'} w='full'>
      <Header mb={10} />
      <PageLayout>
        <Container maxW="620px"> 
          <Routes>
          <Route path='/' element={user ? <><GlobalPage /><CreatePost /></> : <Navigate to="/auth" />} />
            <Route path='/auth' element={
  !user ? <AuthPage /> : <Navigate to={lastAction === 'login' ? '/' : '/Rec'} />
}/>
      <Route path='/home' element={user ? <><HomePage /><CreatePost /></> : <Navigate to="/auth" />} />
            <Route path='/update' element={user ? <UpdateProfilePage /> : <Navigate to="/auth" /> }/>
            <Route path='/Rec' element={user ? <TagsPage /> : <Navigate to="/" /> }/>
            <Route path="/search" element={<SearchPage />} />
            <Route path="/:username" element={ 
            user ? ( 
              <> <UserPage />  <CreatePost /> 
              </>
            ) : ( <UserPage /> )} /> 
            <Route path="/:username/post/:pid" element={<PostPage />} />
            <Route path="/chat" element={user ? <ChatPage /> : <Navigate to={'/auth'} />} />
            <Route path="/settings" element={user ? <SettingsPage /> : <Navigate to={'/auth'} />} />
            <Route path="/funding" element={user ? <FundingPage /> : <Navigate to={'/auth'} />} />
            <Route path="/scholarscores" element={user ? <ScholarScoreboard /> : <Navigate to={'/auth'} />} />
            <Route path='/Hivemenu' element={user ? <HiveMindHome /> : <Navigate to={'/auth'} />} />
            <Route path="tags/:tags" element={user ? <HiveMindPage/> : <Navigate to={'/auth'} />} />
            <Route path="HiveMindBiology" element={user ? <HiveMindBiology /> : <Navigate to={'/auth'} />}/>
            <Route path="HiveMindChemistry" element={user ? <HiveMindChemistry /> : <Navigate to={'/auth'} />}/>
            <Route path="HiveMindComputer" element={user ? <HiveMindComputer /> : <Navigate to={'/auth'} />}/>
            <Route path="HiveMindEarth" element={user ? <HiveMindEarth /> : <Navigate to={'/auth'} />}/>
            <Route path="HiveMindEngineering" element={user ? <HiveMindEngineering /> : <Navigate to={'/auth'} />}/>
            <Route path="HiveMindMath" element={user ? <HiveMindMath /> : <Navigate to={'/auth'} />}/>
            <Route path="HiveMindMedicine" element={user ? <HiveMindMedicine /> : <Navigate to={'/auth'} />}/>
            <Route path="HiveMindMisc" element={user ? <HiveMindMisc /> : <Navigate to={'/auth'} />}/>
            <Route path="HiveMindPhysics" element={user ? <HiveMindPhysics /> : <Navigate to={'/auth'} />}/>
            <Route path="HiveMindVehicles" element={user ? <HiveMindVehicles /> : <Navigate to={'/auth'} />}/>
            <Route path="HiveMindPsychology" element={user ? <HiveMindPsychology /> : <Navigate to={'/auth'} />}/>
          </Routes>
          </Container>
      </PageLayout>
    </Box>  
  )
  }
  
  export default App
