/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/

import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box } from "@chakra-ui/react";
import Like from "./ArrowItems/Like";
import Com from "./ArrowItems/Com";
import FeedQB from "./ArrowItems/FeedQB";
import FeedSave from "./ArrowItems/FeedSave";
import { Flex } from "@chakra-ui/react";
import Report from "./ArrowItems/Report";

const Arrow = ({post}) => {
    return (
       <Accordion allowMultiple>
    <AccordionItem>
        <h2>
            <AccordionButton>
                <AccordionIcon color={"rgb(135,206,250)"} />
                <Box as="span" flex='1' textAlign='left' >
                </Box>
            </AccordionButton>
        </h2>
        <AccordionPanel pb={3} border="1px" borderRadius="lg" h='20%' w="100%" borderColor={"rgb(135,206,250)"}>
            <Flex direction="row" justify="space-between"  >
                <Flex direction="column">
              {/*  <FeedSave/> */}
              <Com post={post}/>
                </Flex>
                <Flex direction="column">
                    
                    <Like post={post}/> 
                </Flex>
             
            </Flex>
            <Flex direction="row" justify="space-between"  >
            <Flex>
                    <Report post={post}/>
                </Flex>
</Flex>
        </AccordionPanel>
    </AccordionItem>
</Accordion>
    )
}

export default Arrow;

/* import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box } from "@chakra-ui/react";
import Like from "./ArrowItems/Like";
import Com from "./ArrowItems/FeedCom";
import FeedQB from "./ArrowItems/FeedQB";
import FeedSave from "./ArrowItems/FeedSave";
import { Flex } from "@chakra-ui/react";

const Arrow = () => {
    return (
       <Accordion allowMultiple>
    <AccordionItem>
        <h2>
            <AccordionButton>
                <AccordionIcon color={"rgb(135,206,250)"} />
                <Box as="span" flex='1' textAlign='left'>
                </Box>
            </AccordionButton>
        </h2>
        <AccordionPanel pb={3} border="1px" borderColor="white.300" borderRadius="lg" h='20%' w="100%">
            <Flex direction="row" justify="space-between">
                <Flex direction="column">
                    <FeedSave/> 
                    <FeedQB/>
                </Flex>
                <Flex direction="column">
                    <Com/>
                    <Like/>
                </Flex>
            </Flex>
        </AccordionPanel>
    </AccordionItem>
</Accordion>
    )
}

export default Arrow; */