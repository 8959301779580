import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './index.css';
import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/theme-utils';
import { ColorModeScript } from '@chakra-ui/color-mode';
import { mode } from '@chakra-ui/theme-tools';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SocketContextProvider } from './context/SocketContext.jsx';
import { UserActionProvider } from './hooks/userActionContext.jsx';
import { useLocation } from 'react-router-dom';

const styles = {
  global: (props) => ({
    body: {
      color: mode('gray.800', 'whiteAlpha.900')(props),
      bg: 'black',
    },
  }),
};

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: true,
};

const colors = {
  gray: {
    light: '#616161',
    dark: '#1e1e1e',
  },
};

const theme = extendTheme({ config, styles, colors });

const RootComponent = () => {
  const location = useLocation();
  const isAuthPage = location.pathname === '/auth';

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <RecoilRoot>
        <SocketContextProvider>
          <UserActionProvider>
            <App />
          </UserActionProvider>
        </SocketContextProvider>
      </RecoilRoot>
    </ChakraProvider>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
 // <React.StrictMode>
    <BrowserRouter>
      <RootComponent />
    </BrowserRouter>
 // </React.StrictMode>
);
/* Ave Maria gratia plena, Dominus tecum, benedicta tu in mulieribus et benedictus fructus 
ventris tui Iesu, Santa Maria, mater Dei, ora pro nobis peccatoribus, nunc et in ora mortis nostrae */


/* 
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/theme-utils'
import { ColorModeScript } from '@chakra-ui/color-mode'
import { mode } from '@chakra-ui/theme-tools'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { SocketContextProvider } from './context/SocketContext.jsx'
import { useLocation } from 'react-router-dom';
import { useUserAction } from './hooks/userActionContext.jsx';
import { UserActionProvider } from './hooks/userActionContext.jsx';

const styles = {
  global: (props) => {
    const location = useLocation();
    const isAuthPage = location.pathname === '/auth';

    return {
      body: {
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: 'black'
      },
    };
  },
};

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: true,
}

const colors = {
  gray:{
    light:'#616161',
    dark: '#1e1e1e',
  }
}

const theme = extendTheme({config, styles, colors})

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RecoilRoot>
    <BrowserRouter>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <UserActionProvider>
      <SocketContextProvider>
    <App />
    </SocketContextProvider>
    </UserActionProvider>
    </ChakraProvider>
    </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
)

*/