/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/
/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/

import { Flex, Box, FormControl, FormLabel, Input, InputGroup, HStack,
  Image, InputRightElement, Stack,
  Button, Heading, Text, useColorModeValue,Link, 
  useMediaQuery} from '@chakra-ui/react'
  import { useState } from 'react'
  import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
  import { useSetRecoilState } from 'recoil'
  import authScreenAtom from '../atoms/authAtom'
  import useShowToast from '../hooks/useShowToast'
  import userAtom from '../atoms/userAtom'
  import { useUserAction } from '../hooks/userActionContext.jsx';

  export default function LoginCard() {
  const [showPassword, setShowPassword] = useState(false)
  const setAuthScreen = useSetRecoilState(authScreenAtom)
  const setUser = useSetRecoilState(userAtom)
  const [loading, setLoading] = useState(false)
  const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
  const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
  const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");
  const [inputs, setInputs] = useState({
  username:"",
  password:"",})
  const showToast = useShowToast()
  


  const { setLastAction } = useUserAction();

  const handleLogin = async () => {
    console.log('handleLogin started'); // Debugging line
    setLoading(true);
    try {
      const res = await fetch("/api/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputs)
      });
      const data = await res.json();
      console.log('Fetch completed', data); // Debugging line
      if (data.error) {
        showToast("Error", data.error, "error");
        return;
      }
    
      setLastAction('login');
      console.log('Last action set to:', 'login'); 
      localStorage.setItem("user-ark", JSON.stringify(data));
      setUser(data);
     
    } catch (error) {
      console.log("Error caught", error); 
      showToast("Error", error, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
<Flex align={'center'} ml={isMac ? "-175px" : isIpadEsq ? "-300px" : isSmallScreen ? "-250px" : "-175px"} fontFamily="Orbitron, sans-serif">
   <Stack spacing={isIpadEsq ? 10 : 8} mx={'auto'} maxW={isIpadEsq ? '2xl' : 'lg'} py={0} px={isIpadEsq ? 10 : 6}>
  <Stack align={'center'}>
  </Stack>
  <Box
    rounded={'lg'}
    bg={useColorModeValue('black')}
    boxShadow={'lg'}
    p={isIpadEsq ? 12 : 8}
    w={{
      base: 'full',
      sm: isIpadEsq ? '600px' : '400px',
    }}
  >
    <Stack spacing={isIpadEsq ? 8 : 4}>
      <FormControl isRequired>
      <Input type="text" placeholder='Username'
      value={inputs.username}
      onChange={(e) => setInputs((inputs) => ({...inputs, username: e.target.value}))}
      onKeyDown={handleKeyDown}
      bg="black"
      size={isIpadEsq ? 'xl' : 'md'}
      fontSize={isIpadEsq ? '2xl' : 'md'} 
      p={isIpadEsq ? 6 : 4} 
      borderRadius="lg" 
/>
      </FormControl>
      <FormControl isRequired>
        <InputGroup>
          <Input type={showPassword ? 'text' : 'password'} placeholder='Password'
            value={inputs.password}
            onChange={(e) => setInputs((inputs) => ({...inputs, password: e.target.value}))}
            onKeyDown={handleKeyDown}
            bg="black"
            size={isIpadEsq ? 'xl' : 'md'}
            fontSize={isIpadEsq ? '2xl' : 'md'} 
            p={isIpadEsq ? 10 : 4} 
          />
          <InputRightElement h={'full'}>
            <Button
              variant={'ghost'}
              onClick={() => setShowPassword((showPassword) => !showPassword)}
              size={isIpadEsq ? 'xl' : 'md'}
            >
              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Stack spacing={isIpadEsq ? 12 : 10} pt={isIpadEsq ? 6 : 2}>
      <Button
  isLoading={loading}
  size={isIpadEsq ? 'xl' : 'md'}
  bg={useColorModeValue("darkgray")}
  color={'blue.400'}
  border="2px"
  borderColor="rgb(135, 206, 250)"
  _hover={{
    bg: useColorModeValue("gray.700", "gray.800"),
  }}
  onClick={handleLogin}
  sx={{
    fontSize: isIpadEsq ? '20px' : '16px', 
    p: isIpadEsq ? '24px' : '12px', 
    minWidth: isIpadEsq ? '100px' : 'auto', 
    height: isIpadEsq ? '60px' : 'auto', 
  }}
>
          {loading ? (
            <Image
              src="zoomstar.gif" 
              alt="Loading..."
              boxSize={isIpadEsq ? '32px' : '16px'}
            />
          ) : (
            'Login'
          )}
        </Button>
      </Stack>
      <Stack pt={isIpadEsq ? 8 : 6}>
      <Box border="2px" borderColor="rgb(135, 206, 250)" p={isIpadEsq ? 8 : 4} borderRadius="lg">
  <Stack pt={isIpadEsq ? 2 : 0.5} align={'center'} justifyContent={'center'} height="100%">
    <Flex flexDirection="column" alignItems="center">
      <Text fontSize={isIpadEsq ? '2xl' : 'md'} mb={isIpadEsq ? 4 : 1} color="orange" textAlign="center">
        Don't have an account?
      </Text>
      <Button
        variant="link"
        color="orange"
        fontSize={isIpadEsq ? '3xl' : 'lg'} 
        onClick={() => setAuthScreen('signup')}
        p={isIpadEsq ? 6 : 2} 
        minHeight={isIpadEsq ? '48px' : 'auto'} 
      >
        Sign Up
      </Button>

    </Flex>
\
  </Stack>
</Box>

{/* 
<Box border="2px" borderColor="rgb(135, 206, 250)" p={isIpadEsq ? 8 : 4} borderRadius="lg">
<Flex flexDirection="column" alignItems="center">
    <Button  color="orange" bg="black">
        Forgot Password?
      </Button>
     
</Flex>
</Box>
*/}
      </Stack>
    </Stack>
  </Box>
</Stack>
  </Flex>
  )
  }


/* 

  const styles = {
    global: (props) => ({
      body: {
        bg: 'black',
        color: mode('gray.800', 'whiteAlpha.900')(props),
      },
    }),
  };

  const theme = extendTheme({ styles });

  const center = useBreakpointValue({
    base: "90%", // Adjusted for iPhone
    sm: "50%",   // Adjusted for small screens
    md: "50%",   // Adjusted for iPad Mini
    lg: "50%",   // Adjusted for large screens
    xl: "50%"    // Adjusted for extra-large screens
  });

  const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
  const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
  const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");

  const adjustedCenter = isIphoneXR ? "50%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : center;

  const overflowX = useBreakpointValue({
    base: "hidden",
    sm: "auto",
    md: "0",
    lg: "0",
    xl: "auto"
  });

  const adjustedOverflow = isIphoneXR ? "50%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : overflowX;


  const marginX = useBreakpointValue({
    base: "auto", // Adjust margin to prevent overflow on the right side
    sm: "0",
    md: "0",
    lg: "0",
    xl: "0"
  });

  const adjustedMarginX = isIphoneXR ? "50%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : marginX;


  const paddingRight = useBreakpointValue({
    base: "5rem", // Add some padding on the right for base breakpoint
    sm: "0",
    md: "0",
    lg: "0",
    xl: "0"
  });
  const adjustedRight = isIphoneXR ? "50%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : paddingRight;

  
  const maxWidth = useBreakpointValue({
    base: "calc(100% - -5rem)", // Limit the width to avoid excessive overflow
    sm: "100%",
    md: "100%",
    lg: "100%",
    xl: "100%"
  });
  const adjustedWidth = isIphoneXR ? "50%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : maxWidth;


  const { setLastAction } = useUserAction();

  const handleLogin = async () => {
    console.log('handleLogin started'); 
    setLoading(true);
    try {
      const res = await fetch("/api/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputs)
      });
      const data = await res.json();
      console.log('Fetch completed', data); 
      if (data.error) {
        showToast("Error", data.error, "error");
        return;
      }
    
      setLastAction('login');
      console.log('Last action set to:', 'login'); 
      localStorage.setItem("user-ark", JSON.stringify(data));
      setUser(data);
     
    } catch (error) {
      console.log("Error caught", error); 
      showToast("Error", error, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };
  return (
    <ChakraProvider theme={theme}>
<Flex
  h="100vh"
  overflowX={overflowX}
  right={adjustedCenter}
  
>
        <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6} w="full">
          <Box rounded="lg" bg={useColorModeValue('black')} boxShadow="lg" p={8} w="full">
            <Stack spacing={4}>
              <FormControl isRequired>
                <Input
                  type="text"
                  placeholder="Username"
                  value={inputs.username}
                  onChange={(e) => setInputs((inputs) => ({ ...inputs, username: e.target.value }))}
                  onKeyDown={handleKeyDown}
                  bg="black"
                  size="md"
                  fontSize="md"
                  fontFamily="Orbitron, sans-serif"
                  p={4}
                  borderRadius="lg"
                  right={adjustedCenter}
                  w="full"
                />
              </FormControl>
              <FormControl isRequired>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={inputs.password}
                    onChange={(e) => setInputs((inputs) => ({ ...inputs, password: e.target.value }))}
                    onKeyDown={handleKeyDown}
                    bg="black"
                    size="md"
                    fontSize="md"
                    fontFamily="Orbitron, sans-serif"
                    p={4}
                    borderRadius="lg"
                    right={adjustedCenter}
                    w="full"
                  />
                </InputGroup>
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  isLoading={loading}
                  size="md"
                  bg={useColorModeValue("darkgray")}
                  color="blue.400"
                  fontFamily="Orbitron, sans-serif"
                  border="2px"
                  borderColor="rgb(135, 206, 250)"
                  _hover={{ bg: useColorModeValue("gray.700", "gray.800") }}
                  onClick={handleLogin}
                  sx={{ fontSize: '16px', p: '12px', minWidth: 'auto', height: 'auto', right: adjustedCenter, w: 'full' }}
                >
                  {loading ? (
                    <Image src="zoomstar.gif" alt="Loading..." boxSize="16px" />
                  ) : (
                    'Login'
                  )}
                </Button>
              </Stack>
              <Stack pt={6} position="relative" right={adjustedCenter} w="full">
                <Box border="2px" borderColor="rgb(135, 206, 250)" p={4} borderRadius="lg" w="full">
                  <Stack pt={0.5} height="100%">
                    <Flex flexDirection="column">
                      <Text fontSize="md" mb={1} color="orange" fontFamily="Orbitron, sans-serif" width="300px" textAlign="center">
                        Don't have an account?
                      </Text>
                      <Button
                        variant="link"
                        color="orange"
                        fontSize="lg"
                        onClick={() => setAuthScreen('signup')}
                        p={2}
                        minHeight="auto"
                        fontFamily="Orbitron, sans-serif"
                      >
                        Sign Up
                      </Button>
                    </Flex>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </ChakraProvider>
  )
    };
    

*/