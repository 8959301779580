/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/

import { useToast } from '@chakra-ui/react'
import React, { useCallback } from 'react'

const useShowToast = () => {
    const toast = useToast()
    const showToast = useCallback((title, description, status) => {
        toast({
            title,
            description,
            status,
            duration: 5001,
            isClosable: true,
            variant: "left-accent", 
            colorScheme:"blue",
            fontFamily:'Orbitron, sans-serif'
             
        })
    }, [toast])
  return showToast
}

export default useShowToast