import React from 'react'
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { useUserAction } from '../hooks/userActionContext.jsx';
import { useMediaQuery } from "@chakra-ui/react";
import { HStack } from '@chakra-ui/react'
import '/src/index.css'
const TagsPage = () => {

    const navigate = useNavigate();
    const [isClicked, setIsClicked] = useState(false); 
    const { setLastAction } = useUserAction(); 
    const [clickedTags, setClickedTags] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");


    useEffect(() => {
       
        return () => {
          localStorage.setItem('hasVisitedTagsPage', 'true');
        };
      }, []); 

    const goGlobal = () => {
        setLastAction('');
        navigate('/');
    }


    const updateUserInterest = (tag) => {
        let interests = JSON.parse(localStorage.getItem('userInterests')) || [];
        console.log("Initial interests:", interests);
    
        if (!interests.includes(tag)) {
            console.log(`Adding tag: ${tag}`);
            interests.push(tag);
        } else {
            console.log(`Removing tag: ${tag}`);
            interests = interests.filter(t => t !== tag);
        }
    
        localStorage.setItem('userInterests', JSON.stringify(interests));
        console.log("Updated interests:", interests);
    
        const token = localStorage.getItem('token');
        console.log("Sending request with token:", token);
    
        fetch('/api/users/rec', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                interests: interests,
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            // Correctly toggle tag in clickedTags state
            setClickedTags(prev => {
                if (prev.includes(tag)) {
                    return prev.filter(t => t !== tag);
                } else {
                    return [...prev, tag];
                }
            });
            setLastAction('');
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };
    
    useEffect(() => {
        const timer = setTimeout(() => {
          setIsVisible(true);
        }, 1000);
    
        return () => clearTimeout(timer);
      }, []);
    
    
    
  return (
    <div style={{ 
        opacity: isVisible ? 1 : 0,
      transition: 'opacity 2s ease-in-out',
      width: '100%', 
      }}>  
      

  <div className=''>
<div className="container-with-line" style={{ position: 'relative' }}>

 <div className={isSmallScreen ? "outer-container" : ""}> 


<div style={{ height: '2px', backgroundColor: 'orange', width: '65%', position: 'absolute', left: '-350px', top: '30px' }}></div>
      
<Button position={'fixed'} bottom={isSmallScreen ? '50' : '10'} right={isSmallScreen ? 'auto' : '5'} 
left={isSmallScreen ? '25' : 'auto'} size={{ base: 'sm', sm:'md', md: 'lg' }} 
border="2px" bg='black' borderColor="orange" textColor='orange' onClick={goGlobal} 
className="specificComponentButton" opacity={1} zIndex="1000" style={{ overflow: 'visible' }}>Next</Button>
    <Flex>
    <Box position="relative" display="inline-block">
  <Text fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)"
      fontSize="39px" ml={{ base: '2', md: '20' }} mt={isSmallScreen ? '-1' : '-10'}>
    Welcome
  </Text>
  <Box as="span" display="inline-block" position="absolute" right="-500px" top="-2"
     width="500px" height="2px" backgroundColor="rgb(135,206,250)" 
     ml="0" />
</Box>
</Flex>
    <Flex justifyContent="flex-end" mr={isSmallScreen ? "0" : "225"} pr="4">
  <Text fontFamily={'Orbitron, sans-serif'} color="orange" 
        fontSize="20px">Please select your interests</Text>
</Flex>

    <Flex>
<Flex justifyContent="center">
<Box position="relative" display="inline-block">
<Button 
    fontFamily={'Orbitron, sans-serif'} 
    color={clickedTags.includes('Biology') ? "orange" : "rgb(135,206,250)"} 
    ml={isSmallScreen ? '50' : '125'} // This line uses isSmallScreen to conditionally set the margin-left
    mt={isSmallScreen ? '25' : '100'} // Example: Adjusting the margin-top based on isSmallScreen
    fontSize={isSmallScreen ? "20px" : "25px"} // Example: Adjusting the font size based on isSmallScreen
    border="2px solid rgb(135,206,250)"
    onClick={() => updateUserInterest('Biology')}
    _after={{
      content: '""',
      position: 'absolute',
      right: "-540px", // Adjust as needed to position the line correctly
      top: "50%", // Adjust to align with the middle of the button
      transform: "translateY(-50%)",
      width: "540px", // Length of the line
      height: "2px", // Thickness of the line
      backgroundColor: "rgb(135,206,250)", // Color of the line
    }}
  >
    Biology
</Button>
</Box>
  <VStack position="absolute" left="-450px" mt='200'>
  {!isSmallScreen && <Image src='biology.png' boxSize="200px" zIndex="2" />}
    </VStack>

    {isSmallScreen && (
        <Box
    display="flex"
    flexDirection="column"
    overflowY="scroll"
    p={4}
    whiteSpace="normal"
    marginLeft="-500px"
    marginTop="100px" // Adjust this value as needed to move the content down
    width={{ base: '300%' }}
    height="200px"
>
  <VStack mt='' ml='300'>
  <Button fontFamily={'Orbitron, sans-serif'}  bg='#333333'  color={clickedTags.includes('uralScience') ? "orange" : "rgb(135,206,250)"}   onClick={() => updateUserInterest('AgriculturalScience')}>AgriculturalScience</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'   color={clickedTags.includes('Anatomy') ? "orange" : "rgb(135,206,250)"}   onClick={() => updateUserInterest('Anatomy')}>Anatomy</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'  color={clickedTags.includes('BioChemistry') ? "orange" : "rgb(135,206,250)"}   onClick={() => updateUserInterest('BioChemistry')}>BioChemistry</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes('BioEngeneering') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('BioEngeneering')}>BioEngeneering</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes('BioInformatics') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('BioInformatics')}>BioInformatics</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes('Conservation') ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('Conservation')}>Conservation</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Physiology') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Physiology')}>Physiology</Button>
<Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('BioMechanics') ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('BioMechanics')}>BioMechanics</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('BioMediacalEngineering')  ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('BioMediacalEngineering')}>BioMediacalEngineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('BioPhysics')  ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('BioPhysics')}>BioPhysics</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('BioTechnology')  ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('BioTechnology')}>BioTechnology</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('Botany')  ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('Botany')}>Botany</Button>
<Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('MarineBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('MarineBiology')}>Marine Biology</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('CellBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('CellBiology')}>Cell Biology</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('ComputationalBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('ComputationalBiology')}>Computational Biology</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('EvolutionaryBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('EvolutionaryBiology')}>Evolutionary Biology</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('NueroScience')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('NueroScience')}>NueroScience</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Zoology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Zoology')}>Zoology</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('Genetics')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Genetics')}>Genetics</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('MicroBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('MicroBiology')}>MicroBiology</Button>
  
</VStack>


</Box>
    )}
    </Flex>
    </Flex>
  




      
{!isSmallScreen && (
     <HStack spacing="24px" ml='190' mt='-70'>
        <VStack mt='50' ml='-500'>
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('AgriculturalScience') ? "orange" : "rgb(135,206,250)"}   onClick={() => updateUserInterest('AgriculturalScience')}>AgriculturalScience</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'    color={clickedTags.includes('Anatomy') ? "orange" : "rgb(135,206,250)"}   onClick={() => updateUserInterest('Anatomy')}>Anatomy</Button>
        <Button fontFamily={'Orbitron, sans-serif'}  bg='#333333' color={clickedTags.includes('BioChemistry') ? "orange" : "rgb(135,206,250)"}   onClick={() => updateUserInterest('BioChemistry')}>BioChemistry</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Botany')  ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('Botany')}>Botany</Button>
  </VStack>

<VStack mt='65' ml=''>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes('BioEngeneering') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('BioEngeneering')}>BioEngeneering</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes('BioInformatics') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('BioInformatics')}>BioInformatics</Button>
        <Button fontFamily={'Orbitron, sans-serif'}  bg='#333333' color={clickedTags.includes('Conservation') ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('Conservation')}>Conservation</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('Physiology') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Physiology')}>Physiology</Button>
</VStack>

<VStack mt='65' ml=''>
<Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('BioMechanics') ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('BioMechanics')}>BioMechanics</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('BioMediacalEngineering')  ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('BioMediacalEngineering')}>BioMediacalEngineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('BioPhysics')  ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('BioPhysics')}>BioPhysics</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('BioTechnology')  ? "orange" : "rgb(135,206,250)"}  onClick={() => updateUserInterest('BioTechnology')}>BioTechnology</Button>

</VStack>

<VStack mt='65' ml=''>
<Button fontFamily={'Orbitron, sans-serif'}  bg='#333333'color={clickedTags.includes ('MarineBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('MarineBiology')}>Marine Biology</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('CellBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('CellBiology')}>Cell Biology</Button>
        <Button fontFamily={'Orbitron, sans-serif'}  bg='#333333' color={clickedTags.includes ('ComputationalBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('ComputationalBiology')}>Computational Biology</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('EvolutionaryBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('EvolutionaryBiology')}>Evolutionary Biology</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('HumanBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('HumanBiology')}>Human Biology</Button>
</VStack>

<VStack mt='65' ml=''>
    
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('NueroScience')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('NueroScience')}>NueroScience</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('Zoology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Zoology')}>Zoology</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('Genetics')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Genetics')}>Genetics</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('MicroBiology')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('MicroBiology')}>MicroBiology</Button>
  
</VStack>  
</HStack>
)}

<Flex position="relative">
    <Flex justifyContent="center">
        <VStack mt='90' position="absolute" left={isSmallScreen ? "-1000vw" : "-450px"}>
            {!isSmallScreen && <Image src='chemistry.png' boxSize="200px" />}
        </VStack>
        <VStack mt='85' ml={isSmallScreen ? '0' : '100'}>
        <Box position="relative" display="inline-block">
  <Button 
    fontFamily={'Orbitron, sans-serif'} 
    fontSize="25px" 
    color={clickedTags.includes('Chemistry') ? "orange" : "rgb(135,206,250)"} 
    onClick={() => updateUserInterest('Chemistry')}
    border="2px solid orange"
    _before={{
      content: '""',
      position: 'absolute',
      left: "-473px", // Adjust as needed to position the line correctly
      top: "50%", // Adjust to align with the middle of the button
      transform: "translateY(-50%)",
      width: "473px", // Length of the line
      height: "2px", // Thickness of the line
      backgroundColor: "orange", // Color of the line
    }}
  >
    Chemistry
  </Button>
</Box>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('ChemicalEngineering') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('ChemicalEngineering')}>Chemical Engineering</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('OrganicChemistry') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('OrganicChemistry')}>Organic Chemistry</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('QuantumComputing') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('QuantumComputing')}>Quantum Computing</Button>
        </VStack>
    </Flex>
</Flex>


    <Flex>
    <Flex justifyContent="center">



{isSmallScreen && (

    <>

<Button mt='90' ml='5' fontFamily={'Orbitron, sans-serif'} color={clickedTags.includes ('Computers') ? "orange" : "rgb(135,206,250)"}
fontSize="25px" 
border="2px solid rgb(135,206,250)" width="150%" display="inline-block" 
onClick={() => updateUserInterest('Computers')}
_before={{
    content: '""',
    position: 'absolute',
    right: "-500px", // Adjust as needed to position the line correctly
    top: "50%", // Adjust to align with the middle of the button
    transform: "translateY(-50%)",
    width: "500px", // Length of the line
    height: "2px", // Thickness of the line
    backgroundColor: "rgb(135,206,250)", // Color of the line
  }} >Computers</Button>


    <Box
    display="flex"
    flexDirection="row"
    overflowX="scroll"
    p={4}
    whiteSpace="nowrap"
      marginLeft="-225px"
      marginTop="150" 
      width={{base:'250%'}}
      height="200px"
  >
<VStack mt='120' position="absolute" left="-5px">
      {!isSmallScreen && <Image src='computers.png' boxSize="200px" />}
</VStack>

<VStack mt='0' ml=''>
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('ArtificalIntelligence')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('ArtificalIntelligence')}>Artifical Intelligence</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('SoftwareEngineering')  ? "orange" : "rgb(135,206,250)"}
        onClick={() => updateUserInterest('Software Engineering')}>SoftwareEngineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Coding')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Coding')}>Coding</Button>
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('HardwareEngineering')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('HardwareEngineering')}>Hardware Engineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('DatabaseManagment')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('DatabaseManagment')}>Database Managment</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Cybersecurity')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Cybersecurity')}>Cybersecurity</Button>
 <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('DataAnalysis')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('DataAnalysis')}>Data Analysis</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Datamining')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Datamining')}>Datamining</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('DataScience')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('DataScience')}>DataScience</Button>
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('WebDevelopment')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('WebDevelopment')}>Web Development</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('DigitalDesign')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('DigitalDesign')}>DigitalDesign</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('GameDevelopment')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('GameDevelopment')}>Game Development</Button>
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('MachineLearning')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('MachineLearning')}>Machine Learning</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('NetworkSecutiry')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('NetworkSecutiry')}>NetworkSecutiry</Button>
</VStack>
</ Box>
</>     
)}
</Flex>
</Flex>
  
{!isSmallScreen && (     
    <Flex>
     <Flex justifyContent="center">
<Box position="relative" display="inline-block">
  <Button 
    mt='90' 
    ml='110' 
    fontFamily={'Orbitron, sans-serif'}    
    border="2px solid rgb(135,206,250)"
    color={clickedTags.includes('Computers') ? "orange" : "rgb(135,206,250)"}
    fontSize="25px" 
    onClick={() => updateUserInterest('Computers')}
    _before={{
      content: '""',
      position: 'absolute',
      right: "-500px", // Adjust as needed to position the line correctly
      top: "50%", // Adjust to align with the middle of the button
      transform: "translateY(-50%)",
      width: "500px", // Length of the line
      height: "2px", // Thickness of the line
      backgroundColor: "rgb(135,206,250)", // Color of the line
    }}
  >
    Computers
  </Button>
</Box>
<VStack mt='120' position="absolute" left="-450px">
      {!isSmallScreen && <Image src='computers.png' boxSize="200px" />}
    </VStack>

<VStack mt='140' ml='-520'>
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('ArtificalIntelligence')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('ArtificalIntelligence')}>Artifical Intelligence</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('SoftwareEngineering')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('SoftwareEngineering')}>SoftwareEngineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes ('Coding')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Coding')}>Coding</Button>
</VStack>

<VStack mt='140' >
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('HardwareEngineering')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('HardwareEngineering')}>Hardware Engineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('DatabaseManagment')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('DatabaseManagment')}>Database Managment</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('Cybersecurity')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Cybersecurity')}>Cybersecurity</Button>
</VStack>

<VStack mt='140' >
 <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('DataAnalysis')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('DataAnalysis')}>Data Analysis</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('Datamining')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Datamining')}>Datamining</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('DataScience')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('DataScience')}>DataScience</Button>
</VStack>

<VStack mt='140' >
  <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('WebDevelopment')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('WebDevelopment')}>Web Development</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('DigitalDesign')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('DigitalDesign')}>DigitalDesign</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('GameDevelopment')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('GameDevelopment')}>Game Development</Button>
</VStack>

<VStack mt='140' >
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('MachineLearning')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('MachineLearning')}>Machine Learning</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('NetworkSecutiry')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('NetworkSecutiry')}>NetworkSecutiry</Button>
</VStack>

</Flex>
    </Flex>
)}
   
   {!isSmallScreen && (
    <Flex>
        <Flex justifyContent="center">
        <Box position="relative" display="inline-block">
  <Button 
    mt='90' 
    ml='140'   
    border="2px solid orange"
    fontFamily={'Orbitron, sans-serif'} 
    color={clickedTags.includes('Earth') ? "orange" : "rgb(135,206,250)"} 
    fontSize="25px" 
    onClick={() => updateUserInterest('Earth')}
    _after={{
      content: '""',
      position: 'absolute',
      left: "-980px", // Position the line to the right
      top: "50%", // Center vertically
      transform: "translateY(-50%) translateX(100%)", // Adjust to move the line out to the right from the button
      width: "490px", // Length of the line
      height: "2px", // Thickness of the line
      backgroundColor: "orange", // Color of the line
    }}
  >
    Earth
  </Button>
</Box>
        </Flex>
        <VStack mt='100' position="absolute" left="-450px">
            {!isSmallScreen && <Image src='earthscience.png' boxSize="200px" />}
        </VStack>
        <VStack mt='140' ml='-260'>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes('ClimateScience') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('ClimateScience')}>Climate Science</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes('EnviornmentalScience') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('EnviornmentalScience')}>Environmental Science</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes('Ecology') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Ecology')}>Ecology</Button>
        </VStack>
        <VStack mt='140' ml=''>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes('Geology') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Geology')}>Geology</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes('Meteorology') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Meteorology')}>Meteorology</Button>
        </VStack>
    </Flex>
)}

{isSmallScreen && (
    <Flex>
        <Flex justifyContent="center">
        <Button 
    mt='90' 
    ml='70'   
    border="2px solid orange"
    fontFamily={'Orbitron, sans-serif'} 
    color={clickedTags.includes('Earth') ? "orange" : "rgb(135,206,250)"} 
    fontSize="25px" 
    onClick={() => updateUserInterest('Earth')}
    _after={{
      content: '""',
      position: 'absolute',
      left: "-980px", // Position the line to the right
      top: "50%", // Center vertically
      transform: "translateY(-50%) translateX(100%)", // Adjust to move the line out to the right from the button
      width: "490px", // Length of the line
      height: "2px", // Thickness of the line
      backgroundColor: "orange", // Color of the line
    }}
  >Earth</Button>
        </Flex>
        <VStack mt='100' position="absolute" left="-1050px">
            {isSmallScreen && <Image src='earthscience.png' boxSize="200px" />}
        </VStack>
        <VStack mt='140' ml='-170'>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes('ClimateScience') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('ClimateScience')}>Climate Science</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes('EnvironmentalScience') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('EnvironmentalScience')}>Environmental Science</Button>
            <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes('Ecology') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Ecology')}>Ecology</Button>
            <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes('Geology') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Geology')}>Geology</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes('Meteorology') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Meteorology')}>Meteorology</Button>
        </VStack>
    </Flex>
)}

{isSmallScreen && (
  <>
<Button mt='90' ml='7' fontFamily={'Orbitron, sans-serif'} color={clickedTags.includes('Computers') ? "orange" : "rgb(135,206,250)"}
fontSize="25px" 
border="1px solid rgb(135,206,250)" width="70%" display="inline-block" 
onClick={() => updateUserInterest('Computers')}
_before={{
    content: '""',
    position: 'absolute',
    right: "-500px", // Adjust as needed to position the line correctly
    top: "50%", // Adjust to align with the middle of the button
    transform: "translateY(-50%)",
    width: "500px", // Length of the line
    height: "2px", // Thickness of the line
    backgroundColor: "rgb(135,206,250)", // Color of the line
  }} >
  Engineering
</Button>
    <Box
  display="flex"
  flexDirection="column" // Change to column for vertical layout
  overflowY="scroll" // Enable vertical scrolling
  p={4}
  whiteSpace="normal" // Adjusted for vertical scrolling
  marginLeft="-10px"
  width={{ base: '150%' }}
  height="400px" // Define a height to enable scrolling
>
      <Flex>
        <Flex justifyContent="center">
          <VStack mt='110' position="absolute" left="-450px">
            {!isSmallScreen && <Image src='engineering.png' boxSize="200px" />}
          </VStack>
          <VStack ml='' mt='0'>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('CivilEngineering') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('CivilEngineering')}>Civil Engineering</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('ElectricalEngineering') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('ElectricalEngineering')}>Electrical Engineering</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('Electronics') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Electronics')}>Electronics</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('Energy') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Energy')}>Energy</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('IndustrialEngineering') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('IndustrialEngineering')}>Industrial Engineering</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('MechanicalEngineering') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('MechanicalEngineering')}>Mechanical Engineering</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('Nanotechnology') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Nanotechnology')}>Nanotechnology</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('GeneticEngineering') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('GeneticEngineering')}>Genetic Engineering</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('PetroleumEngineering') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('PetroleumEngineering')}>Petroleum Engineering</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('StrucutralEngineering') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('StrucutralEngineering')}>Structural Engineering</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('Robotics') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Robotics')}>Robotics</Button>
            <Button fontFamily={'Orbitron, sans-serif'} bg='#333333' color={clickedTags.includes('RenewableEnergy') ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('RenewableEnergy')}>Renewable Energy</Button>
          </VStack>
        </Flex>
      </Flex>
    </Box>
  </>
)}

{!isSmallScreen && (
  <Flex>
        <Flex justifyContent="center">
        <Box position="relative" display="inline-block">
  <Button 
    mt='75' 
    ml='110' 
    fontFamily={'Orbitron, sans-serif'}    
    border="2px solid rgb(135,206,250)"
    color={clickedTags.includes('Engineering') ? "orange" : "rgb(135,206,250)"}
    fontSize="25px" 
    onClick={() => updateUserInterest('Engineering')}
    _before={{
      content: '""',
      position: 'absolute',
      right: "-500px", // Adjust as needed to position the line correctly
      top: "50%", // Adjust to align with the middle of the button
      transform: "translateY(-50%)",
      width: "500px", // Length of the line
      height: "2px", // Thickness of the line
      backgroundColor: "rgb(135,206,250)", // Color of the line
    }}
  >
    Engineering
  </Button>
</Box>
  <VStack mt='110' position="absolute" left="-450px">
      {!isSmallScreen && <Image src='engineering.png' boxSize="200px" />}
    </VStack>

<VStack ml='-425' mt='130'>
<Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('MechanicalEngineering')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('MechanicalEngineering')}>Mechanical Engineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('ElectricalEngineering')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('ElectricalEngineering')}>Electrical Engineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Electronics')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Electronics')}>Electronics</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Energy')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Energy')}>Energy</Button>
</VStack>

<VStack mt='130'>
 <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('IndustrialEngineering')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('IndustrialEngineering')}>Industrial Engineering</Button>
 <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('GeneticEngineering')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('GeneticEngineering')}>Genetic Engineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Nanotechnology')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Nanotechnology')}>Nanotechnology</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('CivilEngineering')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('CivilEngineering')}>Civil Engineering</Button>
</VStack>

<VStack mt='130'>
 <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('PetroleumEngineering')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('PetroleumEngineering')}>Petroleum Engineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('StrucutralEngineering')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('StrucutralEngineering')}>Strucutral Engineering</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('RenewableEnergy')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('RenewableEnergy')}>Renewable Energy</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Robotics')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Robotics')}>Robotics</Button>

</VStack>
    </Flex>
  </Flex>
)}

    <Flex>
     <Flex justifyContent="center">
     <Box position="relative" display="inline-block">
  <Button 
    mt='71' 
    ml={isSmallScreen ? '20' : '140'}
    border="2px solid orange"
    fontFamily={'Orbitron, sans-serif'} 
    color={clickedTags.includes('Math') ? "orange" : "rgb(135,206,250)"} 
    fontSize="25px" 
    onClick={() => updateUserInterest('Math')}
    _after={{
      content: '""',
      position: 'absolute',
      left: "-490px", // Corrected position to start the line right after the button
      top: "50%", // Center vertically
      transform: "translateY(-50%)", // Corrected to only center the line vertically
      width: "490px", // Length of the line
      height: "2px", // Thickness of the line
      backgroundColor: "orange", // Color of the line
    }}
  >
    Math
  </Button>
</Box>
    <VStack mt='79' position="absolute" left="-450px">
      {!isSmallScreen && <Image src='mathematics.png' boxSize="200px" />}
    </VStack>
    <VStack mt='120' ml='-110'>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Calculus')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Calculus')}>Calculus</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Geometry')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Geometry')}>Geometry</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Statistics')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Statistics')}>Statistics</Button>
    </VStack>
    </Flex>
    </Flex>

    <Flex>
    <Flex justifyContent="center">
<Box position="relative" display="inline-block">
  <Button 
    mt='50' 
    ml={isSmallScreen ? '45' : '120'}
    fontFamily={'Orbitron, sans-serif'}    
    border="2px solid rgb(135,206,250)"
    color={clickedTags.includes('Medicine') ? "orange" : "rgb(135,206,250)"}
    fontSize="25px" 
    onClick={() => updateUserInterest('Medicine')}
    _before={{
      content: '""',
      position: 'absolute',
      right: "-520px", // Adjust as needed to position the line correctly
      top: "50%", // Adjust to align with the middle of the button
      transform: "translateY(-50%)",
      width: "520px", // Length of the line
      height: "2px", // Thickness of the line
      backgroundColor: "rgb(135,206,250)", // Color of the line
    }}
  >
    Medicine
  </Button>
</Box>
      <VStack mt='79' position="absolute" left="-450px">
      {!isSmallScreen && <Image src='medicine.png' boxSize="200px" />}
    </VStack>
        <VStack mt='95' ml='-160'>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Pathology')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Pathology')}>Pathology</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('Immunology')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Immunology')}>Immunology</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Pharmacology')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Pharmacology')}>Pharmacology</Button>
        </VStack>
    </Flex>
    </Flex>

    <Flex>
        <Flex justifyContent="center">
        <Box position="relative" display="inline-block">
  <Button 
    mt='70' 
    ml={isSmallScreen ? '12' : '120'} 
    border="2px solid orange"
    fontFamily={'Orbitron, sans-serif'} 
    color={clickedTags.includes('Physics') ? "orange" : "rgb(135,206,250)"} 
    fontSize="25px" 
    onClick={() => updateUserInterest('Physics')}
    _after={{
      content: '""',
      position: 'absolute',
      left: "-470px", // Start the line right after the button
      top: "50%", // Center vertically
      transform: "translateY(-50%)", // Adjust to center the line vertically
      width: "470px", // Length of the line
      height: "2px", // Thickness of the line
      backgroundColor: "orange", // Color of the line
    }}
  >
    Physics
  </Button>
</Box>
    <VStack mt='120' position="absolute" left="-450px">
      {!isSmallScreen && <Image src='physics.png' boxSize="200px" />}
    </VStack>
        <VStack mt='129' ml='-160'>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('NuclearPhysics')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('NuclearPhysics')}>Nuclear Physics</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Plasma')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Plasma')}>Plasma</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Waves')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Waves')}>Waves</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Optics')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Optics')}>Optics</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Thermodynamics')  ? "orange" : "rgb(135,206,250)"} onClick={() => updateUserInterest('Thermodynamics')}>Thermodynamics</Button>
        </VStack>
        </Flex>
    </Flex>

    {!isSmallScreen && (
    <Flex>
    <Flex justifyContent="center">
    <Box position="relative" display="inline-block">
  <Button 
    mt='51' 
    ml='100' 
    fontFamily={'Orbitron, sans-serif'}    
    border="2px solid rgb(135,206,250)"
    color={clickedTags.includes('Miscellaneous') ? "orange" : "rgb(135,206,250)"}
    fontSize="25px" 
    onClick={() => updateUserInterest('Miscellaneous')}
    _before={{
      content: '""',
      position: 'absolute',
      right: "-470px", // Adjust as needed to position the line correctly
      top: "50%", // Adjust to align with the middle of the button
      transform: "translateY(-50%)",
      width: "470px", // Length of the line
      height: "2px", // Thickness of the line
      backgroundColor: "rgb(135,206,250)", // Color of the line
    }}
  >
    Miscellaneous
  </Button>
</Box>
    <VStack mt='120' position="absolute" left="-440px">
      {!isSmallScreen && <Image src='miscellaneous.png' boxSize="175px" />}
    </VStack>
        <VStack mt='100' ml='-350'>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('OrganicFarming')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('OrganicFarming')}>Organic Farming</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Anthropology')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Anthropology')}>Anthropology</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Archeology')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Archeology')}>Archeology</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Caligraphy') ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Caligraphy')}>Caligraphy</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Paleontology')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Paleontology')}>Paleontology</Button>
        </VStack>
<VStack mt='100' ml='-'>
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('ForensicScience')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('ForensicScience')}>Forensic Science</Button>
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('SpaceExploration') ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('SpaceExploration')}>Space Exploration</Button>
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Blacksmithing')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Blacksmithing')}>Blacksmithing</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Gunsmithing')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Gunsmithing')}>Gunsmithing</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Trades')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Trades')}>Trades</Button>
        </VStack>
        
        <VStack mt='100' ml='-'>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('BehavioralEconomics')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('BehavioralEconomics')}>Behavioral Economics</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('Entrepeneurship')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Entrepeneurship')}>Entrepeneurship</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Architecture')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Architecture')}>Architecture</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('3DPrinting')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('3DPrinting')}>3D Printing</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Plumbing')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Plumbing')}>Plumbing</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('Electrician') ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Electrician')}>Electrician</Button>
       
        </VStack>
        </Flex>
    </Flex>
    )}




    {isSmallScreen && (
<>
<Button 
  mt='50' 
  ml= '79'
  fontFamily={'Orbitron, sans-serif'}    
  border="2px solid rgb(135,206,250)"
  color={clickedTags.includes('Medicine') ? "orange" : "rgb(135,206,250)"}
  fontSize="25px" 
  onClick={() => updateUserInterest('Medicine')}
  position="relative" 
  _after={{
    content: '""',
    position: 'absolute',
    left: "100%",
    top: "50%", 
    transform: "translateY(-50%)",
    width: "100px", 
    height: "2px",
    backgroundColor: "rgb(135,206,250)", 
  }}
>
  Misc.
</Button>
        <Box
  display="flex"
  flexDirection="column" 
  overflowY="scroll" 
  p={4}
  whiteSpace="nowrap"
  marginLeft="0px"
  width={{ base: '100%' }}
    height="200px"
>
    <Flex>
    <Flex justifyContent="center">

    <VStack mt='-200' position="absolute" left="">
      {!isSmallScreen && <Image src='miscellaneous.png' boxSize="175px" />}
    </VStack>
        <VStack mt='' ml=''>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('SpaceExploration') ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('SpaceExploration')}>Space Exploration</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Anthropology')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Anthropology')}>Anthropology</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Archeology')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Archeology')}>Archeology</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('Blacksmithing')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Blacksmithing')}>Blacksmithing</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Paleontology')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Paleontology')}>Paleontology</Button>
  <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('ForensicScience')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('ForensicScience')}>Forensic Science</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Plumbing')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Plumbing')}>Plumbing</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Caligraphy') ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Caligraphy')}>Caligraphy</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('Gunsmithing')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Gunsmithing')}>Gunsmithing</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('OrganicFarming')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('OrganicFarming')}>Organic Farming</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333' color={clickedTags.includes ('Trades')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Trades')}>Trades</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Entrepeneurship')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Entrepeneurship')}>Entrepeneurship</Button>
        <Button fontFamily={'Orbitron, sans-serif'}bg='#333333'color={clickedTags.includes ('BehavioralEconomics')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('BehavioralEconomics')}>Behavioral Economics</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('3DPrinting')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('3DPrinting')}>3D Printing</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Architecture')  ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Architecture')}>Architecture</Button>
        <Button fontFamily={'Orbitron, sans-serif'} bg='#333333'color={clickedTags.includes ('Electrician') ? "orange" : "rgb(135,206,250)"}onClick={() => updateUserInterest('Electrician')}>Electrician</Button>
        </VStack>
        </Flex>
    </Flex>



    </Box>
    </>
    )}
 
    <hr style={{border: "2px solid orange", width: '190%', marginLeft: '-59.5%', marginTop: '15px'}} />

</div>
  </div>

</div>
</div>
    
  )
}

export default TagsPage

// Add Psychology,  "Clinical Psychology",     "Cognitive Psychology ", Developmental psychology, Educational Psychology
//Forensic Psychology Health Psychology Industrial-organizational Psychology   Neuropsychology
// Social Psychology Sports psychology Psychiatry


//Add to medicine Cardiology, Dermatology, Endocrinology, Gastroenterology, Hematology, Oncology
//Orthopedics Pediatrics  Pulmonology Radiology Rheumatology Dentistry  Neurosurgery 

// cars, Motorcycles, Planes, Helicopters, ATVs, Bicycles

// Economics, Cooking, Philosophy