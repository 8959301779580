
import React, { createContext, useContext, useState } from 'react';

const UserActionContext = createContext();

export const useUserAction = () => useContext(UserActionContext);

export const UserActionProvider = ({ children }) => {
  const [lastAction, setLastAction] = useState(null);

  return (
    <UserActionContext.Provider value={{ lastAction, setLastAction }}>
      {children}
    </UserActionContext.Provider>
  );
};

export default UserActionContext;