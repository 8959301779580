/*שָלוֹם לָךְ, מִרְיָם,
מְלֵאַת הַחֶסֶד,
ה׳ עִמָּךְ,
בְּרוּכָה אַתְּ בַּנָשִׁים
וּבָרוּךְ פְּרִי בִטְנֵךְ יֵשׁוּעַ,
מִרְיָם הַקְּדוֹשָׁה,
אֵם הָאֱלֹהִים,
הִתְפַּלְּלִי בַּעֲדֵנוּ, הַחוֹטְאִים,
עַתָּה וּבִשְׁעַת מוֹתֵנוּ. */


import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import { Tooltip, Flex, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, Button } from '@chakra-ui/react';
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import useShowToast from '../hooks/useShowToast'
import { Avatar } from '@chakra-ui/avatar'

function Search () {
  const [query, setQuery] = useState('');
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [searchAttempted, setSearchAttempted] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const showToast = useShowToast()
  
  const originalSuggestions = []; 

  const getSuggestions = async value => {
    const tags = value.split(',');
    const lastTag = tags[tags.length - 1].trim();
    const inputValue = lastTag.toLowerCase();
    const inputLength = inputValue.length;
  
    if (inputLength === 0) return [];
  
    try {
      const response = await fetch(`/api/search/${inputValue}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    
      const data = await response.json();
    
      const users = data.users.map(user => ({
        name: user.username,
        profilePic: user.profilePic, 
        type: 'user'
      }));
      const posts = data.posts.map(post => ({ name: post.content, type: 'post' }));
      return [...users, ...posts]; 
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const getSuggestionValue = suggestion => {
    return suggestion.name;
  };
  
  const renderSuggestion = suggestion => (
    <div style={{ display: 'flex', alignItems: 'center', color: '#4A5568' }}>
      <Avatar name={suggestion.name} src={suggestion.profilePic} size="sm" marginRight="10px" />
      {suggestion.name}
    </div>
  );

const inputProps = {
  placeholder: 'Search',
  value,
  onChange: (event, { newValue }) => {
    setValue(newValue);
    setQuery(newValue); 
  },
  style: {
    fontFamily: 'Orbitron, sans-serif',
    backgroundColor: 'black',
    color: 'white', 
    border: '1px solid white',
    borderRadius: '5px', 
    width: '400px', 
    height: '40px'
  }
};

  const onSuggestionsFetchRequested = async ({ value }) => {
    setSuggestions(await getSuggestions(value));
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderInputComponent = inputProps => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input {...inputProps} />
    </div>
  );

  const onSuggestionSelected = (event, { suggestion }) => {
    if (suggestion.type === 'user') {
      navigate(`/${suggestion.name}`);
    } else if (suggestion.type === 'post') {
      navigate(`/posts/${suggestion.name}`);
    }
    onClose(); 
  };


  const handleSearch = async (event) => {
    event.preventDefault();
    setSearchAttempted(true);
    setNotFound(false);
    if (!query) {
      console.error('Query is empty');
      return;
    }
    try {
      // First, try to search for a user profile
      let encodedQuery = encodeURIComponent(query);
      let response = await fetch(`/api/users/profile/${encodedQuery}`);
      console.log(`/api/users/profile/${encodedQuery}`);
      if (!response.ok && response.status !== 404) {
        // After attempting to fetch a user profile
        if (response.status === 400) {
          console.log("Query does not match any profiles, redirecting to generic search page.");
          navigate(`/search?query=${encodedQuery}`);
          onClose();
          return; // Exit the function early
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }
      let contentType = response.headers.get("content-type");
      if (response.ok && contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        if (data.username) {
          navigate(`/${data.username}`);
          onClose();
          return; // Exit the function if a user profile is found
        }
      }
      // If no user profile is found, try to search for posts
      response = await fetch(`/api/posts/findpost/${encodedQuery}`);
      console.log(`/api/posts/findpost/${encodedQuery}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        if (data.posts && data.posts.length > 0) {
          // Handle the display of found posts
          console.log("Posts found:", data.posts);
          // navigate to posts display page or update state to display posts
        } else {
          // If no specific results are found, navigate to a generic search page
          navigate(`/search?query=${encodedQuery}`);
          onClose();
        }
      } else {
        throw new Error(`Unexpected content type: ${contentType}`);
      }
    } catch (error) {
      console.error(error);
      setNotFound(true);
      showToast("Not found");
      // As a fallback, navigate to a generic search page if an error occurs
      navigate(`/search?query=${encodeURIComponent(query)}`);
      onClose();
    }
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event);
    }
  };

  const handleButtonClick = () => {
   
    const mockEvent = {
      preventDefault: () => {}
    };
  
    handleSearch(mockEvent);
  };

  return (
    <>
      <Tooltip
        hasArrow
        label={"Search"}
        placement='right'
        ml={1}
        openDelay={500}
        display={{ base: "block", md: "none" }}
        fontFamily={"'Orbitron', sans-serif"}
      >
        <Flex
          alignItems={"center"}
          gap={4}
          _hover={{ borderColor: 'yellow.400', borderWidth: '1px' }}
          borderRadius={6}
          p={2}
          w={{ base: 10, md: "full" }}
          justifyContent={{ base: "center", md: "flex-start" }}
          onClick={onOpen}
        >
          <Box 
            display="block"
            fontFamily={"'Orbitron', sans-serif"} 
            color="#8d8d8d"
            css={{
              textShadow: "0 0 2px yellow.400, 0 0 3px yellow.400, 0 0 4px yellow.400",
              mixBlendMode: "difference",
            }}
          >
            Search
          </Box>
        </Flex>
      </Tooltip>
    
      <Modal
        isOpen = {isOpen} onClose={onClose} motionPreset='slideInLeft'>
        <ModalOverlay />
        <ModalContent bg={'black'} border={'1px solid gray'} maxW={'400'} fontFamily={'Orbitron, sans-serif'}>
          <ModalHeader color={"rgb(135, 206, 250)"}>Search</ModalHeader>
          <ModalCloseButton color="red.500" />
          <ModalBody pb={6} position="relative">
  <form onSubmit={handleSearch} style={{ marginRight: '25px' }}>
    <FormControl>
      <FormLabel></FormLabel>

      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onSuggestionSelected} 
      />
    </FormControl>
    <Flex w={'full'} justifyContent={'flex-end'} >
    </Flex>
  </form>
  <Button 
  type='submit' 
  position="absolute" 
  top="20px" 
  left="355px" 
  size='sm' 
  isLoading={loading}
  _hover={{ borderColor: 'red.500', borderWidth: '1px' }}
  onClick={handleButtonClick} 
>
  <FaRegArrowAltCircleRight />
</Button>

</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Search;

 