import React from 'react'
import { Flex, Image, Link, Text } from '@chakra-ui/react';

const FundedCard = ({ img, name, link, description }) => {
  return (
    <Flex 
      direction="column" 
      border="2px" 
      borderColor="blue.500" 
      p={4} 
      borderRadius="md" 
      color="rgb(135,206,250)"
      align="center"
    >
  <Image 
    src={img} 
    alt="Funded Card Image" 
    height="150px" 
          width="200px"
    maxWidth="200px"  
    
    mb={4} 
  />
      <Link href={link} isExternal >
      <Text 
  mt={4} 
  color="orange" 
  fontFamily={'Orbitron, sans-serif'} 
  border="2px solid orange" 
  borderRadius="10px" 
  padding="10px" 
  display="inline-block"
>{name}</Text>
      </Link>
      <Text mt={1} color="white" fontFamily={'Orbitron, sans-serif'}>{description}</Text>
    </Flex>
  );
};

export default FundedCard;