/* Χαῖρε Μαρία κεχαριτωμένη ὁ Κύριος μετά σοῦ Ἐυλογημένη σὺ ἐν γυναιξὶ, καὶ εὐλογημένος ὁ καρπὸς τῆς κοιλίας 
σοῦ Ἰησούς Ἁγία Μαρία, μῆτερ θεοῦ προσεύχου ὑπέρ ἡμῶν τῶν ἁμαρτωλῶν, νῦν καὶ ἐν τῇ ὥρᾳ τοῦ θανάτου ἡμῶν. */

import {Box,  Button, Flex, Spinner, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import useShowToast from '../hooks/useShowToast'
import Post from '../components/Post'
import { useRecoilState } from 'recoil'
import postsAtom from '../atoms/postsAtom'
import SuggestedUsers from '../components/Suggested/SuggestedUsers'
import Sidebar from '../Sidebar/Sidebar'
import SidebarSuggested from '../components/Suggested/SidebarSuggested'
import { useMediaQuery } from "@chakra-ui/react";
import { FaPeopleGroup } from "react-icons/fa6";
import { useDisclosure } from '@chakra-ui/react';


const HomePage = () => {
  const [posts, setPosts] = useRecoilState(postsAtom)
  const [ loading, setLoading ] = useState(true)
  const showToast = useShowToast()
  const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
  const [isIphone] = useMediaQuery([
    "(min-device-width: 375px) and (max-device-width: 430px) and (min-device-height: 812px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3)"
  ]);
  const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    const getFeedPosts = async() => {
      setLoading(true)
      setPosts([])
      try {
        const res = await fetch('/api/posts/feed');
        const data = await res.json();
        console.log(data)
        setPosts(data)      
      } catch (error) {
        showToast("Error", error.message, "error")
      } finally{
        setLoading(false)
      }
    }
    getFeedPosts()
  }, [showToast, setPosts])


  return (
<Flex>
{/* This is the Right */}
<Box flex={100} ml={isIpadEsq ? -150 : isIphone ? -75 : isSmallScreen ? -59.5 : -150}>
 {!loading && posts.length === 0 && 

<Flex height="1vh" ml={225}>
<Text 
  color={'yellow.400'} 
  fontFamily={'Orbitron, sans-serif'} 
  fontSize="xl"
  marginLeft={isSmallScreen ? '-180' : 'auto'} 
>
  Follow some Users!
</Text>
</Flex>}
 {loading && (
  <Flex justify='center'>
    <Spinner size="xl" />
  </Flex>
 )}

{posts.map((post) => (
  <Box key={post._id} className="post">
    <Post post={post} postedBy={post.postedBy}/>
  </Box>
))}
 
 </Box>

<Box flex={20} >
  <SidebarSuggested />
</Box>
</Flex>
  )
}

export default HomePage