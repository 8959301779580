
/* Je vous salue, Marie pleine de grâce le Seigneur est avec vous. Vous êtes bénie entre toutes les femmes 
et Jésus le fruit de vos entrailles, est béni. Sainte Marie, Mère de Dieu, priez pour nous pauvres pécheurs
maintenant et à l’heure de notre mort*/

import React from 'react';
import { ChakraProvider, extendTheme, Box, Flex, Image, Tooltip, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { mode } from "@chakra-ui/theme-tools";
import HiveMindSearch from '../HiveMindSearch';

const HiveMindEarth = () => {
  const styles = {
    global: (props) => ({
      body: {
        bg: 'black',
        color: mode('gray.800', 'whiteAlpha.900')(props),
      },
    }),
  };

  const theme = extendTheme({ styles });

  const center = useBreakpointValue({
    base: "10%", //iphone
    sm: "10%",
    md: "31%", //ipad Mini
    lg: "39%",
    xl: "39%"
  });

  const left = useBreakpointValue({
    base: "15%",
    sm: "15%",
    md: "30%",
    lg: "40%",
    xl: "32%"
  });

  const right = useBreakpointValue({
    base: "60%",
    sm: "65%",
    md: "60%",
    lg: "55%",
    xl: "59%"
  });

  const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
  const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
  const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");

  const adjustedCenter = isIphoneXR ? "15%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : center;
  const adjustedLeft = isIphoneXR ? "20%" : isIphone14ProMax ? "20%" : isIpadPro ? "30%" : left;
  const adjustedRight = isIphoneXR ? "60%" : isIphone14ProMax ? "60%" : isIpadPro ? "65%" : right;

  return (
    <ChakraProvider theme={theme}>
      {/* Center */}
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="100%" 
        left={adjustedCenter}
      >
        <Box mt={-100}><HiveMindSearch /></Box>
        <Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px"> Earth</Box>

        <Tooltip label="Climate Science" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/ClimateScience">
              <Image src="climatescience.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Environmental Science" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/EnvironmentalScience">
              <Image src="environmentalscience.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Ecology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Ecology">
              <Image src="ecology.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>

      {/* Left */}
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top={isIpadPro ? "120%" : "115%"} 
        left={adjustedLeft}
      >
        <Tooltip label="Geology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Geology">
              <Image src="geology.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>

      {/* Right */}
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top={isIpadPro ? "200%" : "165%"} 
        left={adjustedRight}
      >
        <Tooltip label="Meteorology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Meteorology">
              <Image src="meteorology.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>
    </ChakraProvider>
  );
}

export default HiveMindEarth;