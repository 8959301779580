 /* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/

import { Box } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/layout'
import Arrow from './Arrow'

const PostFooter = ({post}) => {
  return (
    <Box>
    <Text fontSize={20} color={"gray.500"}>
    <Text as="span" color={"white"} fontWeight={"bold"}></Text>
    </Text>
    <Arrow post={post}/>
</Box>
  )
}

export default PostFooter