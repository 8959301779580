/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/
import React, { useState } from 'react';
import { IconButton, Tooltip, Collapse, Box, Text, Flex, Button } from '@chakra-ui/react';
import { ChevronRightIcon, ChevronLeftIcon, RepeatIcon } from '@chakra-ui/icons';
import SuggestedUsers from './SuggestedUsers'; 
import { useMediaQuery } from "@chakra-ui/react";

const SidebarSuggested = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); 
  const [isIphone] = useMediaQuery([
    "(min-device-width: 375px) and (max-device-width: 430px) and (min-device-height: 812px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3)"
  ]);
  const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
  const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
  const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");
  const refreshUsers = () => {
    setRefreshKey(prevKey => prevKey + 1); 
  };

  return (
    <aside>
<Tooltip backgroundColor="black" label={<><div>Suggested</div><div>Users</div></>} color="gray.500" fontSize="md">
<IconButton
backgroundColor={isIphone || isSmallScreen ? "black" : "initial"} 
      aria-label="Toggle sidebar"
      icon={isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      onClick={() => setIsOpen(!isOpen)}
      position="fixed"
      top="50%"
      right={0}
      color="rgb(135, 206, 250)"
      _hover={{ borderColor: "yellow.400", borderWidth: "1px" }}
      zIndex={isSmallScreen || isIphone ? 9999 : undefined}
    />
  </Tooltip>

  <Collapse in={isOpen}>
  {! isIphone && !isIpadEsq && !isMac && (
  <Button
    onClick={refreshUsers} 
    position={"fixed"}
    top={"25%"}
    right={110}
    colorScheme="blue"
    aria-label="Refresh users"
  >
    <Text fontFamily={'Orbitron, sans-serif'}> Refresh</Text>
  </Button>
)}
<Box height={"65vh"} border={"1px solid"} borderRadius={'lg'} borderColor={"gray.400"} w={230} position={"fixed"}
  top={isSmallScreen ? "142px" : (isIpadEsq ? "100px" : "0")} 
  right={{ base: isIphone ? "10px" : "20px", md: "50px" }} px={{ base: 10, md: 10 }} mt={{ base: "20px", md: "200px" }}
  backgroundColor={isIphone || isSmallScreen ? 'black' : (isIpadEsq ? 'black' : 'transparent')}
  zIndex={isIpadEsq ? 999 : 'auto'} 
>
  <Text fontSize={20} fontWeight={"bold"} fontFamily={"'Orbitron', sans-serif"} color=""
      css={{WebkitTextStroke: "1px rgb(135, 206, 250)", }}>
  </Text>
  {(isMac || isIpadEsq || isIphone || isSmallScreen) && (
    <Button
    onClick={refreshUsers} 
    position={"relative"}
    top={isMac ? "35%": isIpadEsq ? '35%' : isIphone ? '66%' : isSmallScreen ? "2%" : "32%"}
    right={isMac ? '8%' : isIpadEsq ? '15%' : isIphone ? '-50px' : isSmallScreen ? "-20px" : "110px"}
    colorScheme="blue"
    aria-label="Refresh users"
  >
    <Text fontFamily={'Orbitron, sans-serif'}> Refresh</Text>
  </Button>
)}
  <Flex direction={'column'} gap={4} mt={isIpadEsq ? 20 : ''}>
    <SuggestedUsers key={refreshKey} /> 
  </Flex>
</Box>
  </Collapse>
</aside>
  );
};

export default SidebarSuggested;