/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/

import { Box, Button, Input, Text } from "@chakra-ui/react";
import useShowToast from "../hooks/useShowToast";
import useLogout from "../hooks/useLogout";
import { useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";


export const SettingsPage = () => {
	const showToast = useShowToast();
	const logout = useLogout();
  const [isLargeScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
  const [currentUser, setCurrentUser] = useState(null);


    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

	const freezeAccount = async () => {
		if (!window.confirm("Are you sure you want to freeze your account?")) return;

		try {
			const res = await fetch("/api/users/freeze", {
				method: "PUT",
				headers: { "Content-Type": "application/json" },
			});
			const data = await res.json();

			if (data.error) {
				return showToast("Error", data.error, "error");
			}
			if (data.success) {
				await logout();
				showToast("Success", "Your account has been frozen", "success");
			}
		} catch (error) {
			showToast("Error", error.message, "error");
		}
	};

    const deleteAccount = async () => {
        if (!window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) return;

        try {
            const res = await fetch("/api/users/delete", {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
            });
            const data = await res.json();

            if (data.error) {
                return showToast("Error", data.error, "error");
            }
            if (data.success) {
                await logout();
                showToast("Success", "Your account has been deleted", "success");
            }
        } catch (error) {
            showToast("Error", error.message, "error");
        }
    };

    const handleSendEmail = async () => {
      try {
        const userStr = localStorage.getItem('user-ark'); 
        const user = JSON.parse(userStr);
    
        if (!user) {
          console.error('No user info found in localStorage');
          return;
        }
    
        const { _id: userId, name, email } = user;
        console.log('Retrieved user info from localStorage:', userId, name, email);
    
        const response = await fetch('api/users/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: inputValue, // Ensure inputValue is defined and holds the message content
            userId: userId,
            name: name,
            email: email,
          }),
        });
    
        const data = await response.json();
        if (data.success) {
          console.log('Email sent successfully');
        } else {
          console.error('Failed to send email');
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };
  const handleButtonClick = () => {
    handleSendEmail(); // Optionally call handleSendEmail here
};


    return (
        <>
          {/*  <Text my={1} fontWeight={"bold"}>
                Freeze Your Account
            </Text>
            <Text my={1}>You can unfreeze your account anytime by logging in.</Text>
            <Button size={"sm"} colorScheme='red' onClick={freezeAccount}>
                Freeze
            </Button> */}


<Box>
    <Text my={1} fontWeight={"bold"} fontFamily={'Orbitron, sans-serif'} color="orange">
    For Support or Recommendations, email felix@the-ark.blue</Text>
    </Box>

<Box mt='20' borderWidth="1px" borderColor="rgb(135,206,250)" borderRadius="lg" p={4} ml={isLargeScreen ? -75 : 0}>
      <Text my={1} fontWeight={"bold"} fontFamily={'Orbitron, sans-serif'} color="gray.300">
        Delete Your Account
      </Text>
      <Button size={"sm"} border='1px' borderColor={'red.400'}  bg='black' color='red.400' fontFamily={'Orbitron, sans-serif'} onClick={deleteAccount}>
        Delete Account
      </Button>
    </Box>

{/* }
    <Box borderWidth="1px" borderColor="yellow.200" borderRadius="lg" p={4} ml={isLargeScreen ? -75 : 0}>
      <Text my={1} fontWeight={"bold"} fontFamily={'Orbitron, sans-serif'} color="gray.300">
        Send Your Recommendations to Improve the Site!
      </Text>
      <Input       fontFamily={'Orbitron, sans-serif'} 
      color="white" value={inputValue} onChange={handleInputChange} maxLength={500} mb={2} />
      <Button size={"sm"} border='1px' borderColor={'green.400'} onClick={handleSendEmail}  bg='black' color='green.400' fontFamily={'Orbitron, sans-serif'} onClick={handleButtonClick}>
        Send
      </Button>
    </Box> */}


 
        </>
    );
};

