/* Χαῖρε Μαρία κεχαριτωμένη ὁ Κύριος μετά σοῦ Ἐυλογημένη σὺ ἐν γυναιξὶ, καὶ εὐλογημένος ὁ καρπὸς τῆς κοιλίας 
σοῦ Ἰησούς Ἁγία Μαρία, μῆτερ θεοῦ προσεύχου ὑπέρ ἡμῶν τῶν ἁμαρτωλῶν, νῦν καὶ ἐν τῇ ὥρᾳ τοῦ θανάτου ἡμῶν. */


import React from 'react';
import { ChakraProvider, extendTheme, Box, Flex, Image, Tooltip, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { mode } from "@chakra-ui/theme-tools";
import HiveMindSearch from '../HiveMindSearch';

const HiveMindEngineering = () => {
  const styles = {
    global: (props) => ({
      body: {
        bg: 'black',
        color: mode('gray.800', 'whiteAlpha.900')(props),
      },
    }),
  };

  const theme = extendTheme({ styles });

  const center = useBreakpointValue({
    base: "10%", //iphone
    sm: "10%",
    md: "31%", //ipad Mini
    lg: "39%",
    xl: "39%"
  });

  const left = useBreakpointValue({
    base: "15%",
    sm: "15%",
    md: "30%",
    lg: "40%",
    xl: "32%"
  });

  const right = useBreakpointValue({
    base: "60%",
    sm: "65%",
    md: "60%",
    lg: "55%",
    xl: "59%"
  });

  const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
  const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
  const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");
  const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");

  const adjustedCenter = isIphoneXR ? "15%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : center;
  const adjustedLeft = isIphoneXR ? "20%" : isIphone14ProMax ? "20%" : isIpadPro ? "30%" : left;
  const adjustedRight = isIphoneXR ? "60%" : isIphone14ProMax ? "60%" : isIpadPro ? "65%" : right;

  return (
    <ChakraProvider theme={theme}>
      {/* Center */}
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="100%" 
        left={adjustedCenter}
      >
        <Box mt={-100}><HiveMindSearch /></Box>
        <Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px"> Engineering</Box>

        <Tooltip label="Civil Engineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/CivilEngineering">
              <Image src="civilengineering.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Electrical Engineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/ElectricalEngineering">
              <Image src="electricalengineering.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Electronics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Electronics">
              <Image src="electronics.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Energy" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Energy">
              <Image src="energy.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Industrial Engineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/IndustrialEngineering">
              <Image src="industrialengineering.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>

      {/* Left 1 */}
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="120%" 
        left={adjustedLeft}
      >
        <Tooltip label="Mechanical Engineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/MechanicalEngineering">
              <Image src="mechanicalengineering.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Nanotechnology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Nanotechnology">
              <Image src="nanotech.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Genetic Engineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/GeneticEngineering">
              <Image src="geneticengineering.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>

      {/* Right 1 */}
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="120%" 
        left={adjustedRight}
      >
        <Tooltip label="Petroleum Engineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/PetroleumEngineering">
              <Image src="petroleumengineering.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Structural Engineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/StructuralEngineering">
              <Image src="structuralengeneering.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Robotics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/Robotics">
              <Image src="robotics.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>

        <Tooltip label="Renewable Energy" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/RenewableEnergy">
              <Image src="renewableenergy.png" boxSize={isIpadPro ? "150px" : "100px"} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>
    </ChakraProvider>
  );
}

export default HiveMindEngineering;