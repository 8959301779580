/* Χαῖρε Μαρία κεχαριτωμένη ὁ Κύριος μετά σοῦ Ἐυλογημένη σὺ ἐν γυναιξὶ, καὶ εὐλογημένος ὁ καρπὸς τῆς κοιλίας 
σοῦ Ἰησούς Ἁγία Μαρία, μῆτερ θεοῦ προσεύχου ὑπέρ ἡμῶν τῶν ἁμαρτωλῶν, νῦν καὶ ἐν τῇ ὥρᾳ τοῦ θανάτου ἡμῶν. */

import React from 'react';
import HiveMindHeader from '../components/HiveMindHeader';

import { Link as RouterLink } from 'react-router-dom';
import HiveMindBiology from '../components/HiveMind/HiveMindBiology';
import HiveMindPhysics from '../components/HiveMind/HiveMindPhysics';
import HiveMindMisc from '../components/HiveMind/HiveMindMisc';
import HiveMindComputer from '../components/HiveMind/HiveMindComputer';
import HiveMindChemistry from '../components/HiveMind/HiveMindChemistry';
import HiveMindMath from '../components/HiveMind/HiveMindMath';
import { Link } from 'react-router-dom';
import HiveMindSearch from '../components/HiveMindSearch';

import { ChakraProvider, extendTheme, Box, Button, Flex, Image, Tooltip, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';

import { mode } from "@chakra-ui/theme-tools";


const HiveMindHome = () => {
  const styles = {
    global: (props) => ({
      body: {
        bg: 'black',
        color: mode('gray.800', 'whiteAlpha.900')(props),
      },
    }),
  };

  const theme = extendTheme({ styles });

  const center = useBreakpointValue({
    base: "10%", //iphone
    sm: "10%",
    md: "31%", //ipad Mini
    lg: "39%",
    xl: "39%"
  });

  const left = useBreakpointValue({
    base: "0%",
    sm: "15%",
    md: "22%",
    lg: "35%",
    xl: "32%"
  });

  const right = useBreakpointValue({
    base: "60%",
    sm: "65%",
    md: "60%",
    lg: "55%",
    xl: "59%"
  });

  const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
  const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
  const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");
  const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");

  const adjustedCenter = isIphoneXR ? "15%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : center;
  const adjustedLeft = isIphoneXR ? "5%" : isIphone14ProMax ? "5%" : isIpadPro ? "27%" : left;
  const adjustedRight = isIphoneXR ? "60%" : isIphone14ProMax ? "60%" : isIpadPro ? "63%" : right;

  return (
    <ChakraProvider theme={theme}>
      <Flex       direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="70%" 
        left={adjustedCenter}>

   
      
      <HiveMindSearch />
        <RouterLink to="/HiveMindBiology">
          <Tooltip label="Biology" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
              <Image src="biology.png" boxSize={isIpadPro ? "200px" : "150px"} />
            </Box>
          </Tooltip>
        </RouterLink>

        <RouterLink to="/HiveMindChemistry">
          <Tooltip label="Chemistry" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
              <Image src="chemistry.png" boxSize={isIpadPro ? "200px" : "150px"} />
            </Box>
          </Tooltip>
        </RouterLink>

        <RouterLink to="/HiveMindComputer">
          <Tooltip label="Computers" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
              <Image src="computers.png" boxSize={isIpadPro ? "200px" : "150px"} />
            </Box>
          </Tooltip>
        </RouterLink>

        <RouterLink to="/HiveMindPhysics">
              <Tooltip label="Physics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
                <Box>
                  <Image src="physics.png" boxSize={isIpadPro ? "200px" : "150px"} />
                </Box>
              </Tooltip>
            </RouterLink>

      </Flex>

      {/* Right */}
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="120%" 
        left={adjustedRight}
      >
        <RouterLink to="/HiveMindEarth">
          <Tooltip label="Earth Science" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
              <Image src="earthscience.png" boxSize={isIpadPro ? "200px" : "150px"} />
            </Box>
          </Tooltip>
        </RouterLink>

        <RouterLink to="/HiveMindEngineering">
          <Tooltip label="Engineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
              <Image src="engineering.png" boxSize={isIpadPro ? "200px" : "150px"} />
            </Box>
          </Tooltip>
        </RouterLink>

        <RouterLink to="/HiveMindMisc">
              <Tooltip label="Miscellaneous" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
                <Box>
                  <Image src="miscellaneous.png" boxSize={isIpadPro ? "200px" : "150px"} />
                </Box>
              </Tooltip>
            </RouterLink>
      </Flex>

      {/* Left */}
      <Flex 
         direction="column" 
         align="center" 
         justify="center" 
         position="absolute" 
         top="120%" 
         left={adjustedLeft}
      >
        <RouterLink to="/HiveMindMath">
          <Tooltip label="Mathematics" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
              <Image src="mathematics.png" boxSize={isIpadPro ? "200px" : "150px"} />
            </Box>
          </Tooltip>
        </RouterLink>

        <RouterLink to="/HiveMindMedicine">
          <Tooltip label="Medicine" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
            <Box>
              <Image src="medicine.png" boxSize={isIpadPro ? "200px" : "150px"} />
            </Box>
          </Tooltip>
        </RouterLink>
      </Flex>


    </ChakraProvider>
  );
};

export default HiveMindHome;