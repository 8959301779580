// This is the page that you click on it and zooms in
/* Gegrüßet seist du, Maria, voll der Gnade der Herr ist mit dir. Du bist gebenedeit unter den Frauen,
und gebenedeit ist die Frucht deines Leibes, Jesus Heilige Maria, Mutter Gottes bitte für uns Sünder
jetzt und in der Stunde unseres Todes Amen
*/

import { Avatar, Box, Button, Divider, Flex, Image, Spinner, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import Actions from '../components/Actions'
import Comment from '../components/Comment'
import useGetUserProfile from '../hooks/useGetUserProfile'
import useShowToast from '../hooks/useShowToast'
import { useParams, useNavigate } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'
import { useRecoilState, useRecoilValue } from 'recoil'
import userAtom from '../atoms/userAtom'
import { DeleteIcon } from '@chakra-ui/icons'
import postsAtom from '../atoms/postsAtom'
import ReactPlayer from 'react-player'
import PostFooter from '../components/PostFooter'
import { Link } from 'react-router-dom'
import Hls from 'hls.js';


const PostPage = () => {
  const { user, loading } = useGetUserProfile()
  const [posts, setPosts] = useRecoilState(postsAtom)
  const showToast = useShowToast(postsAtom)
  const {pid} = useParams()
  const currentUser = useRecoilValue(userAtom)
  const navigate = useNavigate()
  const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
  const [isLoading, setIsLoading] = useState(true);
  const currentPost = posts[0]
  const [videoUrl, setVideoUrl] = useState(currentPost?.img);
  const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 

  useEffect(() => {
    console.log('Component rendered');
    if (currentPost?.img !== videoUrl) {
      setVideoUrl(currentPost?.img);
    }
  }, [currentPost, videoUrl]);

  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted
    console.log('useEffect called');
    const getPost = async () => {
      console.log('Fetching post data');
      setPosts([]);
      try {
        const res = await fetch(`/api/posts/${pid}`);
        const data = await res.json();
        if (data.error) {
          showToast("Error", data.error, "error");
          return;
        }
        if (isMounted) {
          console.log(data);
          setPosts([data]);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          showToast("Error", error.message, "error");
        }
      }
    };
    getPost();

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, [pid]);


  const isVideo = (url) => {
    const videoExtensions = ['mp4', 'webm', 'ogg', 'mov'];
    const extension = url.split('.').pop();
    return videoExtensions.includes(extension);
  };


const handleDeletePost = async() => {
  try {
      if(!window.confirm("Are you sure you want to delete this post?")) return;
      const res = await fetch(`/api/posts/${currentPost._id}`, {
          method: "DELETE",
      })
      const data = await res.json()
      if (data.error) {
          showToast("Error", data.error, "error"); return;
      }
      showToast("Success", "Post deleted", "success")
      navigate(`/${user.username}`)
  } catch (error) {
      showToast("Error", error.message, "error")
  }
}

  if(!user && loading) return 
  <Flex>
    <Spinner size="xl" />
  </ Flex>

if(!currentPost) return null;

const isBase64Video = (url) => /^data:video\/[^;]+;base64,/.test(url);

const convertBase64ToBlobUrl = (base64) => {
    const parts = base64.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
  
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
  
    const blob = new Blob([uInt8Array], { type: contentType });
    return URL.createObjectURL(blob);
  };


  return (
    <>
      <Flex marginLeft={isIpadEsq ? '-140' : isSmallScreen ? '-70' : 'auto'}>
        <Flex w={'full'} alignItems={'center'} gap={3}>
          <Avatar src={user.profilePic} size={'md'} name='CPN'  
          onClick={(e) => {e.preventDefault(); navigate(`/${user.username}`)}} />
          <Flex>
            <Text fontSize={'sm'} fontWeight={'bold'} color={"rgb(135, 206, 250)"} fontFamily={'Orbitron, sans-serif'}>{user.username}</Text>
          </Flex>
        </Flex>
        <Flex gap={4} alignItems={'center'}>
          <Text fontSize={'sm'} color={'gray.light'}></Text>
          <Flex gap={4} alignItems={'center'} >
            <Text fontSize={'xs'} width={36} textAlign={'right'}
            color={"rgb(135, 206, 250)"} fontFamily={'Orbitron, sans-serif'}>
                {formatDistanceToNow(new Date(currentPost.createdAt))} ago</Text>
               {currentUser?._id === user._id &&  
               <DeleteIcon size={'20'} cursor={'pointer'} onClick={handleDeletePost} />}
            </Flex>
        </Flex>
      </Flex> 
      <Text my={3} color='white' fontFamily={'Orbitron, sans-serif'} 
     marginLeft={isIpadEsq ? '-20' : isSmallScreen ? '-30' : 'auto'}>{currentPost.text}</Text>
      {currentPost.img && (
        <Box
          borderRadius={6}
          overflow={'hidden'}
          border={'1px solid white'}
          borderColor={'gray.light'}
          marginLeft={isIpadEsq ? '-90px' : 'auto'}
        >
          {isBase64Video(currentPost.img) ? (
            <ReactPlayer
              url={convertBase64ToBlobUrl(currentPost.img)}
              width='100%'
              height='100%'
              controls
              config={{
                file: {
                  hlsOptions: {
                    startLevel: 0,
                    capLevelToPlayerSize: true,
                    debug: false,
                    defaultAudioCodec: 'mp4a.40.2',
                    maxBufferLength: 100,
                    maxMaxBufferLength: 150,
                    enableWorker: true,
                    enableSoftwareAES: true,
                  },
                  hlsVersion: Hls.version,
                },
              }}
              onError={e => {
                console.error('ReactPlayer error', e);
                console.error('Error details:', e.target.error);
              }}
            />
          ) : isVideo(currentPost.img) ? (
            <ReactPlayer
              url={currentPost.img.startsWith('http') ? currentPost.img : `https://${currentPost.img}`}
              width='100%'
              height='100%'
              controls
              config={{
                file: {
                  hlsOptions: {
                    startLevel: 0,
                    capLevelToPlayerSize: true,
                    debug: true,
                    defaultAudioCodec: 'mp4a.40.2',
                    maxBufferLength: 100,
                    maxMaxBufferLength: 150,
                    enableWorker: true,
                    enableSoftwareAES: true,
                  },
                  hlsVersion: Hls.version,
                },
              }}
              onError={e => {
                console.error('ReactPlayer error', e);
                console.error('Error details:', e.target.error);
              }}
            />
          ) : (
            <Image src={currentPost.img} alt={currentPost.title} w={'full'}/>
          )}
        </Box>
      )}
            <Text fontSize={'sm'} color={"orange"} fontFamily={'Orbitron, sans-serif'}
              marginLeft={isIpadEsq ? '-79' : isSmallScreen ? '-30' : 'auto'}
          >
    {currentPost.views || 0} views
</Text>
      <Flex gap={3} my={3} marginLeft={isIpadEsq ? '-90px' : isSmallScreen ? '-30px' : 'auto'}>
        <PostFooter post={currentPost}/>
      </Flex>

    </>
  )
  }
  
  export default PostPage

