import React from 'react'
import { Avatar, Box, Button, Flex, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
ModalBody, ModalFooter, useDisclosure, Grid
 } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { ChakraProvider, extendTheme, Image, Tooltip } from '@chakra-ui/react';
import { mode } from "@chakra-ui/theme-tools";
import { useBreakpointValue, useMediaQuery } from "@chakra-ui/react";


const Scholarship = ({name, prize, description, user1, user2, user3, user4, user5,
    school1, school2, school3, school4, school5, Instructions, Avatar1, Avatar2, Avatar3, Avatar4, Avatar5,
    score1, score2, score3, score4, score5, username1, username2, username3, username4, username5
}) => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const finalRef = React.useRef(null)

  

  const users = useBreakpointValue({
    base: "0%", //iphone
    sm: "10%",
    md: "0%", //ipad Mini
    lg: "0%",
    xl: "39%"
  });

  const details = useBreakpointValue({
    base: "0%", //iphone
    sm: "10%",
    md: "0%", //ipad Mini
    lg: "0%",
    xl: "39%"
  });

  //content is the flex border
  const content = useBreakpointValue({
    base: "-10%", //iphone
    sm: "10%",
    md: "-50%", //ipad Mini
    lg: "-30%",
    xl: "39%"
  });


  const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
  const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
  const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");

console.log("isIphoneXR:", isIphoneXR);
console.log("isIphone14ProMax:", isIphone14ProMax);


const adjustedUsers = isIphoneXR ? "0%" : isIphone14ProMax ? "0%" : isIpadPro ? "0%" : users;
const adjustedContent = isIphoneXR ? "-5%" : isIphone14ProMax ? "-5%" : isIpadPro ? "-30%" : content;
const adjustedDetails = isIphoneXR ? "0%" : isIphone14ProMax ? "0%" : isIpadPro ? "0%" : details;

const isSmallScreen = useBreakpointValue({ base: true, sm: true, md: false, lg: false, xl: false });

const ppl = [
  { username: username1, user: user1, school: school1, score: score1, avatar: Avatar1 },
  { username: username2, user: user2, school: school2, score: score2, avatar: Avatar2 },
  { username: username3, user: user3, school: school3, score: score3, avatar: Avatar3 },
  { username: username4, user: user4, school: school4, score: score4, avatar: Avatar4 },
  { username: username5, user: user5, school: school5, score: score5, avatar: Avatar5 }
];


  return (
    <>
  <Flex
    border="2px solid orange"
    padding="10px"
    direction="column"
    display="flex"
    align="center"
    borderRadius="10px"
    width="fit-content"
    style={{ position: 'relative', left: adjustedContent }}
  >
 <Button
  onClick={onOpen}
  mt={4}
  left={adjustedDetails}
  color="orange"
  fontFamily={'Orbitron, sans-serif'}
  borderRadius="20px"  
  border="2px solid white"
>
  {name}
</Button>

<Text
  mt={2}
  style={{ position: 'relative', left: adjustedDetails }}
  color="white"
  fontFamily={'Orbitron, sans-serif'}
  bg='gray.800'
>
  Prize ${prize}
</Text>
<Text
  mt={5}
  style={{ position: 'relative', left: adjustedDetails }}
  color="white"
  fontFamily={'Orbitron, sans-serif'}
>
  {description}
</Text>
{ppl.map((item, index) => (
        <Grid
          key={index}
          templateColumns={{ base: 'repeat(auto-fit, minmax(50px, 1fr))', md: '70px 70px 200px 70px 150px 100px' }}
          gap={4}
          mt={10}
          ml={adjustedUsers}
          fontFamily={'Orbitron, sans-serif'}
          as={Link}
          to={`/${item.username}`}
          bg={index % 2 === 0 ? 'gray.800' : 'black'}
          p={4}
          borderRadius="md"
          alignItems="center"
          width="100%"
          border={index < 3 ? '2px solid orange' : 'none'}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
     <Box
      color={index < 3 ? 'black' : 'inherit'}
      style={index === 0 ? {
        position: 'relative',
        color: 'black',
        textShadow: `
          -1px -1px 0 orange,
          1px -1px 0 orange,
          -1px 1px 0 orange,
          1px 1px 0 orange
        `
      } : index === 1 ? {
        position: 'relative',
        color: 'black',
        textShadow: `
          -1px -1px 0 blue,
          1px -1px 0 blue,
          -1px 1px 0 blue,
          1px 1px 0 blue
        `    } : index === 2 ? {
          position: 'relative',
          color: 'black',
          textShadow: `
            -1px -1px 0 white,
            1px -1px 0 white,
            -1px 1px 0 white,
            1px 1px 0 white
          `
        } : {}}
      >
        {index + 1}st
      </Box>
      <Avatar src={item.avatar} />
      {isSmallScreen ? (
  <>
    <Box color="green">{item.score}pts</Box>
    <Box color="rgb(135,206,250)">{item.user}</Box>
  </>
) : (
  <>
    <Box color="rgb(135,206,250)">{item.user}</Box>

    <>
      {!isSmallScreen && <Box>of</Box>}
    </>
    <Box color="#FF6EC7" overflowWrap="break-word" whiteSpace="normal">
      {item.school}
    </Box>
    <Box color="green">{item.score}pts</Box>
  </>
)}
        </Grid>
      ))}
    </Flex>







    
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="black" border="2px solid orange">
          <ModalHeader   ml={100}   fontFamily={'Orbitron, sans-serif'} 
      color="rgb(135,206,250)">{name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
         {Instructions}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}  fontFamily={'Orbitron, sans-serif'} >
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  )
}

export default Scholarship