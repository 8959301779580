// On HomePage and Global
/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/

import { Avatar } from '@chakra-ui/avatar'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import Actions from './Actions'
import useShowToast from '../hooks/useShowToast'
import { formatDistanceToNow } from 'date-fns'
import { DeleteIcon } from '@chakra-ui/icons'
import { useRecoilState, useRecoilValue } from 'recoil'
import userAtom from '../atoms/userAtom'
import postsAtom from '../atoms/postsAtom'
import ReactPlayer from 'react-player'
import usePreviewMedia from '../hooks/usePreviewImg'
import Arrow from './Arrow'
import PostFooter from './PostFooter'
import { useLocation } from 'react-router-dom'
import Hls from 'hls.js';



const Post = ({ post, postedBy }) => {
    const [user, setUser] = useState(null);
    const showToast = useShowToast();
    const currentUser = useRecoilValue(userAtom);
    const [posts, setPosts] = useRecoilState(postsAtom);
    const navigate = useNavigate();
    const { mediaUrl, mediaType } = usePreviewMedia();
    const [videoUrl, setVideoUrl] = useState(post.img);
    const location = useLocation();

    useEffect(() => {
        const getUser = async () => {
            if (!postedBy) {
                console.log("No user ID provided.");
                return;
            }
            try {
                const res = await fetch(`/api/users/profile/` + postedBy);
                if (!res.ok) {
                    setUser(null);
                    return;
                }
                const data = await res.json();
                setUser(data);
            } catch (error) {
                showToast("Error", error.message, "error");
                setUser(null);
            }
        };
        getUser();
    }, [postedBy, showToast]);

    const isVideo = (url) => {
        const videoExtensions = ['mp4', 'webm', 'ogg', 'mov'];
        const extension = url.split('.').pop();
        return videoExtensions.includes(extension);
    };

    const handleDeletePost = async (e) => {
        try {
            e.preventDefault();
            if (!window.confirm("Are you sure you want to delete this post?")) return;
            const res = await fetch(`/api/posts/${post._id}`, {
                method: "DELETE",
            });
            const data = await res.json();
            if (data.error) {
                showToast("Error", data.error, "error");
                return;
            }
            showToast("Success", "Post deleted", "success");
            setPosts(posts.filter((p) => p._id !== post._id));
        } catch (error) {
            showToast("Error", error.message, "error");
        }
    };

    if (!user) return null;

    const isBase64Video = (url) => /^data:video\/[^;]+;base64,/.test(url);

    const convertBase64ToBlobUrl = (base64) => {
        const parts = base64.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        const blob = new Blob([uInt8Array], { type: contentType });
        return URL.createObjectURL(blob);
    };

    return (
        <>
            <Link to={`/${user.username}/post/${post._id}`}>
                <Flex gap={3} mb={4} py={5}>
                    <Flex flexDirection={'column'} alignItems={'center'}>
                        <Avatar size="md" name={user?.name} src={user?.profilePic}
                            onClick={(e) => { e.preventDefault(); navigate(`/${user.username}`) }} />
                        <Box w="1px" h={'full'} bg="rgb(135,206,250)" my={2}></Box>
                    </Flex>
                    <Flex flex={1} flexDirection={'column'} gap={2}>
                        <Flex justifyContent={'space-between'} w={'full'}>
                            <Flex w={"full"} alignItems={'center'}>
                                <Text fontSize={'sm'} fontWeight={'bold'} color={"rgb(135, 206, 250)"} fontFamily={'Orbitron, sans-serif'}
                                    onClick={(e) => { e.preventDefault(); navigate(`/${user.username}`) }}>{user?.username}</Text>
                            </Flex>
                            <Flex gap={4} alignItems={'center'}>
                                <Text fontSize={'xs'} width={36} textAlign={'right'}
                                    color={"rgb(135, 206, 250)"} fontFamily={'Orbitron, sans-serif'}>
                                    {formatDistanceToNow(new Date(post.createdAt))} ago</Text>
                                {currentUser?._id === user._id && location.pathname.includes(`/${user.username}`) && <DeleteIcon size={'20'} onClick={handleDeletePost} color={'yellow.200'} />}
                            </Flex>
                        </Flex>
                        <Text fontSize={'sm'} color={"white"} fontFamily={'Orbitron, sans-serif'}>{post.text}</Text>

                        {post.img && (
                            <Box borderRadius={6} overflow={'hidden'} border={'1px solid white'} borderColor={'gray.light'}>
                                {isBase64Video(post.img) ? (
                                    <ReactPlayer
                                        url={convertBase64ToBlobUrl(post.img)}
                                        width='100%'
                                        height='100%'
                                        controls
                                        config={{
                                            file: {
                                                hlsOptions: {
                                                    startLevel: 0,
                                                    capLevelToPlayerSize: true,
                                                    debug: false,
                                                    defaultAudioCodec: 'mp4a.40.2',
                                                    maxBufferLength: 100,
                                                    maxMaxBufferLength: 150,
                                                    enableWorker: true,
                                                    enableSoftwareAES: true,
                                                },
                                                hlsVersion: Hls.version,
                                            },
                                        }}
                                        onError={e => {
                                            console.error('ReactPlayer error', e);
                                            const hasHttp = post.img.startsWith('http://');
                                            const hasHttps = post.img.startsWith('https://');
                                            const correctedUrl = hasHttp || hasHttps ? post.img : `https://${post.img}`;
                                            setVideoUrl(correctedUrl);
                                        }}
                                    />
                                ) : isVideo(post.img) ? (
                                    <ReactPlayer
                                        url={videoUrl}
                                        width='100%'
                                        height='100%'
                                        controls
                                        config={{
                                            file: {
                                                hlsOptions: {
                                                    startLevel: 0,
                                                    capLevelToPlayerSize: true,
                                                    debug: false,
                                                    defaultAudioCodec: 'mp4a.40.2',
                                                    maxBufferLength: 100,
                                                    maxMaxBufferLength: 150,
                                                    enableWorker: true,
                                                    enableSoftwareAES: true,
                                                },
                                                hlsVersion: Hls.version,
                                            },
                                        }}
                                        onError={e => {
                                            console.error('ReactPlayer error', e);
                                            const hasHttp = post.img.startsWith('http://');
                                            const hasHttps = post.img.startsWith('https://');
                                            const correctedUrl = hasHttp || hasHttps ? post.img : `https://${post.img}`;
                                            setVideoUrl(correctedUrl);
                                        }}
                                    />
                                ) : (
                                    <Image src={post.img} alt={post.title} w={'full'} />
                                )}
                            </Box>
                        )}

<Text fontSize={'sm'} color={"orange"} fontFamily={'Orbitron, sans-serif'}>
    {post.views || 0} views
</Text>
                    </Flex>
                </Flex>
            </Link>
            <Flex gap={3} my={1} mt={-10}>
                <PostFooter post={post} />
            </Flex>
        </>
    );
}

export default Post;