/* Je vous salue, Marie pleine de grâce le Seigneur est avec vous. Vous êtes bénie entre toutes les femmes 
et Jésus le fruit de vos entrailles, est béni. Sainte Marie, Mère de Dieu, priez pour nous pauvres pécheurs
maintenant et à l’heure de notre mort*/

// Has the Avatar scroll thing on the top

import { Avatar, AvatarBadge, Box, Flex, Stack, Text, WrapItem, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { useRecoilState, useRecoilValue } from 'recoil';
import  userAtom  from '../atoms/userAtom';
import { BsCheck2All } from 'react-icons/bs';
import { selectedConversationAtom } from '../atoms/messagesAtom';
import { Wrap } from '@chakra-ui/react';

const Conversation = ({conversation, isOnline}) => {
    const user = conversation.participants[0];
    const currentUser = useRecoilValue(userAtom)
    const lastMessage = conversation.lastMessage;
    const [selectedConversation, setSelectedConversation] = useRecoilState(selectedConversationAtom);
    const colorMode = useColorMode()
    console.log("selectedConversation", selectedConversation);
  return (
    <Flex gap={4} alignItems={'center'} p={'1'} _hover={{
        cursor:'pointer',
        bg:useColorModeValue('gray.600', 'gray.dark'),
        color:'white'
    }}
    onClick={() => setSelectedConversation({
        _id: conversation._id,
        userId: user._id,
        userProfilePic: user.profilePic,    
        username: user.username,
        mock: conversation.mock,
    })}

    bg={selectedConversation?._id === conversation._id ? (colorMode === 'light' ? 'gray.600' : 'gray.dark') : '' }
    borderRadius={'md'}>
      <Wrap spacing={6}> 
  <WrapItem>
    <Avatar size={{base:'xs', sm:'sm', md:'md'}} src={user.profilePic} name={user.username}>
      {isOnline ? <AvatarBadge boxSize='3em' bg='green.500' /> : ''}
    </Avatar>
  </WrapItem>
</Wrap>
        
        <Stack direction={'column'} fontSize={'sm'}>
            <Text fontWeight='700' display={'flex'} alignItems={'center'} fontFamily={'Orbitron, sans-serif'}>
            {user.username} 
            </Text>
            <Text fontSize={'10px'} dispaly={'flex'} alignItems={'center'} gap={1} color={'gray.400'}
            fontFamily={'Orbitron, sans-serif'}>
                {currentUser._id === lastMessage.sender ? 
                <Box color={lastMessage.seen ? "" : ""}>
                    <BsCheck2All size={16} />
                </Box>  : ""}
               {lastMessage.text > 18 ? lastMessage.text.slice(0,18) + '...' : lastMessage.text}
            </Text>
        </Stack>
    </Flex>
  )
}

export default Conversation

/* 
import { Avatar, AvatarBadge, Box, Flex, Stack, Text, WrapItem, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { useRecoilState, useRecoilValue } from 'recoil';
import  userAtom  from '../atoms/userAtom';
import { BsCheck2All } from 'react-icons/bs';
import { selectedConversationAtom } from '../atoms/messagesAtom';

const Conversation = ({conversation, isOnline}) => {
    const user = conversation.participants[0];
    const currentUser = useRecoilValue(userAtom)
    const lastMessage = conversation.lastMessage;
    const [selectedConversation, setSelectedConversation] = useRecoilState(selectedConversationAtom);
    const colorMode = useColorMode()
    console.log("selectedConversation", selectedConversation);
  return (
    <Flex gap={4} alignItems={'center'} p={'1'} _hover={{
        cursor:'pointer',
        bg:useColorModeValue('gray.600', 'gray.dark'),
        color:'white'
    }}
    onClick={() => setSelectedConversation({
        _id: conversation._id,
        userId: user._id,
        userProfilePic: user.profilePic,    
        username: user.username,
        mock: conversation.mock,
    })}

    bg={selectedConversation?._id === conversation._id ? (colorMode === 'light' ? 'gray.600' : 'gray.dark') : '' }
    borderRadius={'md'}>
       <WrapItem>
       <Avatar size={{base:'xs', sm:'sm', md:'md'}} src={user.profilePic} name={user.username}>
        {isOnline ? <AvatarBadge boxSize='1em' bg='green.500' /> : ''}
    </Avatar>
</WrapItem>
        
        <Stack direction={'column'} fontSize={'sm'}>
            <Text fontWeight='700' display={'flex'} alignItems={'center'} fontFamily={'Orbitron, sans-serif'}>
            {user.username} 
            </Text>
            <Text fontSize={'10px'} dispaly={'flex'} alignItems={'center'} gap={1} color={'gray.400'}
            fontFamily={'Orbitron, sans-serif'}>
                {currentUser._id === lastMessage.sender ? 
                <Box color={lastMessage.seen ? "black" : ""}>
                    <BsCheck2All size={16} />
                </Box>  : ""}
               {lastMessage.text > 18 ? lastMessage.text.slice(0,18) + '...' : lastMessage.text}
            </Text>
        </Stack>
    </Flex>
  )
}

export default Conversation
 */