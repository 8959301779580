//万福玛利亚，
//你充满圣宠，
//主与你同在，
//你在妇女中受赞颂
//你的情子耶稣同受赞颂
//天主圣母玛利亚，
//求你现在和我们临终时
//为我们罪人祈求天主。
//阿们。

import React, { useState } from 'react';
import { MdReportProblem } from 'react-icons/md';
import { MdOutlineReportProblem } from "react-icons/md";
import { Flex, Box, Button, Text, Input, Textarea, Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from "recoil";
import userAtom from "../../atoms/userAtom";
import postsAtom from "../../atoms/postsAtom";
import { useEffect } from "react";
import Post from '../Post';
import useShowToast from '../../hooks/useShowToast';

const Report = ({ post }) => {
  const [reason, setReason] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useRecoilValue(userAtom); 
  const showToast = useShowToast()
 
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/posts/report', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ postId: post._id, reason, userId: user._id, userName: user.name }), 
      });
      if (response.ok) {
        showToast('Report submitted successfully', 'success');
        onClose(); 
      } else {
        showToast('Failed to submit report', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      showToast('An error occurred while submitting the report', 'error');
    }
  };
  
  return (

<>
    <Tooltip
    hasArrow
    label={"Comment"}
    placement='right'
    ml={1}
    openDelay={500}
    display={{ base: 'block', md: 'none' }}
    fontFamily={"'Orbitron', sans-serif"}
  >
    <Flex
      alignItems={'center'}
      gap={4}
      borderRadius={6}
      p={2}
      w={{ base: 10, md: 'full' }}
      justifyContent={{ base: 'center', md: 'flex-start' }}
      onClick={onOpen}
    >
      <MdOutlineReportProblem size={20} color="red" />
      <Box
        display={{ base: "none", md: "block" }}
        fontFamily={"'Orbitron', sans-serif"}
        color="#8d8d8d"
        css={{
          textShadow: "0 0 2px yellow.400, 0 0 3px yellow.400, 0 0 4px yellow.400",
          mixBlendMode: "difference",
        }}
      >
         Report
      </Box>
    </Flex>
  </Tooltip>



  
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg='black'>
          <ModalHeader color={"rgb(135, 206, 250)"}  textAlign="center"
           fontFamily={'Orbitron, sans-serif'}>Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <Text fontWeight='bold' mb='1rem'color={"rgb(135, 206, 250)"}   textAlign="center"
            fontFamily={'Orbitron, sans-serif'}>
             You are reporting this post
            </Text>
           <Post post={post} />
           <Textarea               value={reason}
              onChange={(e) => setReason(e.target.value)}
              mt={4}   fontFamily={"'Orbitron', sans-serif"}>

           </Textarea>
           
          </ModalBody>

          <ModalFooter>
        
          <Button colorScheme='blue' mr={3} fontFamily={'Orbitron, sans-serif'} onClick={handleReport}>
      Report
    </Button>
          
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>



  );
};

export default Report;