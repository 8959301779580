import React from 'react';
import { ChakraProvider, extendTheme, Box, Flex, Image, Tooltip, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import HiveMindSearch from '../HiveMindSearch';
import { mode } from "@chakra-ui/theme-tools";

const HiveMindChemistry = () => {
  const styles = {
    global: (props) => ({
      body: {
        bg: 'black',
        color: mode('gray.800', 'whiteAlpha.900')(props),
      },
    }),
  };

  const theme = extendTheme({ styles });

  const center = useBreakpointValue({
    base: "10%", //iphone
    sm: "10%",
    md: "31%", //ipad Mini
    lg: "39%",
    xl: "39%"
  });

  const [isIphoneXR] = useMediaQuery("(min-width: 414px) and (max-width: 415px)");
  const [isIphone14ProMax] = useMediaQuery("(min-width: 430px) and (max-width: 431px)");
  const [isIpadPro] = useMediaQuery("(min-width: 1024px) and (max-width: 1025px) and (min-height: 1366px) and (max-height: 1367px)");

  const adjustedCenter = isIphoneXR ? "15%" : isIphone14ProMax ? "15%" : isIpadPro ? "40%" : center;

  return (
    <ChakraProvider theme={theme}>
      <Flex 
        direction="column" 
        align="center" 
        justify="center" 
        position="absolute" 
        top="100%" 
        left={adjustedCenter}
      >
        <Box mt={-100}><HiveMindSearch /></Box>
        <Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px">Chemistry</Box> 

        <Tooltip label="ChemicalEngineering" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/ChemicalEngineering">
              <Image src="chemicalengeneering.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Organic Chemistry" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/OrganicChemistry">
              <Image src="organicchem.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
        <Tooltip label="Quantum Computing" bg="black" color="#FF6EC7" fontSize="md" fontFamily={'Orbitron, sans-serif'}>
          <Box>
            <Link to="/tags/QuantumComputing">
              <Image src="quantumcomputing.png" boxSize={{ base: "100px", md: "150px" }} />
            </Link>
          </Box>
        </Tooltip>
      </Flex>
    </ChakraProvider>
  );
}

export default HiveMindChemistry;


/* import { Box, Button, Flex, Grid, Image, Tooltip, useMediaQuery } from '@chakra-ui/react';
import { Link, Link as RouterLink } from 'react-router-dom';
import HiveMindSearch from '../HiveMindSearch';

const HiveMindChemistry = () => {
    const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (max-width: 430px)");
    const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
    const [ipadPro] = useMediaQuery('(min-width: 1024px) and (min-height: 1366px)');
    const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");
  return (
   <> 

  <Flex direction="column" align="center" justify="center" position="absolute" top="100%" 
  left={isMac ? '40%' : ipadPro ? '37%' : isIpadEsq ? "31%" : isSmallScreen ? "10%" : "39%"}>
      <Box mt={-100} ><HiveMindSearch /></Box>
    <Box color="yellow.400" fontFamily={'Orbitron, sans-serif'} fontSize="32px">
        Chemistry 
    </Box>

<Tooltip label="ChemicalEngineering" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/ChemicalEngineering">
        <Image src="chemicalengeneering.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </ Link>
    </Box>
</Tooltip>
<Tooltip label="Organic Chemistry" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/OrganicChemistry">
        <Image src="organicchem.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </ Link>
    </Box>
</Tooltip>
<Tooltip label="Quantum Computing" bg="black" color="#FF6EC7" fontSize="md"fontFamily={'Orbitron, sans-serif'}>
    <Box>
        <Link to="/tags/QuantumComputing">
        <Image src="quantumcomputing.png" boxSize={isIpadEsq ? "150px" : "100px"}/>
        </ Link>
    </Box>
</Tooltip>
    </ Flex>
   </>
  )
}

export default HiveMindChemistry */