/* Je vous salue, Marie pleine de grâce le Seigneur est avec vous. Vous êtes bénie entre toutes les femmes 
et Jésus le fruit de vos entrailles, est béni. Sainte Marie, Mère de Dieu, priez pour nous pauvres pécheurs
maintenant et à l’heure de notre mort*/

import { Avatar, Box, Button, Flex, Grid, Spacer, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import useFollowUnfollow from "../../hooks/useFollowUnfollow";
import { IoIosAddCircleOutline } from "react-icons/io";



const SuggestedUser = ({user, onButtonClick}) => {
    const {handleFollowUnfollow, updating, following }= useFollowUnfollow(user);
    return (
        <Grid templateColumns="1fr auto" alignItems="center">
     <Box as={Link} to={`/${user.username}`} mt={5}>
        <Avatar src={user.profilePic} name={user.name} />
        <Box>
            <Text fontSize={"sm"} fontWeight={"bold"} fontFamily={'Orbitron, sans-serif'} 
              color="rgb(135,206,250)">
                {user.username}
            </Text>
            <Text  fontSize={"sm"}     fontFamily={'Orbitron, sans-serif'} 
              color="rgb(135,206,250)">
                {user.name}
            </Text>
        </Box>
    </Box>
            <Box>
                <IoIosAddCircleOutline
                    size={"1.5em"} // Set this to the size you want
                    color={following ? "#FF6EC7" : "white"}
                    onClick={() => {
                        handleFollowUnfollow();
                        onButtonClick();
                    }}
                    _hover={{
                        color: "blue.500", // Change this to the color you want on hover
                    }}
                    style={{ cursor: "pointer" }}
                />
            </Box>
        </Grid>
    )
}


export default SuggestedUser


/* const SuggestedUser = ({user}) => {
    const {handleFollowUnfollow, updating, following }= useFollowUnfollow(user);
return (
<Grid templateColumns="1fr auto" alignItems="center">
    <Box as={Link} to={`${user.username}`} mt={5}>
        <Avatar src={user.profilePic} />
        <Box>
            <Text fontSize={"sm"} fontWeight={"bold"}>
                {user.username}
            </Text>
            <Text color={"gray.light"} fontSize={"sm"}>
                {user.name}
            </Text>
        </Box>
    </Box>
    <Box>
        <IoIosAddCircleOutline
            size={"1.5em"} // Set this to the size you want
            color={following ? "#FF6EC7" : "white"}
            onClick={handleFollowUnfollow}
            _hover={{
                color: "blue.500", // Change this to the color you want on hover
            }}
            style={{ cursor: "pointer" }}
        />
    </Box>
</Grid>
  )
}

export default SuggestedUser
 */