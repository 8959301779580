/* Ave Maria gratia plena, Dominus tecum, benedicta tu in mulieribus et benedictus fructus 
ventris tui Iesu, Santa Maria, mater Dei, ora pro nobis peccatoribus, nunc et in ora mortis nostrae */

import { Flex, Box, FormControl, FormLabel, Input, InputGroup, HStack, InputRightElement, Stack,
  Button, Heading, Text, useColorModeValue,Link, useToast, 
  useMediaQuery} from '@chakra-ui/react'
  import { useState } from 'react'
  import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
  import { useSetRecoilState } from 'recoil'
  import authScreenAtom from '../atoms/authAtom'
  import useShowToast from '../hooks/useShowToast'
  import userAtom from '../atoms/userAtom'
  import { useUserAction } from '../hooks/userActionContext.jsx';

  export default function SignupCard() {
  const [showPassword, setShowPassword] = useState(false)
  const setAuthScreen = useSetRecoilState(authScreenAtom)
  const { setLastAction } = useUserAction();
  const [isSmallScreen] = useMediaQuery("(min-width: 375px) and (min-height: 667px)");
  const [isIpadEsq] = useMediaQuery('(min-width: 768px) and (min-height: 1024px)'); 
  const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");
  const [inputs, setInputs] = useState({
  name:"",
  username:"",
  email:'',
  password:"",
  });
  
  const showToast = useShowToast()
  const setUser = useSetRecoilState(userAtom)
  
  const handleSignup = async () => {
    try {
      const res = await fetch("/api/users/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputs)
      });
      const data = await res.json();
      if (data.error) {
        showToast("Error", data.error, "error");
        return;
      }

    
      localStorage.setItem("user-ark", JSON.stringify(data));
      localStorage.setItem('userInterests', JSON.stringify([]));
      setUser(data);
      setLastAction('signup'); 

    } catch (error) {
      showToast("Error", error, "error");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSignup();
    }
  }

  return (
    <Flex align={'center'} justify={'center'} ml={isMac ? "-170px" : isSmallScreen ? "-250px" : "-175px"} fontFamily="Orbitron, sans-serif">
  <Box borderColor="alpha.White" p={1} borderRadius="md">
  <Stack spacing={isIpadEsq ? 24 : 8} mx={'auto'} maxW={isIpadEsq ? 'xl' : 'md'} py={isIpadEsq ? 24 : 8} px={isIpadEsq ? 6 : 3}>
  <Box
    rounded={'lg'}
    boxShadow={'lg'}
    p={isIpadEsq ? 10 : 8}>
    <Stack spacing={isIpadEsq ? 6 : 4}>
      <HStack>
        <Box>
          <FormControl>
          <Input type="text" placeholder="Full Name" 
      onChange={(e) => setInputs({...inputs, name:e.target.value})}
      onKeyDown={handleKeyDown}
      value={inputs.name}
      size={isIpadEsq ? 'lg' : 'md'}
      style={{
        fontSize: isIpadEsq ? '24px' : '16px', 
        padding: isIpadEsq ? '32px' : '12px', 
        height: isIpadEsq ? '70px' : '48px' 
      }}/>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <Input type="text" placeholder="User" 
              onChange={(e) => setInputs({...inputs, username: e.target.value})}
              onKeyDown={handleKeyDown}
              value={inputs.username}
              size={isIpadEsq ? 'lg' : 'md'}
              style={{
                fontSize: isIpadEsq ? '24px' : '16px', 
                padding: isIpadEsq ? '32px' : '12px', 
                height: isIpadEsq ? '70px' : '48px' 
              }}/>
          </FormControl>
        </Box>
      </HStack>
      <FormControl>
        <Input type="email" placeholder="Email" 
          onChange={(e) => setInputs({...inputs, email: e.target.value})}
          onKeyDown={handleKeyDown}
          value={inputs.email}
          size={isIpadEsq ? 'lg' : 'md'}
          style={{
            fontSize: isIpadEsq ? '24px' : '16px', 
            padding: isIpadEsq ? '32px' : '12px', 
            height: isIpadEsq ? '70px' : '48px' 
          }}/>
      </FormControl>
      <FormControl>
        <InputGroup>
          <Input type={showPassword ? 'text' : 'password'}
            placeholder="Password" 
            onChange={(e) => setInputs({...inputs, password: e.target.value})}
            onKeyDown={handleKeyDown}
            value={inputs.password}
            size={isIpadEsq ? 'lg' : 'md'}
            style={{
              fontSize: isIpadEsq ? '24px' : '16px', 
              padding: isIpadEsq ? '32px' : '12px', 
              height: isIpadEsq ? '70px' : '48px' 
            }}/>
          <InputRightElement h={'full'}>
            <Button
              variant={'ghost'}
              onClick={() => setShowPassword((showPassword) => !showPassword)}
              size={isIpadEsq ? 'lg' : 'md'}
              >
              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Stack spacing={isIpadEsq ? 12 : 10} pt={isIpadEsq ? 4 : 2}>
        <Button
          loadingText="Submitting"
          size={isIpadEsq ? 'lg' : 'md'}
          bg={useColorModeValue("darkgray")}
          color={'blue.400'}
          fontSize={isIpadEsq ? 'xl' : 'lg'}
          border="2px solid rgb(135, 206, 250)"
          _hover={{
            bg: useColorModeValue("gray.700","gray.800"),
          }}
          onClick={handleSignup}
          style={{
            fontSize: isIpadEsq ? '24px' : '16px', 
            padding: isIpadEsq ? '32px' : '12px', 
            height: isIpadEsq ? '70px' : '48px' 
          }}
        >
        Sign up
        </Button>
      </Stack>
      <Box border="2px" borderColor="rgb(135, 206, 250)" p={isIpadEsq ? 6 : 4} borderRadius="md">
        <Stack pt={isIpadEsq ? 1 : 0.5} align={'center'} justifyContent={'center'} height="100%" style={{
        fontSize: isIpadEsq ? '24px' : '16px', 
        padding: isIpadEsq ? '32px' : '12px', 
        height: isIpadEsq ? '70px' : '48px' 
      }}>
          <Text fontSize={isIpadEsq ? '2xl' : 'md'} mb={isIpadEsq ? 2 : 1} color="orange" >
            Already a user?
          </Text>
          <Button
            fontSize={isIpadEsq ? '2xl' : 'lg'}
            variant="link"
            color="orange"
            onClick={() => setAuthScreen('login')}>
            Login
          </Button>
        </Stack>
      </Box>
    </Stack>
  </Box>
</Stack>
  </Box>
  </Flex>
  )
  }
  
