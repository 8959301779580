/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/


import React from 'react';
import { Flex, Avatar, Text, Divider, useToast } from '@chakra-ui/react';
import useShowToast from '../hooks/useShowToast'
import { DeleteIcon } from '@chakra-ui/icons';
import { useEffect } from 'react';

//this is the comment in UI

const Comment = ({ post, reply, lastReply, currentUser, setComments,  }) => {

  const showToast = useShowToast();

    // Debugging: Log reply object to verify _id availability
    useEffect(() => {
      console.log("Reply object on mount/update:", reply);
    }, [reply]);
  

    const handleDeleteComment = async (postId, replyId) => { 
      console.log(`Comment ID: ${replyId}`);
      try {
        if (!window.confirm("Are you sure you want to delete this comment?")) return;
        
        console.log(`Deleting comment with postId: ${postId}, replyId: ${replyId}`);
        const res = await fetch(`/api/posts/${postId}/replies/${replyId}`, {
          method: "DELETE",
        });
        if (!res.ok) {
          throw new Error('Failed to delete comment');
        }
        const data = await res.json();
        if (data.error) {
          showToast("Error", data.error, "error");
          return;
        }
       
        // Refresh the page after successful deletion
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        showToast("Error", error.message, "error");
      }
    };
  return (
    <>
      <Flex gap={4} py={2} my={2} w={'full'}>
        {
reply.userProfilePic ? (
  <Avatar src={reply.userProfilePic} size={'sm'} />
) : (
  <Avatar size={'sm'} name={reply.username ? reply.username.charAt(0).toUpperCase() : ''} />
)
        }
        <Flex gap={1} w={'full'} flexDirection={'column'}>
          <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'}>
            <Text fontSize='sm' fontWeight='bold' fontFamily={'Orbitron, sans-serif'} color="rgb(135,206,250)">
              {reply.username}
            </Text>
            {currentUser && reply && (String(currentUser._id) === String(reply.userId)) && (
  <DeleteIcon cursor="pointer" onClick={() => {
    if (typeof reply._id === 'undefined') {
      console.error("reply._id is undefined. Cannot delete comment.");
      return;
    }
    handleDeleteComment(post._id, reply._id);
  }} color={'yellow.200'} />
)}
          </Flex>
          <Text color={"white"} fontFamily={'Orbitron, sans-serif'}>{reply.text}</Text>
        </Flex>
      </Flex>
      {!lastReply ? <Divider /> : null}
    </>
  );
};


export default Comment;
